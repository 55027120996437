import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import {
  Link,
  LinksState,
  NormalizedLinkFragment,
  REQUEST_LINK_SUCCESS,
} from "./types";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import isEqual from "lodash/isEqual";

/**
 * Data about the available links.
 */
const initialState: LinksState = {
  byId: {},
};

export function linksReducer(
  state = initialState,
  action: PlayerAction
): LinksState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS:
      case REQUEST_LINK_SUCCESS: {
        const links = action.payload.links;
        const ids = Object.keys(links);
        ids.forEach((id) => {
          if (links[id]) {
            const newLink = mapGqlLinkToPlayerLink(links[id]);
            if (!isEqual(state.byId[id], newLink)) {
              draft.byId[id] = newLink;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlLinkToPlayerLink = (data: NormalizedLinkFragment): Link => {
  return {
    id: data.id,
    url: data.url,
    cloudConfigCredential: data.cloudConfig?.credential,
    linkType: data.linkType,
    name: data.name,
    fileId: data.fileByFileId,
  };
};
