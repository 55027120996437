import { PlayerAction } from "../storeTypes";
import { produce } from "immer";
import {
  PlaylistsState,
  Playlist,
  REQUEST_PLAYLIST_SUCCESS,
  NormalizedPlaylistFragment,
} from "./types";
import isEqual from "lodash/isEqual";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { REQUEST_CHANNEL_SUCCESS } from "./../channels/types";

const initialState: PlaylistsState = {
  byId: {},
};

export function playListReducer(
  state = initialState,
  action: PlayerAction
): PlaylistsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS:
      case REQUEST_SCREEN_SUCCESS: {
        const playlists = action.payload.playlists;
        const playlistIds = Object.keys(playlists);
        playlistIds.forEach((id) => {
          if (playlists[id]) {
            const newPlaylist = mapGqlChannelToPlayerPlaylist(playlists[id]);
            if (!isEqual(newPlaylist, state.byId[id])) {
              draft.byId[id] = newPlaylist;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlChannelToPlayerPlaylist = (
  data: NormalizedPlaylistFragment
): Playlist => {
  return {
    name: data.name,
    id: data.id,
  };
};
