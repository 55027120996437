import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import {
  NormalizedAppInstanceFragment,
  REMOVE_APP_INSTANCE,
  REQUEST_APP_SUCCESS,
} from "./types";
import { PlayerAction } from "../storeTypes";
import { App, AppsState } from "./types";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import isEqual from "lodash/isEqual";

/**
 * Data about the available app instances.
 */
const initialState: AppsState = {
  byId: {},
};

export function appsReducer(
  state = initialState,
  action: PlayerAction
): AppsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_APP_SUCCESS: {
        const apps = action.payload.apps;
        const appIds = Object.keys(apps);
        appIds.forEach((id) => {
          if (apps[id]) {
            const newAppEntity = mapGqlAppToPlayerApp(apps[id]);
            if (!isEqual(newAppEntity, draft.byId[id])) {
              draft.byId[id] = newAppEntity;
            }
          }
        });
        break;
      }
      case REMOVE_APP_INSTANCE: {
        delete draft.byId[action.payload.id];
        break;
      }
    }
    return draft;
  });
}

const mapGqlAppToPlayerApp = (data: NormalizedAppInstanceFragment): App => {
  return {
    appInstallId: data.appInstallId,
    config: data.config ?? {},
    fileIds: data.filesByAppInstanceId?.nodes,
    fileByThumbnailFileId: data.fileByThumbnailFileId || undefined,
    id: data.id,
    name: data.name || "",
    version: data.version || "",
    viewerUrl: data.appVersionByAppInstanceId?.viewerUrl || "",
    iconUrl: data.appByAppId?.iconUrl || "",
    slug: extractSlugFromScrn(data.appByAppId?.scrn || ""),
  };
};

const extractSlugFromScrn = (scrn: string | null): string | null => {
  if (!scrn) {
    return null;
  }

  const splitScrn = scrn.split(":");
  return splitScrn[splitScrn.length - 1];
};
