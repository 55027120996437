import {
  ADD_LIVE_UPDATE_NOTIFICATION,
  AddNotificationAction,
  CLEANUP_NOTIFICATIONS,
  CleanupNotificationsAction,
  LIVE_UPDATE_LOADED,
  LiveUpdateItemType,
  LiveUpdateLoadedAction,
} from "./types";
import { getLiveUpdateCompositeId } from "./utils";

export const addNotificationItem = (
  entityId: string,
  entityType: LiveUpdateItemType,
  timestamp: number
): AddNotificationAction => {
  const compositeId = getLiveUpdateCompositeId(entityType, entityId);
  return {
    type: ADD_LIVE_UPDATE_NOTIFICATION,
    payload: {
      id: compositeId,
      timestamp,
    },
  };
};

export const liveUpdateLoaded = (
  entityId: string,
  entityType: LiveUpdateItemType
): LiveUpdateLoadedAction => {
  const compositeId = getLiveUpdateCompositeId(entityType, entityId);
  return {
    type: LIVE_UPDATE_LOADED,
    payload: {
      id: compositeId,
    },
  };
};

export const cleanupNotifications = (
  ids: string[]
): CleanupNotificationsAction => {
  return {
    type: CLEANUP_NOTIFICATIONS,
    payload: {
      ids,
    },
  };
};
