import { FileFragment, FileOutputFragment, Scalars } from "../../queries";
import { Modify } from "../storeTypes";

export interface FilesState {
  byId: {
    [id: string]: PlayerFile;
  };
}

export type Mimetype = string;
export type FileMediaType = "image" | "video" | "audio" | "document";

export interface DocumentFileOutput {
  urlKeys: string[];
  mimetype: string;
}

interface PlayerFileBase {
  availableAt: null | string;
  expireAt: null | string;
  height: number | undefined;
  id: string;
  name: string;
  size: number;
  width: number | undefined;
  type: FileMediaType;
  orgId: string;
}

export interface ImageFile extends PlayerFileBase {
  type: "image";
  urlKey: string;
  mimetype: Mimetype;
}

export interface VideoFile extends PlayerFileBase {
  type: "video";
  urlKey: string;
  mimetype: Mimetype;
  thumbnail?: string;
}

export interface AudioFile extends PlayerFileBase {
  type: "audio";
  urlKey: string;
}

export interface DocumentFile extends PlayerFileBase {
  type: "document";
  images: ImageFile[];
}

export type PlayerFile = ImageFile | VideoFile | AudioFile | DocumentFile;

export type FileAction = RequestFileSuccessAction;

export const REQUEST_FILE_SUCCESS = "REQUEST_FILE_SUCCESS";

export interface RequestFileSuccessAction {
  type: typeof REQUEST_FILE_SUCCESS;
  payload: {
    files: { [id: string]: FileFragment };
  };
}

export const VideoStreamingMimetypesPriorityList: Mimetype[] = [
  "application/dash+xml", // Dash - this should be served most of the cases other than legacy videos
  "application/x-mpegURL", // HLS
];

// some fields are allowed to be empty on the API side, but break the logic on player side, so broken entities will
// be filtered out and the type that comes from the API originally will be narrowed down to the one below.
export type ValidatedFileFragment = Modify<
  FileFragment,
  {
    mimetype: string;
    fileOutputsByFileId: {
      nodes: ValidatedFileOutputFragment[];
    };
  }
>;

export type ValidatedFileOutputFragment = Modify<
  FileOutputFragment,
  {
    mimetype: string;
    url: string;
    content: Scalars["JSON"];
  }
>;
