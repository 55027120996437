import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import {
  parseContentListId,
  parseLocalTimelineId,
} from "../../../../store/contentLists/utils";
import { Timeline, TimelineItem } from "../../../../store/timelines/types";
import { PlaybackItemReport } from "./types";
import ClientApiContext from "../../../../ClientApiContext";
import {
  ContentItemLeaf,
  ContentList,
} from "../../../../store/contentLists/types";

interface TimelinePlaybackReportContainerProps {
  timelineId: string;
}

export const TimelinePlaybackReportContainer: FunctionComponent<TimelinePlaybackReportContainerProps> = (
  props
) => {
  const clientApiContext = useContext(ClientApiContext);

  const { timelineId } = props;

  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => {
    return state.playback.timelines[timelineId];
  });

  const targetTimeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => {
      return state.timelines.byId[timelineId];
    }
  );

  const targetContentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => {
      return state.contentLists.byId[
        parseLocalTimelineId(timelineId).sourceContentListId
      ];
    }
  );

  const targetTimelineItems = targetTimeline?.items;

  const activeTimelineItem = useMemo<TimelineItem | undefined>(() => {
    if (targetTimelineItems && playbackState?.activeIndex !== undefined) {
      return targetTimelineItems[playbackState.activeIndex];
    } else {
      return undefined;
    }
  }, [playbackState, targetTimelineItems]);

  const activeContentListItem = useMemo<ContentItemLeaf | undefined>(() => {
    if (targetContentList && activeTimelineItem?.type !== "void") {
      return targetContentList.items.find(
        (item) =>
          activeTimelineItem &&
          activeTimelineItem?.type !== "void" &&
          item.listId === activeTimelineItem.listId
      );
    }
  }, [targetContentList, activeTimelineItem]);

  const activeZoneId = useMemo<string | undefined>(() => {
    const { sourceContentListId } = parseLocalTimelineId(timelineId);
    const { zoneId } = parseContentListId(sourceContentListId);
    return zoneId;
  }, [timelineId]);

  const activeItemReport = useMemo<PlaybackItemReport | undefined>(() => {
    if (
      !activeTimelineItem ||
      !playbackState ||
      playbackState.activeIndex === undefined ||
      targetTimeline === undefined
    ) {
      return undefined;
    }
    const result: PlaybackItemReport = {
      type: activeTimelineItem.type,
      startTimestamp:
        targetTimeline.items[playbackState.activeIndex].startTimestamp,
    };
    if (activeTimelineItem.type !== "void") {
      result.id = activeTimelineItem.id;
      result.listId = activeTimelineItem.listId;
      result.parentEntityListId = activeContentListItem?.parent?.listId;
    }
    return result;
  }, [
    activeTimelineItem,
    playbackState,
    targetTimeline,
    activeContentListItem,
  ]);

  useEffect(() => {
    if (activeItemReport && clientApiContext?.remoteApi) {
      clientApiContext.remoteApi.reportPlaybackStateUpdate(
        activeItemReport,
        activeZoneId
      );
    }
  }, [activeItemReport, activeZoneId, clientApiContext?.remoteApi]);

  return <></>;
};
