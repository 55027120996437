import { useEffect, useRef } from "react";

type Callback = () => unknown;

/**
 * Hook to run a callback every x milliseconds
 * Taken from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: Callback, delay: number): void {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = window.setInterval(tick, delay);
      return (): void => window.clearInterval(id);
    }
  }, [delay]);
}
