import isEqual from "lodash/isEqual";
import { AnyAction, Store } from "redux";
import { PlayerState } from "./../store/rootReducer";
import { setConfig } from "../store/config/actions";
import { ConfigurationManager } from "../configurationManager";

export const connectToPMIForUpdates = (
  store: Store<PlayerState, AnyAction>
): void => {
  // connect to the PMI for updates from the SDK parent
  ConfigurationManager.getInstance()
    .getRemoteInterface()
    .on("SP_UPDATE_OVERRIDE_APP_INITIALIZE", (overrideAppInitialize) => {
      if (overrideAppInitialize) {
        // get current overrideAppInitialize
        const overrideAppInitialiseFromStore =
          store.getState().config.overrideAppInitialize || {};

        // compare overrideAppInitialize from store to new
        if (!isEqual(overrideAppInitialiseFromStore, overrideAppInitialize)) {
          const config = {
            overrideAppInitialize: {
              ...overrideAppInitialiseFromStore,
              ...overrideAppInitialize,
              key: Date.now().toString(),
            },
          };
          store.dispatch(setConfig(config));
        }
      }
    });
};
