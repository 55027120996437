import { ContentGenericType } from "../types/content";

export const timelineSlotPrefix = "slot-item-";
export const genericViewerPrefix = "generic-viewer-";

export const genericViewerTestId = (
  contentItem: {
    type: ContentGenericType | "void";
    id?: string;
  },
  startTimestamp: number | undefined
): string => {
  const { type, id } = contentItem;
  const base = `${genericViewerPrefix}_${type}_${startTimestamp}`;
  if (id !== undefined) {
    return `${base}-${id}`;
  } else {
    return base;
  }
};
