import { GraphQLClient } from "graphql-hooks";
import { ConfigState } from "./store/config/types";
import { FileprocessingClient } from "@screencloud/signage-files-client";
import { FileProcessingClientInterface } from "./types/filesClient";

/**
 * Instantiate a new GraphQL client to be used across the Player
 *
 * https://github.com/nearform/graphql-hooks
 * Substantially lighter-weight than Apollo
 * Avoids breaks due to Apollo forcing __typename everywhere.
 */
export const createGraphQLClient = (
  config: Pick<ConfigState, "graphqlEndpoint" | "graphqlToken">
): GraphQLClient => {
  return new GraphQLClient({
    url: config.graphqlEndpoint,
    cache: undefined,
    logErrors: true,
    headers: {
      Authorization: `bearer ${config.graphqlToken}`,
    },
  });
};

export const createFileProcessingClient = (
  config: Pick<ConfigState, "imgixDomain" | "mediaOrigin">
): FileProcessingClientInterface => {
  return new FileprocessingClient({
    imgixDomain: config.imgixDomain,
    mediaOrigin: config.mediaOrigin,
    signedUrlClient: { signUrl: (): Promise<string> => Promise.resolve("") }, // dummy client not used right now
  });
};
