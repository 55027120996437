import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenData } from "../../../../store/screen/types";
import { ContextConfig } from "../../../../store/config/types";
import { EntityType } from "@screencloud/signage-firestore-client";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { AppViewer } from "../AppViewer/AppViewer";
import { DEFAULT_APP_INSTANCE_ID } from "../../../../constants";
import { Site } from "../../../../store/sites/types";
import { App } from "../../../../store/apps/types";

interface SiteViewerContainerProps {
  id: string;
  isPreload?: boolean;
  fullDurationMs: number;
}

export const SiteViewerContainer = (
  props: SiteViewerContainerProps
): ReactElement<SiteViewerContainerProps> => {
  const { id, isPreload, fullDurationMs } = props;

  // Using a key to re-mount the app when app object is updated
  const [reMountKey, setReMountKey] = useState<number | undefined>(undefined);

  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );

  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );

  const region = useSelector<PlayerState, string>(
    (state) => state.config.region
  );

  const site = useSelector<PlayerState, Site | undefined>(
    (state) => state.sites.byId[id]
  );

  const screenData = useSelector<PlayerState, ScreenData | undefined>(
    (state) => state.screen.screenData
  );

  const orgId = useSelector<PlayerState, string | undefined>(
    (state) => state.organization?.id
  );

  const screenId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.id
  );

  const viewerUrl = process.env[
    `REACT_APP_SECURE_SITES_VIEWER_URL_${region.toUpperCase()}`
  ] as string;

  const app = useMemo<App | undefined>(() => {
    if (!site) {
      return undefined;
    }

    return {
      viewerUrl,
      fileIds: [],
      config: {
        isPreview,
        siteId: id,
        type: site.type,
        url: site.url,
        ...site.config,
      },
      id,
      version: "",
      appInstallId: DEFAULT_APP_INSTANCE_ID,
      name: site.name,
      slug: "",
    };
  }, [viewerUrl, isPreview, id, site]);

  useEffect(() => {
    setReMountKey(new Date().getTime());
  }, [app]);

  if (!app) {
    return <p>Sorry, we can&apos;t find the site you are looking for.</p>;
  }

  return (
    <>
      <LiveUpdateConnector
        entityId={props.id}
        entityType={EntityType.APP_INSTANCE}
      />
      <AppViewer
        key={reMountKey}
        app={app}
        orgId={orgId}
        screenId={screenId}
        screenData={screenData}
        filesByAppInstanceId={{ nodes: [] }}
        fullDurationMs={fullDurationMs}
        isPreload={isPreload}
        contextConfig={contextConfig}
        overrideAppInitialize={{ appInstanceId: DEFAULT_APP_INSTANCE_ID }}
        isPreview={isPreview}
        initialPlaybackPositionMs={0}
        durationElapsedMs={0}
      />
    </>
  );
};
