import { PlayerState } from "../rootReducer";

export const activeChannelId = (state: PlayerState): string | undefined => {
  if (state.screen.activeContentItem?.type === "channel") {
    return state.screen.activeContentItem?.id;
  } else if (state.config.contentConfig.type === "channel") {
    return state.config.contentConfig.id;
  }
  return undefined;
};
