import { REQUEST_LINK_SUCCESS, RequestLinkSuccessAction } from "./types";
import { normalize } from "normalizr";
import { LinkFragment } from "../../queries";
import { linkSchema, NormalizedLinkEntities } from "../normalizationSchemas";

export const requestLinkSuccess = (
  link: LinkFragment
): RequestLinkSuccessAction => {
  const normalizedData = normalize<unknown, NormalizedLinkEntities>(
    link,
    linkSchema
  );

  return {
    type: REQUEST_LINK_SUCCESS,
    payload: {
      links: normalizedData.entities.links || {},
      files: normalizedData.entities.files || {},
    },
  };
};
