import { SpacePreferences } from "../graphqlTypes";
import { SpaceFragment } from "../../queries";

export interface Space {
  id: string;
  name: string;
  preferences?: SpacePreferences;
}

export interface SpacesState {
  byId: {
    [id: string]: Space;
  };
}

export type SpaceAction = RequestSpaceSuccessAction;

export const REQUEST_SPACE_SUCCESS = "REQUEST_SPACE_SUCCESS";

export interface RequestSpaceSuccessAction {
  type: typeof REQUEST_SPACE_SUCCESS;
  payload: {
    spaces: { [id: string]: SpaceFragment };
  };
}
