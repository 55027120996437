import {
  UseClientRequestOptions,
  useManualQuery,
  FetchData,
  UseClientRequestResult,
} from "graphql-hooks";

import {
  AppInstanceByIdQuery,
  AppInstanceByIdQueryVariables,
  AppInstanceRootQuery,
  AppInstanceRootQueryVariables,
  ChannelByIdQuery,
  ChannelByIdQueryVariables,
  CreateAppTokenMutation,
  CreateAppTokenMutationVariables,
  CurrentScreenQuery,
  CurrentScreenQueryVariables,
  FileByIdQuery,
  FileByIdQueryVariables,
  LayoutByIdQuery,
  LayoutByIdQueryVariables,
  LinkByIdQuery,
  LinkByIdQueryVariables,
  PlaylistByIdQuery,
  PlaylistByIdQueryVariables,
  ScreenByIdQuery,
  ScreenByIdQueryVariables,
  SiteByIdQuery,
  SiteByIdQueryVariables,
  SpaceByIdQuery,
  SpaceByIdQueryVariables,
  UpdateScreenDeviceMutation,
  UpdateScreenDeviceMutationVariables,
} from "./types";

export const appInstanceById = `
          query appInstanceById($id: UUID!) {
  appInstanceById(id: $id) {
    ...AppInstance
  }
}

          fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

          `;

export const useAppInstanceById = (
  options?: UseClientRequestOptions<AppInstanceByIdQueryVariables>
): [
  FetchData<AppInstanceByIdQuery, AppInstanceByIdQueryVariables, unknown>,
  UseClientRequestResult<AppInstanceByIdQuery, unknown>
] => {
  return useManualQuery<AppInstanceByIdQuery, AppInstanceByIdQueryVariables>(
    appInstanceById,
    options
  );
};

export const appInstanceRoot = `
          query appInstanceRoot($id: UUID!) {
  appInstanceById(id: $id) {
    ...AppInstance
    orgByOrgId {
      ...Org
    }
  }
}

          fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

fragment Org on Org {
  id
  brandChannelId
  themeByDefaultChannelThemeId {
    ...Theme
  }
  featureFlagsCache
  preferences
}

fragment Theme on Theme {
  id
  name
  config
}

          `;

export const useAppInstanceRoot = (
  options?: UseClientRequestOptions<AppInstanceRootQueryVariables>
): [
  FetchData<AppInstanceRootQuery, AppInstanceRootQueryVariables, unknown>,
  UseClientRequestResult<AppInstanceRootQuery, unknown>
] => {
  return useManualQuery<AppInstanceRootQuery, AppInstanceRootQueryVariables>(
    appInstanceRoot,
    options
  );
};

export const channelById = `
          query channelById($id: UUID!) {
  channelById(id: $id) {
    ...Channel
    orgByOrgId {
      ...Org
    }
    spaceBySpaceId {
      ...Space
    }
  }
}

          fragment Channel on Channel {
  id
  name
  content
  width
  height
  publishedAt
  layoutByChannel {
    ...Layout
  }
  appInstancesByChannelId {
    nodes {
      ...AppInstance
    }
  }
  playlistsByChannelId {
    nodes {
      ...Playlist
    }
  }
  filesByChannelId {
    nodes {
      ...File
    }
  }
  linksByChannelId {
    nodes {
      ...Link
    }
  }
  sitesByChannelId {
    nodes {
      ...Site
    }
  }
  themeByThemeId {
    ...Theme
  }
}

fragment Layout on Layout {
  id
  zones
  config
  width
  height
  isFlexible
  isScalable
}

fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

fragment Playlist on Playlist {
  id
  name
  content
  publishedAt
  filesByPlaylistId {
    nodes {
      ...File
    }
  }
  linksByPlaylistId {
    nodes {
      ...Link
    }
  }
  appInstancesByPlaylistId {
    nodes {
      ...AppInstance
    }
  }
  sitesByPlaylistId {
    nodes {
      ...Site
    }
  }
}

fragment Link on Link {
  id
  name
  url
  linkType
  cloudConfig
  fileByFileId {
    ...File
  }
}

fragment Site on Site {
  id
  name
  url
  actions
  config
  type
  fileByThumbnailId {
    ...File
  }
}

fragment Theme on Theme {
  id
  name
  config
}

fragment Org on Org {
  id
  brandChannelId
  themeByDefaultChannelThemeId {
    ...Theme
  }
  featureFlagsCache
  preferences
}

fragment Space on Space {
  id
  name
  preferences
}

          `;

export const useChannelById = (
  options?: UseClientRequestOptions<ChannelByIdQueryVariables>
): [
  FetchData<ChannelByIdQuery, ChannelByIdQueryVariables, unknown>,
  UseClientRequestResult<ChannelByIdQuery, unknown>
] => {
  return useManualQuery<ChannelByIdQuery, ChannelByIdQueryVariables>(
    channelById,
    options
  );
};

export const createAppToken = `
          mutation createAppToken($input: RuntimeJwtRequestInput!) {
  createSignedRuntimeJwt(input: $input) {
    signedRuntimeToken
    tokenType
    expiresAt
  }
}

          
          `;

export const useCreateAppToken = (
  options?: UseClientRequestOptions<CreateAppTokenMutationVariables>
): [
  FetchData<CreateAppTokenMutation, CreateAppTokenMutationVariables, unknown>,
  UseClientRequestResult<CreateAppTokenMutation, unknown>
] => {
  return useManualQuery<
    CreateAppTokenMutation,
    CreateAppTokenMutationVariables
  >(createAppToken, options);
};

export const currentScreen = `
          query currentScreen {
  currentScreen {
    ...Screen
  }
}

          fragment Screen on Screen {
  id
  content
  status
  preview
  device
  env
  preferences
  orgByOrgId {
    id
    brandChannelId
    themeByDefaultChannelThemeId {
      ...Theme
    }
    featureFlagsCache
    preferences
  }
  channelsByScreenId {
    nodes {
      ...Channel
    }
  }
  appInstancesByScreenId {
    nodes {
      ...AppInstance
    }
  }
  filesByScreenId {
    nodes {
      ...File
    }
  }
  linksByScreenId {
    nodes {
      ...Link
    }
  }
  playlistsByScreenId {
    nodes {
      ...Playlist
    }
  }
  castByCastId {
    ...Cast
  }
  spaceBySpaceId {
    ...Space
  }
  sitesByScreenId {
    nodes {
      ...Site
    }
  }
}

fragment Theme on Theme {
  id
  name
  config
}

fragment Channel on Channel {
  id
  name
  content
  width
  height
  publishedAt
  layoutByChannel {
    ...Layout
  }
  appInstancesByChannelId {
    nodes {
      ...AppInstance
    }
  }
  playlistsByChannelId {
    nodes {
      ...Playlist
    }
  }
  filesByChannelId {
    nodes {
      ...File
    }
  }
  linksByChannelId {
    nodes {
      ...Link
    }
  }
  sitesByChannelId {
    nodes {
      ...Site
    }
  }
  themeByThemeId {
    ...Theme
  }
}

fragment Layout on Layout {
  id
  zones
  config
  width
  height
  isFlexible
  isScalable
}

fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

fragment Playlist on Playlist {
  id
  name
  content
  publishedAt
  filesByPlaylistId {
    nodes {
      ...File
    }
  }
  linksByPlaylistId {
    nodes {
      ...Link
    }
  }
  appInstancesByPlaylistId {
    nodes {
      ...AppInstance
    }
  }
  sitesByPlaylistId {
    nodes {
      ...Site
    }
  }
}

fragment Link on Link {
  id
  name
  url
  linkType
  cloudConfig
  fileByFileId {
    ...File
  }
}

fragment Site on Site {
  id
  name
  url
  actions
  config
  type
  fileByThumbnailId {
    ...File
  }
}

fragment Cast on Cast {
  id
  priority
  content
  appInstanceByCastId {
    ...AppInstance
  }
  fileByCastId {
    ...File
  }
  linkByCastId {
    ...Link
  }
  siteByCastId {
    ...Site
  }
  playlistByCastId {
    ...Playlist
  }
  channelByCastId {
    ...Channel
  }
}

fragment Space on Space {
  id
  name
  preferences
}

          `;

export const useCurrentScreen = (
  options?: UseClientRequestOptions<CurrentScreenQueryVariables>
): [
  FetchData<CurrentScreenQuery, CurrentScreenQueryVariables, unknown>,
  UseClientRequestResult<CurrentScreenQuery, unknown>
] => {
  return useManualQuery<CurrentScreenQuery, CurrentScreenQueryVariables>(
    currentScreen,
    options
  );
};

export const fileById = `
          query fileById($id: UUID!) {
  fileById(id: $id) {
    ...File
  }
}

          fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

          `;

export const useFileById = (
  options?: UseClientRequestOptions<FileByIdQueryVariables>
): [
  FetchData<FileByIdQuery, FileByIdQueryVariables, unknown>,
  UseClientRequestResult<FileByIdQuery, unknown>
] => {
  return useManualQuery<FileByIdQuery, FileByIdQueryVariables>(
    fileById,
    options
  );
};

export const layoutById = `
          query layoutById($id: UUID!) {
  layoutById(id: $id) {
    ...Layout
  }
}

          fragment Layout on Layout {
  id
  zones
  config
  width
  height
  isFlexible
  isScalable
}

          `;

export const useLayoutById = (
  options?: UseClientRequestOptions<LayoutByIdQueryVariables>
): [
  FetchData<LayoutByIdQuery, LayoutByIdQueryVariables, unknown>,
  UseClientRequestResult<LayoutByIdQuery, unknown>
] => {
  return useManualQuery<LayoutByIdQuery, LayoutByIdQueryVariables>(
    layoutById,
    options
  );
};

export const linkById = `
          query linkById($id: UUID!) {
  linkById(id: $id) {
    ...Link
  }
}

          fragment Link on Link {
  id
  name
  url
  linkType
  cloudConfig
  fileByFileId {
    ...File
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

          `;

export const useLinkById = (
  options?: UseClientRequestOptions<LinkByIdQueryVariables>
): [
  FetchData<LinkByIdQuery, LinkByIdQueryVariables, unknown>,
  UseClientRequestResult<LinkByIdQuery, unknown>
] => {
  return useManualQuery<LinkByIdQuery, LinkByIdQueryVariables>(
    linkById,
    options
  );
};

export const playlistById = `
          query playlistById($id: UUID!) {
  playlistById(id: $id) {
    ...Playlist
    orgByOrgId {
      ...Org
    }
    spaceBySpaceId {
      ...Space
    }
  }
}

          fragment Playlist on Playlist {
  id
  name
  content
  publishedAt
  filesByPlaylistId {
    nodes {
      ...File
    }
  }
  linksByPlaylistId {
    nodes {
      ...Link
    }
  }
  appInstancesByPlaylistId {
    nodes {
      ...AppInstance
    }
  }
  sitesByPlaylistId {
    nodes {
      ...Site
    }
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment Link on Link {
  id
  name
  url
  linkType
  cloudConfig
  fileByFileId {
    ...File
  }
}

fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

fragment Site on Site {
  id
  name
  url
  actions
  config
  type
  fileByThumbnailId {
    ...File
  }
}

fragment Org on Org {
  id
  brandChannelId
  themeByDefaultChannelThemeId {
    ...Theme
  }
  featureFlagsCache
  preferences
}

fragment Theme on Theme {
  id
  name
  config
}

fragment Space on Space {
  id
  name
  preferences
}

          `;

export const usePlaylistById = (
  options?: UseClientRequestOptions<PlaylistByIdQueryVariables>
): [
  FetchData<PlaylistByIdQuery, PlaylistByIdQueryVariables, unknown>,
  UseClientRequestResult<PlaylistByIdQuery, unknown>
] => {
  return useManualQuery<PlaylistByIdQuery, PlaylistByIdQueryVariables>(
    playlistById,
    options
  );
};

export const screenById = `
          query screenById($id: UUID!) {
  screenById(id: $id) {
    ...Screen
  }
}

          fragment Screen on Screen {
  id
  content
  status
  preview
  device
  env
  preferences
  orgByOrgId {
    id
    brandChannelId
    themeByDefaultChannelThemeId {
      ...Theme
    }
    featureFlagsCache
    preferences
  }
  channelsByScreenId {
    nodes {
      ...Channel
    }
  }
  appInstancesByScreenId {
    nodes {
      ...AppInstance
    }
  }
  filesByScreenId {
    nodes {
      ...File
    }
  }
  linksByScreenId {
    nodes {
      ...Link
    }
  }
  playlistsByScreenId {
    nodes {
      ...Playlist
    }
  }
  castByCastId {
    ...Cast
  }
  spaceBySpaceId {
    ...Space
  }
  sitesByScreenId {
    nodes {
      ...Site
    }
  }
}

fragment Theme on Theme {
  id
  name
  config
}

fragment Channel on Channel {
  id
  name
  content
  width
  height
  publishedAt
  layoutByChannel {
    ...Layout
  }
  appInstancesByChannelId {
    nodes {
      ...AppInstance
    }
  }
  playlistsByChannelId {
    nodes {
      ...Playlist
    }
  }
  filesByChannelId {
    nodes {
      ...File
    }
  }
  linksByChannelId {
    nodes {
      ...Link
    }
  }
  sitesByChannelId {
    nodes {
      ...Site
    }
  }
  themeByThemeId {
    ...Theme
  }
}

fragment Layout on Layout {
  id
  zones
  config
  width
  height
  isFlexible
  isScalable
}

fragment AppInstance on AppInstance {
  id
  name
  appInstallId
  config
  spaceId
  state
  version
  orgId
  filesByAppInstanceId {
    nodes {
      ...File
    }
  }
  appByAppId {
    ...App
  }
  fileByThumbnailFileId {
    ...File
  }
  appVersionByAppInstanceId {
    ...AppVersion
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

fragment App on App {
  id
  name
  scrn
  iconUrl
}

fragment AppVersion on AppVersion {
  id
  appId
  version
  viewerUrl
}

fragment Playlist on Playlist {
  id
  name
  content
  publishedAt
  filesByPlaylistId {
    nodes {
      ...File
    }
  }
  linksByPlaylistId {
    nodes {
      ...Link
    }
  }
  appInstancesByPlaylistId {
    nodes {
      ...AppInstance
    }
  }
  sitesByPlaylistId {
    nodes {
      ...Site
    }
  }
}

fragment Link on Link {
  id
  name
  url
  linkType
  cloudConfig
  fileByFileId {
    ...File
  }
}

fragment Site on Site {
  id
  name
  url
  actions
  config
  type
  fileByThumbnailId {
    ...File
  }
}

fragment Cast on Cast {
  id
  priority
  content
  appInstanceByCastId {
    ...AppInstance
  }
  fileByCastId {
    ...File
  }
  linkByCastId {
    ...Link
  }
  siteByCastId {
    ...Site
  }
  playlistByCastId {
    ...Playlist
  }
  channelByCastId {
    ...Channel
  }
}

fragment Space on Space {
  id
  name
  preferences
}

          `;

export const useScreenById = (
  options?: UseClientRequestOptions<ScreenByIdQueryVariables>
): [
  FetchData<ScreenByIdQuery, ScreenByIdQueryVariables, unknown>,
  UseClientRequestResult<ScreenByIdQuery, unknown>
] => {
  return useManualQuery<ScreenByIdQuery, ScreenByIdQueryVariables>(
    screenById,
    options
  );
};

export const siteById = `
          query siteById($id: UUID!) {
  siteById(id: $id) {
    ...Site
  }
}

          fragment Site on Site {
  id
  name
  url
  actions
  config
  type
  fileByThumbnailId {
    ...File
  }
}

fragment File on File {
  id
  orgId
  name
  availableAt
  expireAt
  fileProcessingStatus
  mimetype
  source
  metadata
  fileOutputsByFileId {
    nodes {
      ...FileOutput
    }
  }
}

fragment FileOutput on FileOutput {
  id
  content
  metadata
  mimetype
  url
}

          `;

export const useSiteById = (
  options?: UseClientRequestOptions<SiteByIdQueryVariables>
): [
  FetchData<SiteByIdQuery, SiteByIdQueryVariables, unknown>,
  UseClientRequestResult<SiteByIdQuery, unknown>
] => {
  return useManualQuery<SiteByIdQuery, SiteByIdQueryVariables>(
    siteById,
    options
  );
};

export const spaceById = `
          query spaceById($id: UUID!) {
  spaceById(id: $id) {
    ...Space
  }
}

          fragment Space on Space {
  id
  name
  preferences
}

          `;

export const useSpaceById = (
  options?: UseClientRequestOptions<SpaceByIdQueryVariables>
): [
  FetchData<SpaceByIdQuery, SpaceByIdQueryVariables, unknown>,
  UseClientRequestResult<SpaceByIdQuery, unknown>
] => {
  return useManualQuery<SpaceByIdQuery, SpaceByIdQueryVariables>(
    spaceById,
    options
  );
};

export const updateScreenDevice = `
          mutation UpdateScreenDevice($input: UpdateSelfScreenDeviceInput!) {
  updateSelfScreenDevice(input: $input) {
    screen {
      id
      device
      updatedAt
    }
  }
}

          
          `;

export const useUpdateScreenDevice = (
  options?: UseClientRequestOptions<UpdateScreenDeviceMutationVariables>
): [
  FetchData<
    UpdateScreenDeviceMutation,
    UpdateScreenDeviceMutationVariables,
    unknown
  >,
  UseClientRequestResult<UpdateScreenDeviceMutation, unknown>
] => {
  return useManualQuery<
    UpdateScreenDeviceMutation,
    UpdateScreenDeviceMutationVariables
  >(updateScreenDevice, options);
};
