import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../providers/MenuProvider/MenuProvider";
import styles from "./Controls.module.css";
import MenuIcon from "../../../img/menu.svg";
import NavPrevIcon from "../../../img/nav-back.svg";
import NavNextIcon from "../../../img/nav-forward.svg";
import LiveIcon from "../../../img/live.svg";
import FullscreenOpenIcon from "../../../img/fullscreen-open.svg";
import FullscreenCloseIcon from "../../../img/fullscreen-close.svg";

const Controls: FunctionComponent = () => {
  const {
    show,
    liveMode,
    returnToLiveMode,
    items,
    currentIndex,
    toggleFullscreen,
    isFullscreen,
    navigateToPreviousItem,
    navigateToNextItem,
    filteredItems,
  } = useEmbedMenu();

  const count = filteredItems.length;

  const disableNavigationButtons = count <= 1;

  const currentItem = items[currentIndex];

  return (
    <div className={styles.root}>
      {!liveMode && (
        <div className={styles.topRow}>
          <button className={styles.liveButton} onClick={returnToLiveMode}>
            <img src={LiveIcon} alt="Search" />
            <span>Switch to Live playback</span>
          </button>
        </div>
      )}
      {currentItem?.type !== "void" ? (
        <div className={styles.bottomRow}>
          <div className={styles.column}>
            {liveMode ? (
              <div className={styles.liveMessage}>
                <div />
                <p>
                  <span>LIVE</span>
                  <span>{currentItem?.name}</span>
                </p>
              </div>
            ) : (
              <div className={styles.pausedMessage}>
                <p>
                  <span>PAUSED:</span>
                  <span>{currentItem.name}</span>
                </p>
              </div>
            )}
          </div>
          <div className={styles.column} style={{ justifyContent: "center" }}>
            {!liveMode && (
              <button
                className={
                  disableNavigationButtons
                    ? styles.disabledMenuButton
                    : styles.menuIcon
                }
                onClick={navigateToPreviousItem}
                disabled={disableNavigationButtons}
              >
                <img className={styles.icon} src={NavPrevIcon} alt="Previous" />
              </button>
            )}
            <button className={styles.menuIcon} onClick={show}>
              <img className={styles.icon} src={MenuIcon} alt="Menu" />
            </button>
            {!liveMode && (
              <button
                className={
                  disableNavigationButtons
                    ? styles.disabledMenuButton
                    : styles.menuIcon
                }
                onClick={navigateToNextItem}
                disabled={disableNavigationButtons}
              >
                <img className={styles.icon} src={NavNextIcon} alt="Next" />
              </button>
            )}
          </div>
          <div className={styles.column} style={{ justifyContent: "flex-end" }}>
            <button className={styles.menuIcon} onClick={toggleFullscreen}>
              {isFullscreen ? (
                <img
                  className={styles.icon}
                  src={FullscreenCloseIcon}
                  alt="fullscreen exit"
                />
              ) : (
                <img
                  className={styles.icon}
                  src={FullscreenOpenIcon}
                  alt="fullscreen open"
                />
              )}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Controls;
