/**
 * An overview of the way live updates work:
 * https://www.notion.so/screencloud/Data-Fetch-Live-Updates-d64913a35931412aa4a5337cf675f972
 */

import React, {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ContentListsState } from "../../../../store/contentLists/types";
import {
  EntityType,
  ILiveUpdateClient,
} from "@screencloud/signage-firestore-client";
import {
  addNotificationItem,
  cleanupNotifications,
} from "../../../../store/liveUpdates/actions";
import { playbackNowTimestamp } from "../../../../utils/timeManager";
import { LiveUpdateItemType } from "../../../../store/liveUpdates/types";
import { isPlaylistContentListId } from "../../../../store/contentLists/utils";
import pickBy from "lodash/pickBy";
import difference from "lodash/difference";
import { HOUR_DURATION_MS } from "../../../../constants";
import { useInterval } from "../../../../utils/useInterval";
import { decomposeLiveUpdateId } from "../../../../store/liveUpdates/utils";
import { useTimeOptions } from "../../../../utils/useTimeOptions";

export interface ContentRefs {
  apps: MutableRefObject<Set<string>>;
  links: MutableRefObject<Set<string>>;
  channels: MutableRefObject<Set<string>>;
  playlists: MutableRefObject<Set<string>>;
  files: MutableRefObject<Set<string>>;
  layouts: MutableRefObject<Set<string>>;
  spaces: MutableRefObject<Set<string>>;
  screens: MutableRefObject<Set<string>>;
}

interface LiveUpdatesContainerProps {
  liveUpdateClient: ILiveUpdateClient | undefined;
  onRefUpdate?: (contentRef: ContentRefs) => void; // this is for test purpose
  cleanupInterval?: number; // this is for test purpose
}

export const LiveUpdatesContainer: FunctionComponent<LiveUpdatesContainerProps> = (
  props: LiveUpdatesContainerProps
) => {
  const dispatch = useDispatch();

  const state = useSelector<PlayerState, PlayerState>((state) => state);

  const {
    apps,
    channels,
    files,
    links,
    contentLists,
    layouts,
    spaces,
    screen,
    liveUpdates,
  } = state;
  const { liveUpdateClient, onRefUpdate, cleanupInterval } = props;

  const playlistContentLists = useMemo<ContentListsState>(() => {
    return {
      byId: pickBy(contentLists.byId, (contentList) =>
        isPlaylistContentListId(contentList.id)
      ),
    };
  }, [contentLists]);

  const timeOptions = useTimeOptions();

  const notificationCallback = useCallback(
    (snapshot) => {
      const timestamp = playbackNowTimestamp(timeOptions);
      const entityType = snapshot.ref.parent.id as LiveUpdateItemType;
      const entityId = snapshot.ref.id as string;

      dispatch(addNotificationItem(entityId, entityType, timestamp));
    },
    [dispatch, timeOptions]
  );

  const appSubscriptionIds = useRef<Set<string>>(new Set());
  const linkSubscriptionIds = useRef<Set<string>>(new Set());
  const channelSubscriptionIds = useRef<Set<string>>(new Set());
  const playlistSubscriptionIds = useRef<Set<string>>(new Set());
  const fileSubscriptionIds = useRef<Set<string>>(new Set());
  const layoutSubscriptionIds = useRef<Set<string>>(new Set());
  const spaceSubscriptionIds = useRef<Set<string>>(new Set());
  const screenSubscriptionIds = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (onRefUpdate) {
      onRefUpdate({
        apps: appSubscriptionIds,
        links: linkSubscriptionIds,
        channels: channelSubscriptionIds,
        playlists: playlistSubscriptionIds,
        files: fileSubscriptionIds,
        layouts: layoutSubscriptionIds,
        spaces: spaceSubscriptionIds,
        screens: screenSubscriptionIds,
      });
    }
  }, [
    onRefUpdate,
    appSubscriptionIds,
    linkSubscriptionIds,
    channelSubscriptionIds,
    playlistSubscriptionIds,
    fileSubscriptionIds,
    layoutSubscriptionIds,
    spaceSubscriptionIds,
    screenSubscriptionIds,
  ]);

  const setSubscriptions = useCallback(
    (
      entityType: LiveUpdateItemType,
      subsListRef: MutableRefObject<Set<string>>,
      loadedEntityIds: Set<string>
    ): void => {
      if (liveUpdateClient === undefined) {
        return;
      }
      // subscribe to new entities, unsubscribe from removed entities
      const entityIdsToSubscribe = difference(
        [...loadedEntityIds],
        [...subsListRef.current]
      );
      const entityIdsToUnsubscribe = difference(
        [...subsListRef.current],
        [...loadedEntityIds]
      );

      entityIdsToSubscribe.forEach((entityId) => {
        liveUpdateClient.subscribeToEntityChange(
          entityType,
          entityId,
          notificationCallback
        );
        subsListRef.current.add(entityId);
      });

      entityIdsToUnsubscribe.forEach((entityId) => {
        liveUpdateClient.unsubscribeFromEntityChange(entityType, entityId);
        subsListRef.current.delete(entityId);
      });
    },
    [notificationCallback, liveUpdateClient]
  );

  const setAppSubscriptions = useCallback(() => {
    const loadedAppIds = new Set(Object.keys(apps.byId));
    setSubscriptions(EntityType.APP_INSTANCE, appSubscriptionIds, loadedAppIds);
  }, [apps, setSubscriptions]);

  const setLinkSubscriptions = useCallback(() => {
    const loadedLinkIds = new Set(Object.keys(links.byId));
    setSubscriptions(EntityType.LINK, linkSubscriptionIds, loadedLinkIds);
  }, [links, setSubscriptions]);

  const setChannelSubscriptions = useCallback(() => {
    const loadedChannelIds = new Set(Object.keys(channels.byId));
    setSubscriptions(
      EntityType.CHANNEL,
      channelSubscriptionIds,
      loadedChannelIds
    );
  }, [channels, setSubscriptions]);

  const setPlaylistSubscriptions = useCallback(() => {
    const loadedPlaylistIds = new Set(Object.keys(playlistContentLists.byId));
    setSubscriptions(
      EntityType.PLAYLIST,
      playlistSubscriptionIds,
      loadedPlaylistIds
    );
  }, [playlistContentLists, setSubscriptions]);

  const setFileSubscriptions = useCallback(() => {
    const loadedFileIds = new Set(Object.keys(files.byId));
    setSubscriptions(EntityType.FILE, fileSubscriptionIds, loadedFileIds);
  }, [files, setSubscriptions]);

  const setLayoutSubscriptions = useCallback(() => {
    const loadedLayoutIds = new Set(Object.keys(layouts.byId));
    setSubscriptions(EntityType.LAYOUT, layoutSubscriptionIds, loadedLayoutIds);
  }, [layouts, setSubscriptions]);

  const setSpaceSubscriptions = useCallback(() => {
    const loadedSpaceIds = new Set(Object.keys(spaces.byId));
    setSubscriptions(EntityType.SPACE, spaceSubscriptionIds, loadedSpaceIds);
  }, [spaces, setSubscriptions]);

  const setScreenSubscriptions = useCallback(() => {
    if (screen.id) {
      const loadedScreenIds = new Set([screen.id]);
      setSubscriptions(
        EntityType.SCREEN,
        screenSubscriptionIds,
        loadedScreenIds
      );
    }
  }, [screen.id, setSubscriptions]);

  useEffect(() => {
    appSubscriptionIds.current.clear();
    linkSubscriptionIds.current.clear();
    channelSubscriptionIds.current.clear();
    playlistSubscriptionIds.current.clear();
    fileSubscriptionIds.current.clear();
    layoutSubscriptionIds.current.clear();
    spaceSubscriptionIds.current.clear();
    screenSubscriptionIds.current.clear();
  }, [liveUpdateClient]);

  useEffect(() => {
    setAppSubscriptions();
  }, [setAppSubscriptions]);

  useEffect(() => {
    setLinkSubscriptions();
  }, [setLinkSubscriptions]);

  useEffect(() => {
    setChannelSubscriptions();
  }, [setChannelSubscriptions]);

  useEffect(() => {
    setPlaylistSubscriptions();
  }, [setPlaylistSubscriptions]);

  useEffect(() => {
    setFileSubscriptions();
  }, [setFileSubscriptions]);

  useEffect(() => {
    setLayoutSubscriptions();
  }, [setLayoutSubscriptions]);

  useEffect(() => {
    setSpaceSubscriptions();
  }, [setSpaceSubscriptions]);

  useEffect(() => {
    setScreenSubscriptions();
  }, [setScreenSubscriptions]);

  useInterval(() => {
    const itemsToRemove: string[] = [];

    function addToRemoveList(
      collection: { [key: string]: unknown },
      entityId: string,
      notificationId: string
    ): void {
      if (!collection[entityId]) {
        itemsToRemove.push(notificationId);
      }
    }

    Object.values(liveUpdates.byId).forEach((item) => {
      const { entityType, entityId } = decomposeLiveUpdateId(item.id);
      switch (entityType) {
        case EntityType.APP_INSTANCE:
          addToRemoveList(apps.byId, entityId, item.id);
          break;
        case EntityType.SCREEN:
          addToRemoveList({ [screen.id]: screen }, entityId, item.id);
          break;
        case EntityType.LINK:
          addToRemoveList(links.byId, entityId, item.id);
          break;
        case EntityType.CHANNEL:
          addToRemoveList(channels.byId, entityId, item.id);
          break;
        case EntityType.SPACE:
          addToRemoveList(spaces.byId, entityId, item.id);
          break;
        case EntityType.LAYOUT:
          addToRemoveList(layouts.byId, entityId, item.id);
          break;
        case EntityType.FILE:
          addToRemoveList(files.byId, entityId, item.id);
          break;
        case EntityType.PLAYLIST:
          addToRemoveList(playlistContentLists.byId, entityId, item.id);
          break;
        default:
          throw new Error(`Unexpected notification entity type: ${entityType}`);
      }
    });

    if (itemsToRemove.length > 0) {
      dispatch(cleanupNotifications(itemsToRemove));
    }
  }, cleanupInterval ?? HOUR_DURATION_MS);
  return <></>;
};
