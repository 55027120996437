import { ContentSizeType } from "../types/content";
import { ScheduleRules } from "../utils/scheduleFilter";
import { ScreenRotation } from "../queries";

/**
 * Last resort location for sharing types.
 * Most types should belong to the relevant parts of the store.
 */

export type Datetime = string;

export type GqlContentType =
  | "channel"
  | "playlist"
  | "app"
  | "file"
  | "link"
  | "site";

export interface GqlScreenContentJson {
  _ref: {
    id: string;
    type: GqlContentType;
  };
  props?: GqlContentProps;
}

interface GqlChannelContentLayoutJson {
  zones: number;
  config: {
    css: string;
  };
}
export interface GqlChannelContentJson {
  props?: {
    default_durations?: DefaultDurations;
    // channel's props.layout (if exists) will override the layoutByChannel.
    // This is mainly used to update the stylesheet dynamically for the
    // Splash Channel to match the selected Splash Theme from user.
    layout?: GqlChannelContentLayoutJson;
  };
  zones: {
    [key: string]: GqlZoneContentJson;
  };
}

interface GqlZoneContentJson {
  list: GqlContentListItem[] | undefined;
  props?: {
    sizing_type?: GqlZoneItemSizeType;
    transition?: GqlZoneItemTransition;
  };
}

export interface GqlPlaylistContentJson {
  props?: {
    default_durations?: DefaultDurations;
    play_mode?: string;
    total_items?: number;
    total_durations?: number;
  };
  list: GqlContentListItem[];
}

export interface DefaultDurations {
  app?: number;
  link?: number;
  site?: number;
  image?: number;
  document?: number;
}

export interface GqlContentRef {
  _ref: {
    id: string;
    type: GqlContentType;
  };
  props?: GqlContentProps;
  meta?: GqlContentMeta;
}

export interface GqlContentListItem {
  rules?: ScheduleRules[];
  content: GqlContentRef;
  list_id: string;
}

export interface GqlContentProps {
  duration?: number;
  default_durations?: DefaultDurations;
  default_transition?: GqlZoneItemTransition;
  cover?: unknown; // TODO - What type is this?
  layout?: {
    config: GqlLayoutConfigJson;
    zones?: number;
  };
  sizing_type?: SizingTypeRules;
  transition?: GqlZoneItemTransition;
}

export interface GqlContentMeta {
  [key: string]: unknown;
}

interface SizingTypeRules {
  document?: SizingType;
  image?: SizingType;
  video?: SizingType;
}

export enum TransitionType {
  None = "none",
  Fade = "fade",
  Dissolve = "dissolve",
  Slide = "slide",
  Flip = "flip",
  Bounce = "bounce",
  Rotate = "rotate",
  Zoom = "zoom",
  Roll = "roll",
  JackInTheBox = "jackinthebox",
}

export type SizingType = "fit" | "fill";

export type Direction =
  | "center"
  | "left"
  | "right"
  | "up"
  | "down"
  | "clockwise"
  | "counterclockwise";

export interface GqlZoneItemSizeType {
  image?: ContentSizeType;
  document?: ContentSizeType;
  video?: ContentSizeType;
}

export interface GqlZoneItemTransition {
  color?: string;
  direction?: Direction;
  duration: number;
  type: TransitionType;
}

interface GqlLayoutZoneItem {
  id: string;
  name: string;
}

export type SCREEN_ORIENTATION = "landscape" | "portrait";

export interface GqlLayoutConfigJson {
  css: string;
  height: number;
  width: number;
  is_flexible: boolean;
  is_scalable: boolean;
  name: string;
  zones: GqlLayoutZoneItem[];
}

export interface GqlEnv {
  sc_address: string;
  sc_country: string;
  sc_latitude: unknown;
  sc_locality: string;
  sc_longitude: unknown;
  sc_utc_offset: unknown;
}

// These are the ScreenDevice props that the player receives.
export interface GqlScreenDeviceJson {
  id: string;
  settings: {
    name: string;
    rotation?: ScreenRotation;
    updatedAt: number;
    orientation: SCREEN_ORIENTATION;
  };
  player_width: number;
  player_height: number;
  releaseVersion: string;
  deviceSystemTimezone: string;
  screenId: string;
  connection: string;
  deviceModel: string;
  connected_at: number;
  devicePlatform: string;
  disconnected_at: number;
  overrideTimezone?: string;
}

export interface GqlScreenPreferencesJson {
  operating?: Operating;
}

export interface SpacePreferences {
  operating?: Operating;
}

export interface Operating {
  alwaysOn?: boolean;
  enable: boolean;
  day?: OperatingDay;
}

export interface OperatingTimeRange {
  start: number;
  end: number;
  enable: boolean;
}

export interface OperatingDay {
  sun?: OperatingTimeRange;
  mon?: OperatingTimeRange;
  tue?: OperatingTimeRange;
  wed?: OperatingTimeRange;
  thu?: OperatingTimeRange;
  fri?: OperatingTimeRange;
  sat?: OperatingTimeRange;
}

export interface OrgPreferences {
  player?: {
    player_log_config?: {
      endpoint: string;
    };
  };
}

// These are the ScreenDevice props that the player sends. Most values are optional.
export interface GqlScreenDeviceRequestJson {
  id?: string;
  settings?: {
    name: string;
    rotation?: ScreenRotation;
    updatedAt: number;
    orientation: SCREEN_ORIENTATION;
  };
  player_width: number;
  player_height: number;
  releaseVersion?: string;
  deviceSystemTimezone?: string;
  screenId?: string;
  connection?: string;
  deviceModel?: string;
  connected_at?: number;
  devicePlatform?: string;
  disconnected_at?: number;
  overrideTimezone?: string;
}
