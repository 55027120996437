import {
  NEXT_ITEM,
  NextItemAction,
  SET_TIMELINE_CONTROL_OFFSET,
  SetTimelineControlOffsetAction,
  TIMELINE_SKIP_BACK,
  TIMELINE_SKIP_FORWARD,
  TimelineSkipBackAction,
  TimelineSkipForwardAction,
} from "./types";

export function nextItemAction(
  timelineId: string,
  targetTimestamp: number,
  deterministicTimelineStartTimestamp?: number
): NextItemAction {
  return {
    type: NEXT_ITEM,
    payload: {
      timelineId,
      targetTimestamp,
      deterministicTimelineStartTimestamp,
    },
  };
}

export function setTimelineControlOffset(
  offsetValue: number
): SetTimelineControlOffsetAction {
  return {
    type: SET_TIMELINE_CONTROL_OFFSET,
    payload: {
      offsetValue,
    },
  };
}

export function timelineSkipForward(): TimelineSkipForwardAction {
  return {
    type: TIMELINE_SKIP_FORWARD,
  };
}

export function timelineSkipBack(): TimelineSkipBackAction {
  return {
    type: TIMELINE_SKIP_BACK,
  };
}
