import React, { FunctionComponent, memo } from "react";
import classnames from "classnames";
import { isLocalDev } from "../../../../utils/devUtils";
import { GenericViewer } from "../GenericViewer/GenericViewer";
import { isTimelineItemVoid } from "../../../../utils/contentItemTypeGuards";
import Transition, {
  isCrossTransition,
} from "../../../core/components/Transition/Transition";
import { SlotProps } from "./types";
import { TransitionStatus } from "../../../core/components/Transition/types";
import { GqlZoneItemTransition } from "../../../../store/graphqlTypes";
import { timelineSlotPrefix } from "../../../../test-utils/testIds";
import styles from "./Slot.module.css";

/*

  General Summary:

  With transitions:
    With cross animation (e.g. slide):
      1. Active item is rendered with transitionStatus as `undefined`.
      2. Preload item is rendered at `preload duration` transitionStatus `undefined`.
      3. Active item transitionStatus is set to `Transition.out` at time `active item end - transition duration`. Preload item transitionStatus is set to `Transition.in` at same time.
      4. After transition is completed, previous active item is removed and preload item becomes new active item. transitionStatus is `undefined` because it has already transitioned in as a preload item.

    Without cross animation (e.g. fade):
      1. Active item is rendered with transitionStatus as `Transition.in`.
      2. Preload item is rendered at `preload duration` before end of active item with transitionStatus as `undefined`.
      3. Active item transitionStatus is set to `Transition.out` at time `item end - transition duration`.
      4. After active item transition is completed, preload item becomes active item and gets transitionStatus `Transition.in`.


  Without transitions:
    1. Active item is rendered.
    2. Preload item is rendered at `preload duration` before end of active item.
    3. Active item is removed when duration elapsed.
    4. Preload item becomes active item.

*/

function e2eTestSlotIdentifier(index: number): string {
  return `slot-${index + 1}`;
}

const getPreloadSlotTransitionStatus = (
  isActiveItemTransitioningOut: boolean,
  crossTransition?: boolean
): TransitionStatus | undefined => {
  if (isActiveItemTransitioningOut && crossTransition) {
    return TransitionStatus.in;
  }
  return undefined;
};

const getActiveSlotTransitionStatus = (
  isActiveItemTransitioningOut: boolean,
  transition?: GqlZoneItemTransition,
  crossTransition?: boolean
) => {
  if (transition && isActiveItemTransitioningOut) {
    return TransitionStatus.out;
  }
  if (!transition) {
    return undefined;
  }
  return crossTransition ? undefined : TransitionStatus.in;
};

const InnerSlot = memo(
  ({
    item,
    transition,
    isActiveItemTransitioningOut,
    isPreload,
    isPreloadingStarted,
  }: SlotProps) => {
    if (
      !item ||
      isTimelineItemVoid(item) ||
      (isPreload && !isPreloadingStarted)
    ) {
      return null;
    }

    const crossTransition =
      transition && isCrossTransition(transition.type, transition?.direction);

    const transitionStatus = isPreload
      ? getPreloadSlotTransitionStatus(
          isActiveItemTransitioningOut,
          crossTransition
        )
      : getActiveSlotTransitionStatus(
          isActiveItemTransitioningOut,
          transition,
          crossTransition
        );

    if (transition) {
      return (
        <Transition
          transitionProps={transition}
          transitionStatus={transitionStatus}
        >
          <GenericViewer
            contentItem={item}
            isPreload={isPreload}
            itemStartTimestamp={item.startTimestamp}
          />
        </Transition>
      );
    }

    return (
      <GenericViewer
        contentItem={item}
        isPreload={isPreload}
        itemStartTimestamp={item.startTimestamp}
      />
    );
  }
);
InnerSlot.displayName = "InnerSlot";

const Slot: FunctionComponent<SlotProps> = memo((props: SlotProps) => {
  const crossTransition =
    props.transition &&
    isCrossTransition(props.transition.type, props.transition?.direction);

  /*
    Rules for preload classes:
    - `preloadDevPreview` is applied when preloading has started and in local development mode. This will draw the preview in a red square at bottom right.
    - `preload` is applied when preloading has started and not in local development mode. This will ensure the preview is hidden so the user doesn't see it.
    - `preloadCrossTransition` is applied at the time of the transition if it is a cross transition (e.g. fade). The class overrides the other 2 classes to ensure the item transitions in correctly.
  */
  const classes = classnames({
    "item-slot": true,
    [styles.base]: true,
    [styles.preloadDevPreview]:
      props.isPreloadingStarted && props.isPreload && isLocalDev(),
    [styles.preload]:
      props.isPreloadingStarted && props.isPreload && !isLocalDev(),
    [styles.preloadCrossTransition]:
      crossTransition &&
      props.isPreloadingStarted &&
      props.isPreload &&
      props.isActiveItemTransitioningOut,
    [styles.activeViewer]: !props.isPreload,
  });

  return (
    <div
      className={classes}
      id={e2eTestSlotIdentifier(props.index)}
      aria-label={e2eTestSlotIdentifier(props.index)}
      data-testid={`${timelineSlotPrefix}${props.index}`}
    >
      <InnerSlot {...props} />
    </div>
  );
});
Slot.displayName = "Slot";

export default Slot;
