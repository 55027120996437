import { PlayerState } from "../rootReducer";
import { PlayerAction } from "../storeTypes";
import {
  NEXT_ITEM,
  PlaybackState,
  SET_TIMELINE_CONTROL_OFFSET,
  TimelinePlaybackState,
} from "./types";
import { REPLACE_TIMELINE, UPDATE_TIMELINE } from "../timelines/types";
import isEqual from "lodash/isEqual";

const initialState: PlaybackState = {
  timelines: {},
  controls: {
    timelineOffset: 0, // used to provide playback skipping functionality
  },
};

export function emptyPlaybackReducer(
  state: PlaybackState = initialState,
  action: PlayerAction
): PlaybackState {
  return state;
}

export function playbackReducer(
  state: PlayerState,
  action: PlayerAction
): PlayerState {
  switch (action.type) {
    case NEXT_ITEM:
      return setTimelinePlaybackState(
        state,
        action.payload.timelineId,
        action.payload.targetTimestamp,
        undefined
      );

    case REPLACE_TIMELINE:
    case UPDATE_TIMELINE:
      return setTimelinePlaybackState(
        state,
        action.payload.id,
        action.payload.targetTimestamp,
        action.payload.nextChunkRequestTimestamp
      );

    case SET_TIMELINE_CONTROL_OFFSET:
      return {
        ...state,
        playback: {
          ...state.playback,
          controls: {
            ...state.playback.controls,
            timelineOffset: action.payload.offsetValue,
          },
        },
      };

    default:
      return state;
  }
}

function setTimelinePlaybackState(
  state: PlayerState,
  timelineId: string,
  targetTimestamp: number,
  nextChunkRequestTimestamp: number | undefined
): PlayerState {
  const timeline = state.timelines.byId[timelineId];
  const items = timeline.items;
  const currentPlaybackState = state.playback.timelines[timelineId];

  const nextItemIndex = items.findIndex((item, index) => {
    const followingItem = items[index + 1];
    return (
      item.startTimestamp <= targetTimestamp &&
      (!followingItem || followingItem.startTimestamp > targetTimestamp)
    );
  });

  let update: TimelinePlaybackState;

  if (nextItemIndex > -1) {
    const preloadItemIndex = items[nextItemIndex + 1]
      ? nextItemIndex + 1
      : undefined;
    const activeScreenTime = preloadItemIndex
      ? items[preloadItemIndex].startTimestamp -
        items[nextItemIndex].startTimestamp
      : undefined;

    update = {
      ...currentPlaybackState,
      activeIndex: nextItemIndex,
      activeScreenTimeMs: activeScreenTime,
      preloadIndex: preloadItemIndex,
    };
  } else {
    update = {
      ...currentPlaybackState,
      activeIndex: undefined,
      activeScreenTimeMs: undefined,
      preloadIndex: undefined,
    };
  }

  if (nextChunkRequestTimestamp !== undefined) {
    update.nextChunkRequestTimestamp = nextChunkRequestTimestamp;
  }

  if (isEqual(update, currentPlaybackState)) {
    return state;
  } else {
    return updateTimelinePlayback(state, timelineId, update);
  }
}

function updateTimelinePlayback(
  state: PlayerState,
  timelineId: string,
  update: Partial<TimelinePlaybackState>
): PlayerState {
  const currentPlaybackState = state.playback.timelines[timelineId];

  const updatedPlaybackState = {
    ...currentPlaybackState,
    ...update,
  };

  if (!isEqual(currentPlaybackState, updatedPlaybackState)) {
    return {
      ...state,
      playback: {
        ...state.playback,
        timelines: {
          ...state.playback.timelines,
          [timelineId]: updatedPlaybackState,
        },
      },
    };
  }

  return state;
}
