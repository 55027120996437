import { Modify } from "../storeTypes";
import { NormalizedAppInstanceFragment } from "../apps/types";
import {
  ChannelFragment,
  FileFragment,
  LayoutFragment,
  SpaceFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedPlaylistFragment } from "../playlists/types";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedOrgFragment } from "../organization/types";

export const REQUEST_CHANNEL_SUCCESS = "REQUEST_CHANNEL_SUCCESS";
export const REQUEST_CHANNEL = "REQUEST_CHANNEL";

// Export all action types here.
export type ChannelAction = RequestChannelAction | RequestChannelSuccessAction;

export interface RequestChannelAction {
  type: typeof REQUEST_CHANNEL;
}

export interface ChannelsState {
  byId: {
    [id: string]: Channel;
  };
}

export interface ChannelContentLayout {
  zones: number;
  config: {
    css: string;
  };
}

export interface Channel {
  name: string;
  height: number;
  id: string;
  layoutId: string;
  themeId?: string;
  width: number;

  // todo: this prop is to be removed in next versions. Details in gh issue:
  //  https://github.com/screencloud/studio-player/issues/613
  deprecateContentLayout?: ChannelContentLayout;
  isHiddenZoneActive: boolean;
}

export interface RequestChannelSuccessAction {
  type: typeof REQUEST_CHANNEL_SUCCESS;
  payload: {
    organization: NormalizedOrgFragment | null | undefined;
    apps: {
      [id: string]: NormalizedAppInstanceFragment;
    };
    channels: {
      [id: string]: NormalizedChannelFragment;
    };
    files: {
      [id: string]: FileFragment;
    };
    layouts: {
      [id: string]: LayoutFragment;
    };
    links: {
      [id: string]: NormalizedLinkFragment;
    };
    playlists: {
      [id: string]: NormalizedPlaylistFragment;
    };
    themes: {
      [id: string]: ThemeFragment;
    };
    space: SpaceFragment | null | undefined;
    sites: {
      [id: string]: NormalizedSiteFragment;
    };
  };
}

export type NormalizedChannelFragment = Modify<
  ChannelFragment,
  {
    appInstancesByChannelId: {
      nodes: string[];
    };
    filesByChannelId: {
      nodes: string[];
    };
    layoutByChannel: string;
    themeByThemeId?: string;
    linksByChannelId: {
      nodes: string[];
    };
    playlistsByChannelId: {
      nodes: string[];
    };
    sitesByChannelId: { nodes: string[] };
    orgByOrgId?: string;
  }
>;
