import spacetime, { Spacetime } from "spacetime";
import { WEEK_DAYS, WeekDay } from "./scheduleFilterConstants";
import { HOUR_DURATION_MS } from "../constants";

export interface ParsedDate {
  timestamp: number;
  hour: number;
  minute: number;
  minutesFromDayStart: number;
  secondFromDayStart: number;
  millisecondFromDayStart: number;
  weekDay: WeekDay;
}

export interface TimeOptions {
  timezoneOverride?: string; // timezone value assigned to this player by the user
  timeOffset: number; // offset between device's time and screencloud time server in ms
  timelineControlOffset: number; // time offset in ms, used for providing playback controls functionality
}

export function getDeviceTimezoneName(): string {
  if (Intl) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    let utcOffsetMinutes = new Date().getTimezoneOffset();
    let result = "GMT";

    if (utcOffsetMinutes < 0) {
      result += "-";
      utcOffsetMinutes *= -1;
    } else {
      result += "+";
    }

    result += Math.floor(utcOffsetMinutes / 60).toString();
    result += ":";
    result += (utcOffsetMinutes % 60).toString();

    return result;
  }
}

/**
 * "Now" timestamp with time synchronization and playback control offsets.
 * Represents what player should think is "now" according to all the time and playback settings.
 * If you're working on playback related functionality, you should use this method any time you want to
 * know the "now" timestamp. Maker sure the `options` object is complete and up to date data from redux state.
 */
export function playbackNowTimestamp(options: TimeOptions): number {
  return getUtcTime(combinedTimeOffset(options));
}

/**
 * "Now" timestamp with time synchronization offset only
 * This is the real utc "now" timestamp according to screencloud time server
 */
export function actualUtcNow(options: TimeOptions): number {
  return getUtcTime(options.timeOffset);
}

export function getUtcTime(timeOffset: number): number {
  return spacetime.now().epoch + (timeOffset || 0);
}

export function parseDateInLocalTime(
  options: TimeOptions,
  timestamp: number
): ParsedDate {
  const s = spacetime(timestamp).goto(getTimeZone(options));
  const millisecondFromDayStart =
    s.hour() * HOUR_DURATION_MS +
    s.minute() * 60000 +
    s.second() * 1000 +
    s.millisecond();
  const secondFromDayStart = Math.floor(millisecondFromDayStart / 1000);
  const minutesFromDayStart = Math.floor(secondFromDayStart / 60);

  return {
    hour: s.hour(),
    minute: s.minute(),
    minutesFromDayStart,
    secondFromDayStart,
    millisecondFromDayStart,
    timestamp: timestamp,
    weekDay: WEEK_DAYS[s.day()],
  };
}

export function getDateIsoShort(
  options: TimeOptions,
  timestamp: number
): string {
  const s = targetSpacetime(options, timestamp);
  return s.format("iso-short") as string;
}

export function getTimestampByTime(
  options: TimeOptions,
  time: string,
  date?: string
): number {
  let s = targetSpacetime(options, date);

  s = s.time(time);

  return s.epoch;
}

export function getStartOfDay(
  options: TimeOptions,
  targetDateInput?: string
): number {
  return targetSpacetime(options, targetDateInput).startOf("day").epoch;
}

export function getEndOfDay(
  options: TimeOptions,
  targetDateInput?: string
): number {
  return targetSpacetime(options, targetDateInput).endOf("day").epoch;
}

/**
 * Takes a string input of date and time (may also contain timezone) and produces a timestamp that would produce the
 * exact string representation, but in the timezone of the device or timezone overridden in options
 */
export function convertDateToLocalTimezone(
  options: TimeOptions,
  targetDate: string
): number {
  const s1 = spacetime(targetDate);
  const year = s1.year();
  const month = s1.month();
  const date = s1.date();
  const hour = s1.hour();
  const minute = s1.minute();
  const second = s1.second();

  const s2 = targetSpacetime(options)
    .year(year)
    // todo: remove ts ignore, when spacetime will update the .month(value) type declaration
    // eslint-disable-next-line
    // @ts-ignore
    .month(month)
    .date(date)
    .hour(hour)
    .minute(minute)
    .second(second)
    .millisecond(0);

  return s2.epoch;
}

/**
 * Returns spacetime object with applied TimeOptions
 * @param options
 * @param targetDate - strings should not contain timezone info, otherwise it will override timezoneOverride in options
 */
export function targetSpacetime(
  options: TimeOptions,
  targetDate?: string | number
): Spacetime {
  if (!targetDate) {
    return spacetime(playbackNowTimestamp(options)).goto(getTimeZone(options));
  } else if (typeof targetDate === "number") {
    return spacetime(targetDate as number, getTimeZone(options));
  } else {
    return spacetime(targetDate as string, getTimeZone(options));
  }
}

function getTimeZone(options: TimeOptions): string {
  const now = spacetime(playbackNowTimestamp(options));

  if (options && options.timezoneOverride) {
    const timeZoneTimestamp = now.goto(options.timezoneOverride);
    return timeZoneTimestamp.timezone().name;
  } else {
    return now.timezone().name;
  }
}

function combinedTimeOffset(options: TimeOptions): number {
  return options.timeOffset + options.timelineControlOffset;
}
