import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { parseContentListId } from "../../../../store/contentLists/utils";
import { ContentListReportItem, ContentListUpdateReport } from "./types";
import ClientApiContext from "../../../../ClientApiContext";
import {
  ContentItemLeaf,
  ContentList,
} from "../../../../store/contentLists/types";

interface ContentListUpdateReportContainerProps {
  contentListId: string;
}

function produceReportItem(
  contentListItem: ContentItemLeaf
): ContentListReportItem {
  return {
    id: contentListItem.id,
    listId: contentListItem.listId,
    type: contentListItem.type,
    parentEntityListId: contentListItem.parent?.listId,
    parentEntityId: contentListItem.parent?.id,
  };
}

export const ContentListUpdateReportContainer: FunctionComponent<ContentListUpdateReportContainerProps> = (
  props
) => {
  const clientApiContext = useContext(ClientApiContext);
  const remoteApi = clientApiContext?.remoteApi;

  const { contentListId } = props;

  const targetContentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[contentListId]
  );
  const targetItems = targetContentList?.items;

  const targetZoneId = useMemo<string | undefined>(
    () => parseContentListId(contentListId).zoneId,
    [contentListId]
  );

  const report = useMemo<ContentListUpdateReport | undefined>(() => {
    if (!targetItems) {
      return undefined;
    }

    return {
      zoneId: targetZoneId,
      items: targetItems.map(produceReportItem),
    };
  }, [targetItems, targetZoneId]);

  useEffect(() => {
    if (report && remoteApi) {
      remoteApi.reportContentListUpdate(report);
    }
  }, [remoteApi, report]);

  return <></>;
};
