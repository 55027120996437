import React, { FunctionComponent, useEffect, useMemo } from "react";
import { TimelineOperatorPropsBase } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { ContentList } from "../../../../store/contentLists/types";
import { PlayerState } from "../../../../store/rootReducer";
import { Timeline, TimelineItem } from "../../../../store/timelines/types";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import {
  getOptimizedGenerateTimeline,
  OptimizedGeneratorFunction,
} from "../../../timeline/localGenerator";
import { playbackNowTimestamp } from "../../../../utils/timeManager";
import {
  replaceTimelineAction,
  updateTimelineAction,
} from "../../../../store/timelines/actions";
import { FilesState } from "../../../../store/files/types";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { EntityType } from "@screencloud/signage-firestore-client";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { DAY_DURATION_MS } from "../../../../constants";

type TimelineOperatorLocalProps = TimelineOperatorPropsBase & {
  testOnLiveUpdateChange?: (collection: LiveUpdateCollection) => unknown; // for test purposes only
};

export interface LiveUpdateCollection {
  fileIds: string[];
  appIds: string[];
  playlistIds: string[];
}

const DEFAULT_ITEMS_AHEAD = 34;
const DEFAULT_MIN_HISTORY_SIZE = 10;
const DEFAULT_TIME_AHEAD = DAY_DURATION_MS;

/**
 * Returns timestamp, when next timeline chunk should be requested
 */
function nextChunkRequestTimestamp(
  requestedFragmentStartTimestamp: number, // requested timeline fragment start
  requestedMaxTime: number, // requested maximum timeline chunk duration
  requestedItemsAhead: number, // requested maximum number of items
  generatedTimelineChunk: TimelineItem[] // generated chunk
): number {
  const itemsAhead = generatedTimelineChunk.filter(
    (item) => item.startTimestamp > requestedFragmentStartTimestamp
  );

  if (generatedTimelineChunk.length === 0) {
    // todo: should we throw Error?? Timeline generator should always return at least 1 item
    return requestedFragmentStartTimestamp + requestedMaxTime - 1000 * 60;
  }

  if (itemsAhead.length >= requestedItemsAhead && itemsAhead.length > 0) {
    // if generator returned exact amount of items, as were requested - we should generate new chunk at the start of
    //  the last item
    return itemsAhead[itemsAhead.length - 1].startTimestamp;
  } else {
    // if generator returned less items than we requested, - that means that it hit the max generation time limit,
    //  which means we should request new timeline chunk, when the time is close to max time limit that was set
    return requestedFragmentStartTimestamp + requestedMaxTime - 1000 * 60;
  }
}

const TimelineOperatorLocal: FunctionComponent<TimelineOperatorLocalProps> = ({
  timelineId,
  amountOfItemsAhead,
  timeAheadMs,
  testOnLiveUpdateChange,
}: TimelineOperatorLocalProps) => {
  const itemsAhead = amountOfItemsAhead || DEFAULT_ITEMS_AHEAD;
  const generateTimeAhead = timeAheadMs ?? DEFAULT_TIME_AHEAD;

  const contentListId = timelineId;
  const contentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[contentListId]
  );
  const timeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => state.timelines.byId[timelineId]
  );
  const timelineItems = timeline?.items;

  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => state.playback.timelines[timelineId]);
  const files = useSelector<PlayerState, FilesState>((state) => state.files);
  const dispatch = useDispatch();

  const timeOptions = useTimeOptions();

  const generateTimeline = useMemo<OptimizedGeneratorFunction>(() => {
    if (!contentList) {
      return getOptimizedGenerateTimeline(
        { id: "", items: [], publishedAt: "" },
        files,
        timeOptions
      );
    }

    return getOptimizedGenerateTimeline(contentList, files, timeOptions);
  }, [contentList, files, timeOptions]);

  useEffect(() => {
    /**
     * Generate and replace timeline with updated content
     */
    if (contentList !== undefined) {
      const now = playbackNowTimestamp(timeOptions);
      const timelineUpdate = generateTimeline({
        targetStartTimestamp: now,
        maxItemsAhead: itemsAhead,
        maxTimeAheadMs: generateTimeAhead,
        maxItemsBehind: 2,
        maxTimeBehindMs: DAY_DURATION_MS * 3,
      });
      const nextChunkRequestTime = nextChunkRequestTimestamp(
        now,
        generateTimeAhead,
        itemsAhead,
        timelineUpdate
      );
      dispatch(
        replaceTimelineAction(
          timelineUpdate,
          timelineId,
          now,
          nextChunkRequestTime
        )
      );
    } else {
      // todo: remove the else clause, when TimelineViewer component is fully covered with tests
      //  https://github.com/screencloud/studio-player/issues/659
      dispatch(
        replaceTimelineAction(
          [
            {
              type: "void",
              startTimestamp: -Infinity,
              fullDurationMs: Infinity,
              isInfinite: true,
            },
          ],
          timelineId,
          playbackNowTimestamp(timeOptions),
          0 // we don't update until content changes in this case
        )
      );
    }
  }, [
    contentList,
    dispatch,
    timelineId,
    timeOptions,
    itemsAhead,
    generateTimeAhead,
    generateTimeline,
  ]);

  useEffect(() => {
    /**
     * Produce next timeline fragment
     */
    const timeoutValue =
      typeof playbackState?.nextChunkRequestTimestamp === "number"
        ? playbackState.nextChunkRequestTimestamp -
          playbackNowTimestamp(timeOptions)
        : undefined;

    if (
      contentList &&
      timelineItems &&
      timeoutValue !== undefined &&
      timeoutValue > 0
    ) {
      const timeout = window.setTimeout(() => {
        const now = playbackNowTimestamp(timeOptions);
        const targetTimestamp = now;

        const lastHistoryItemIndex = timelineItems.findIndex(
          (item, index) =>
            item.startTimestamp < now &&
            (timelineItems[index + 1] === undefined ||
              timelineItems[index + 1].startTimestamp >= now)
        );

        let cleanupAmount = 0;

        if (lastHistoryItemIndex > DEFAULT_MIN_HISTORY_SIZE) {
          cleanupAmount = lastHistoryItemIndex - DEFAULT_MIN_HISTORY_SIZE;
        }

        const generateAmount: number = itemsAhead;

        const timelineUpdate = generateTimeline({
          targetStartTimestamp: targetTimestamp,
          maxItemsAhead: generateAmount,
          maxTimeAheadMs: generateTimeAhead,
          maxItemsBehind: 0,
          maxTimeBehindMs: 0,
        });

        const nextUpdateTime = nextChunkRequestTimestamp(
          targetTimestamp,
          generateTimeAhead,
          generateAmount,
          timelineUpdate
        );

        dispatch(
          updateTimelineAction(
            timelineUpdate,
            timelineId,
            playbackNowTimestamp(timeOptions),
            cleanupAmount,
            nextUpdateTime
          )
        );
      }, timeoutValue);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [
    contentList,
    dispatch,
    playbackState?.nextChunkRequestTimestamp,
    generateTimeAhead,
    itemsAhead,
    timeOptions,
    timelineItems,
    timelineId,
    generateTimeline,
  ]);

  const contentListItems = contentList?.items;

  const liveUpdateCollection = useMemo<LiveUpdateCollection>(() => {
    const fileSet = new Set<string>();
    const appSet = new Set<string>();
    const playlistSet = new Set<string>();

    if (contentListItems) {
      contentListItems?.forEach((item) => {
        switch (item.type) {
          case "app":
            appSet.add(item.id);
            break;
          case "file":
            fileSet.add(item.id);
            break;
        }

        if (item.parent && item.parent.type === "playlist") {
          playlistSet.add(item.parent.id);
        }
      });
    }

    return {
      fileIds: [...fileSet],
      appIds: [...appSet],
      playlistIds: [...playlistSet],
    };
  }, [contentListItems]);

  useEffect(() => {
    // test purpose only
    if (testOnLiveUpdateChange) {
      testOnLiveUpdateChange(liveUpdateCollection);
    }
  }, [liveUpdateCollection, testOnLiveUpdateChange]);

  return (
    <>
      {/**
       * Local timeline operator generates timeline based on the items, included into respective contentList.
       * Therefore all items that may affect the schedule filtering are connected to live updates below
       */}
      {liveUpdateCollection.fileIds.map((fileId) => (
        <LiveUpdateConnector
          key={fileId}
          entityId={fileId}
          entityType={EntityType.FILE}
        />
      ))}
      {liveUpdateCollection.appIds.map((appId) => (
        <LiveUpdateConnector
          key={appId}
          entityId={appId}
          entityType={EntityType.APP_INSTANCE}
        />
      ))}
      {liveUpdateCollection.playlistIds.map((playlistId) => (
        <LiveUpdateConnector
          key={playlistId}
          entityId={playlistId}
          entityType={EntityType.PLAYLIST}
        />
      ))}
    </>
  );
};

export default TimelineOperatorLocal;
