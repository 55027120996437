import { getContentContext } from "./contentContext";
import { AppsState } from "../../../../store/apps/types";
import { FilesState } from "../../../../store/files/types";
import { LinksState } from "../../../../store/links/types";
import { ScreenState } from "../../../../store/screen/types";
import { OrganizationState } from "../../../../store/organization/types";
import { getCommitHash } from "../../../../utils/helpers";
import { getSourceDetail } from "./sourceDetail";
import { usePreviousValue } from "../../../../utils/usePreviousValue";
import { SitesState } from "../../../../store/sites/types";
import { Logger } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";

const log = new Logger("singleContentLogs");

export interface SingleContentLogsProps {
  apps: AppsState;
  files: FilesState;
  links: LinksState;
  sites: SitesState;
  screen: ScreenState;
  platform: string;
  organization: OrganizationState;
}

export const useSingleContentLogs = ({
  apps,
  files,
  links,
  sites,
  screen,
  platform,
  organization,
}: SingleContentLogsProps): void => {
  const activeContentItem = screen.activeContentItem;
  const previousItemContent = usePreviousValue(activeContentItem);

  const previousItemContentId =
    !previousItemContent || previousItemContent.type === "void"
      ? undefined
      : previousItemContent.id;
  const activeContentItemId =
    !activeContentItem || activeContentItem.type === "void"
      ? undefined
      : activeContentItem.id;
  const emptyItem = {
    zoneId: "root",
  };

  // check to not log in preview mode
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );

  if (previousItemContent !== activeContentItem) {
    // log end of previous item
    if (!previousItemContent || previousItemContent.type === "void") {
      log.info(
        "Finish empty screen period.",
        {
          ...emptyItem,
          isPreview: isPreview,
        },
        true
      );
    } else if (
      previousItemContent.type !== activeContentItem?.type || // A: I'm wonder why we need to check the type?, id should be all unique?
      previousItemContentId !== activeContentItemId
    ) {
      log.info(
        `Finish playback Single Content`,
        {
          platform,
          zoneId: "root",
          status: screen.status,
          screenId: screen.id,
          spaceId: screen.spaceId || "unavailable",
          deviceId: screen.deviceMeta.id,
          contentId: previousItemContent.id,
          contentType: previousItemContent.type,
          ...getSourceDetail("single-content", previousItemContent),
          playerVersion: getCommitHash(),
          ...getContentContext(
            previousItemContent.id,
            previousItemContent.type,
            apps,
            files,
            links,
            sites
          ),
          screenName: screen.deviceMeta.settings.name,
          location: screen.screenData?.sc_address,
          orgId: organization.id,
          isPreview: isPreview,
        },
        true
      );
    }

    // log start of new item
    if (!activeContentItem) {
      log.info("Screen is empty. No active item assigned.", {
        ...emptyItem,
        isPreview: isPreview,
      });
    } else if (
      activeContentItem.type === "void" &&
      activeContentItem.type !== previousItemContent?.type
    ) {
      log.info(
        "Screen is empty. Void item assigned to screen.",
        emptyItem,
        true
      );
    } else if (
      previousItemContent?.type !== activeContentItem.type ||
      previousItemContentId !== activeContentItemId
    ) {
      log.info(
        `Start playback Single Content`,
        {
          platform,
          zoneId: "root",
          status: screen.status,
          screenId: screen.id,
          spaceId: screen.spaceId || "unavailable",
          deviceId: screen.deviceMeta.id,
          contentId: activeContentItemId,
          contentType: activeContentItem.type,
          ...getSourceDetail("single-content", activeContentItem),
          playerVersion: getCommitHash(),
          ...getContentContext(
            activeContentItemId || "",
            activeContentItem.type,
            apps,
            files,
            links,
            sites
          ),
          screenName: screen.deviceMeta.settings.name,
          location: screen.screenData?.sc_address,
          orgId: organization.id,
          isPreview: isPreview,
        },
        true
      );
    } else {
      log.error(
        "SINGLE-CONTENT-LOG CANNOT RESOLVE ANYTHING",
        {
          isPreview: isPreview,
        },
        true
      );
    }
  }
};
