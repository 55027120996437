interface ReduxPersistLog {
  timestamp: number;
}

export class ReduxPersistCounter {
  private reduxPersistTimestamps: ReduxPersistLog[] = [];
  private duration = 0;

  constructor(_duration: number) {
    if (_duration > 0) {
      this.duration = _duration;
    }
  }

  // adds reduxPersistTimestamps and returns the number of reduxPersistTimestamps added
  public add(): number {
    this.retain();
    this.reduxPersistTimestamps.push({ timestamp: Date.now() });
    return this.reduxPersistTimestamps.length;
  }

  private retain(): void {
    const timestampCuttingPoint = Date.now() - this.duration;
    this.reduxPersistTimestamps = this.reduxPersistTimestamps.filter(
      ({ timestamp }) => timestamp >= timestampCuttingPoint
    );
  }
}
