import { ContentItemLeaf, ContentList } from "../../store/contentLists/types";
import { TimelineItem, TimelineItemContent } from "../../store/timelines/types";
import {
  getDateIsoShort,
  targetSpacetime,
  TimeOptions,
} from "../../utils/timeManager";
import { FilesState } from "../../store/files/types";
import {
  getMemoizedFilterFunction,
  getScheduleBreakpointsForItemList,
  ScheduleFilterReport,
} from "../../utils/scheduleFilter";
import moize from "moize";
import { DAY_DURATION_MS } from "../../constants";
// import { captureMessage } from "../../utils/bugTracker";
import { Logger } from "../../logger/logger";
import { captureMessage } from "@sentry/browser";

const log = new Logger("localGenerator");

export interface TimelineFragmentGeneratorOptions {
  targetStartTimestamp: number; // timeline fragment start
  maxItemsAhead: number; // max items to generate ahead of fragment start
  maxTimeAheadMs: number; // max time to generate ahead of fragment start
  maxItemsBehind: number; // max items to generate behind the fragment start
  maxTimeBehindMs: number; // max time to generate behind the fragment start
}

/**
 * Returns optimised timeline generator funciton.
 * Optimization is achieve by applying memoization to expensive functions.
 * Using this getter allows you to keep the cache alive as long as you keep the reference to the optimized function.
 */
export function getOptimizedGenerateTimeline(
  contentList: ContentList,
  files: FilesState,
  timeOptions: TimeOptions,
  timelineStartTimestampOverride?: number // this allows to override timeline starting point externally
): OptimizedGeneratorFunction {
  const memoizedBreakpointsGetter = moize(
    // Memory consumption note:
    //  We memoize arrays of numbers here, which means we need to store millions of items to see any noticeable
    //  memory impact. The number of return results is limited with `maxSize` option, which gives us peace of mind.
    (dateIsoShort: string) => {
      return getScheduleBreakpointsForItemList(
        contentList.items,
        dateIsoShort,
        timeOptions
      );
    },
    { maxSize: 6, maxAge: DAY_DURATION_MS }
  );

  const filterResultGetter = getMemoizedFilterFunction(
    contentList.items,
    timeOptions,
    memoizedBreakpointsGetter,
    true
  );

  return (generatorOptions: TimelineFragmentGeneratorOptions) => {
    return generateTimeline(
      generatorOptions,
      contentList,
      files,
      timeOptions,
      memoizedBreakpointsGetter,
      filterResultGetter,
      timelineStartTimestampOverride
    );
  };
}

/**
 * Generates timeline fragment
 */
function generateTimeline(
  generatorOptions: TimelineFragmentGeneratorOptions,
  contentList: ContentList,
  files: FilesState,
  timeOptions: TimeOptions,
  memoizedBreakpointsGetter: ScheduleBreakpointsGetter,
  filterResultGetter: FilterFunction,
  timelineStartTimestampOverride?: number // this allows to override timeline starting point externally
): TimelineItem[] {
  const publishedAtTimestamp = targetSpacetime(
    timeOptions,
    contentList.publishedAt
  ).epoch;

  const itemsAhead = generateAhead(
    generatorOptions,
    contentList,
    publishedAtTimestamp,
    files,
    timeOptions,
    memoizedBreakpointsGetter,
    filterResultGetter,
    timelineStartTimestampOverride
  );

  const itemsBehind = generateBehind(
    generatorOptions,
    contentList,
    publishedAtTimestamp,
    files,
    timeOptions,
    memoizedBreakpointsGetter,
    filterResultGetter,
    timelineStartTimestampOverride
  );

  const lastItemBehind = itemsBehind[itemsBehind.length - 1];
  const firstItemAhead = itemsAhead[0];

  if (
    lastItemBehind &&
    firstItemAhead &&
    lastItemBehind.type === firstItemAhead.type &&
    (lastItemBehind.type === "void" ||
      lastItemBehind.id === (firstItemAhead as TimelineItemContent).id)
  ) {
    // if last item behind and first item ahead are the same - we just extend last item behind to avoid duplicate
    //  item in timeline
    itemsAhead.shift();
  }

  return itemsBehind.concat(itemsAhead);
}

function generateAhead(
  generatorOptions: {
    maxItemsAhead: number;
    maxTimeAheadMs: number;
    targetStartTimestamp: number;
  },
  contentList: ContentList,
  publishedAtTimestamp: number,
  files: FilesState,
  timeOptions: TimeOptions,
  memoizedBreakpointsGetter: ScheduleBreakpointsGetter,
  memoizedFilterFunction: FilterFunction,
  timelineStartTimestampOverride?: number
): TimelineItem[] {
  const result: TimelineItem[] = [];
  const {
    targetStartTimestamp,
    maxItemsAhead,
    maxTimeAheadMs,
  } = generatorOptions;

  // add + 1, because item that is active at targetStartTimestamp is included in the result as well
  const maxItems = maxItemsAhead + 1;

  let targetTimestamp = targetStartTimestamp;

  let i = 0;
  const iterationsLimit = maxItems * 2; // if it takes twice the number of items - something is wrong

  while (
    result.length < maxItems &&
    targetTimestamp < targetStartTimestamp + maxTimeAheadMs &&
    targetStartTimestamp !== Infinity && // protect from infinite loop
    i < iterationsLimit // protect from infinite loop
  ) {
    i++;
    const scheduleBreakpoints = memoizedBreakpointsGetter(
      getDateIsoShort(timeOptions, targetTimestamp)
    );

    const { timelineItem, nextItemStartTimestamp } = produceTimelineItemReport(
      contentList,
      targetTimestamp,
      publishedAtTimestamp,
      timeOptions,
      files,
      memoizedFilterFunction,
      scheduleBreakpoints,
      timelineStartTimestampOverride
    );

    const previousItem = result[result.length - 1];

    if (
      previousItem &&
      previousItem.type === timelineItem.type &&
      (timelineItem.type === "void" ||
        (previousItem as TimelineItemContent).id ===
          (timelineItem as TimelineItemContent).id)
    ) {
      // it is possible that the item doesn't change on a schedule breakpoint. we just extend it here instead of
      // pushing a separate item of the same type and id as previous
    } else {
      result.push(timelineItem);
    }
    targetTimestamp = nextItemStartTimestamp;

    if (previousItem?.startTimestamp === timelineItem.startTimestamp) {
      // this should never happen in real life. If it happens, that will mean infinite loop, so we break right away
      //  to avoid halting the device. This should be reported to developers and investigated

      // TODO remove bug tracker

      captureMessage(
        "Unexpected startTimestamp of timeline item while executing generateAhead() function. Previous item's startTimestamp equals new item's startTimestamp."
      );
      log.warn(
        "Unexpected startTimestamp of timeline item while executing generateAhead() function. Previous item's startTimestamp equals new item's startTimestamp.",
        {
          startTimestamp: timelineItem.startTimestamp,
        }
      );
      break;
    }
    if (i >= iterationsLimit) {
      // TODO remove bug tracker

      captureMessage(
        "Hit iterations limit while executing generateAhead() function"
      );
      log.warn(
        "Hit iterations limit while executing generateAhead() function",
        {
          iterationsLimit: iterationsLimit,
        }
      );
    }
  }

  return result;
}

function generateBehind(
  generatorOptions: {
    maxItemsBehind: number;
    maxTimeBehindMs: number;
    targetStartTimestamp: number;
  },
  contentList: ContentList,
  publishedAtTimestamp: number,
  files: FilesState,
  timeOptions: TimeOptions,
  memoizedBreakpointsGetter: ScheduleBreakpointsGetter,
  memoizedFilterFunction: FilterFunction,
  timelineStartTimestampOverride?: number
): TimelineItem[] {
  const result: TimelineItem[] = [];
  const {
    targetStartTimestamp,
    maxItemsBehind,
    maxTimeBehindMs,
  } = generatorOptions;

  // add + 1, because item that is active at targetStartTimestamp is included in the result as well
  const maxItems = maxItemsBehind + 1;

  let targetTimestamp = targetStartTimestamp;

  let i = 0;
  const iterationsLimit = maxItems * 2; // if it take twice the number of items - something is wrong

  while (
    result.length < maxItems &&
    targetTimestamp > targetStartTimestamp - maxTimeBehindMs &&
    targetStartTimestamp !== -Infinity && // protect from infinite loop
    i < iterationsLimit // protect from infinite loop.
  ) {
    i++;
    const scheduleBreakpoints = memoizedBreakpointsGetter(
      getDateIsoShort(timeOptions, targetTimestamp)
    );

    const { timelineItem } = produceTimelineItemReport(
      contentList,
      targetTimestamp,
      publishedAtTimestamp,
      timeOptions,
      files,
      memoizedFilterFunction,
      scheduleBreakpoints,
      timelineStartTimestampOverride
    );

    const previousItem = result[0];

    if (
      previousItem &&
      previousItem.type === timelineItem.type &&
      (timelineItem.type === "void" ||
        (previousItem as TimelineItemContent).id ===
          (timelineItem as TimelineItemContent).id)
    ) {
      // it is possible that the item doesn't change on a schedule breakpoint. we just extend it here instead of
      // pushing a separate item of the same type and id as previous
      result[0] = timelineItem;
    } else {
      result.unshift(timelineItem);
    }
    targetTimestamp = timelineItem.startTimestamp - 1;

    if (previousItem?.startTimestamp === timelineItem.startTimestamp) {
      // this should never happen in real life. If it happens, that will mean infinite loop, so we break right away
      //  to avoid halting the device. This should be reported to developers and investigated

      // TODO remove bug tracker

      captureMessage(
        "Unexpected startTimestamp of timeline item while executing generateBehind() function. Previous item's startTimestamp equals new item's startTimestamp."
      );
      log.warn(
        "Unexpected startTimestamp of timeline item while executing generateBehine() function. Previous item's startTimestamp equals new item's startTimestamp.",
        {
          prevItemstartTimestamp: previousItem.startTimestamp,
          newstartTimestamp: timelineItem.startTimestamp,
        }
      );
      break;
    }

    if (i >= iterationsLimit) {
      // captureMessage(
      //   "Hit iterations limit while executing generateBehind() function"
      // );
      log.warn(
        "Hit iterations limit while executing generateBehind() function",
        {
          iterationsLimit: iterationsLimit,
        }
      );
    }
  }

  return result;
}

/**
 * Produces a timeline item for a given content list and target timestamp + next item start timestamp.
 * This method contains all the deterministic logic described here:
 * https://www.notion.so/screencloud/Playback-Logic-352201dfc69346bda62c35e7008b5161
 */
function produceTimelineItemReport(
  contentList: ContentList,
  targetTimestamp: number,
  publishedAtTimestamp: number,
  timeOptions: TimeOptions,
  files: FilesState,
  memoizedFilterFunction: FilterFunction,
  scheduleBreakpoints: number[],
  timelineStartTimestampOverride?: number
): {
  timelineItem: TimelineItem;
  nextItemStartTimestamp: number;
} {
  const scheduleFilterTargetRangeStartIndex = scheduleBreakpoints.findIndex(
    (breakpoint, idx) => {
      const nextBreakpoint = scheduleBreakpoints[idx + 1];
      return (
        breakpoint <= targetTimestamp &&
        ((nextBreakpoint && nextBreakpoint > targetTimestamp) ||
          !nextBreakpoint)
      );
    }
  );

  const scheduleFilterTargetRangeStart: number =
    scheduleFilterTargetRangeStartIndex > -1
      ? scheduleBreakpoints[scheduleFilterTargetRangeStartIndex]
      : -Infinity;
  const scheduleFilterTargetRangeEnd: number =
    scheduleFilterTargetRangeStartIndex + 1 < scheduleBreakpoints.length
      ? scheduleBreakpoints[scheduleFilterTargetRangeStartIndex + 1]
      : Infinity;

  const serverDefinedTimelineStart =
    timelineStartTimestampOverride || -Infinity;
  const lastScheduleFilterRulesBreakpoint = scheduleFilterTargetRangeStart;

  const timelineStart = Math.max(
    serverDefinedTimelineStart,
    lastScheduleFilterRulesBreakpoint,
    publishedAtTimestamp
  );

  const filterReport = memoizedFilterFunction(targetTimestamp);

  let timelinePointer: number = timelineStart;

  if (filterReport.validItemsCount === 1) {
    const nextItemIndex = findNextIndex(undefined, filterReport.result);

    if (nextItemIndex === undefined) {
      throw new Error("Deterministic logic error.");
    }

    const nextItem = contentList.items[nextItemIndex];

    const resultTimelineItem: TimelineItemContent = {
      startTimestamp:
        scheduleFilterTargetRangeStart > 0 ? scheduleFilterTargetRangeStart : 0,
      id: nextItem.id,
      type: nextItem.type,
      sizeType: nextItem.sizeType,
      preloadDurationMs: nextItem.preloadDurationMs,
      isInfinite: scheduleFilterTargetRangeEnd === Infinity,
      fullDurationMs: nextItem.durationMs,
      listId: nextItem.listId,
      transition: nextItem.transition,
    };

    return {
      timelineItem: resultTimelineItem,
      nextItemStartTimestamp: scheduleFilterTargetRangeEnd,
    };
  } else if ((filterReport.validItemsCount as number) > 1) {
    const listFullLoopsNumber = Math.floor(
      (targetTimestamp - timelinePointer) /
        (filterReport.filteredListFullDurationMs as number)
    );

    timelinePointer +=
      listFullLoopsNumber * (filterReport.filteredListFullDurationMs as number);

    let nextTargetTimestamp: number = timelinePointer;
    let nextItemIndex = -1;
    let nextItem: ContentItemLeaf | undefined;

    while (
      nextTargetTimestamp <= targetTimestamp &&
      targetTimestamp !== Infinity
    ) {
      // is always defined, cause validItemsCount > 1 at this point
      nextItemIndex = findNextIndex(
        nextItemIndex,
        filterReport.result
      ) as number;

      nextItem = contentList.items[nextItemIndex];

      nextTargetTimestamp += nextItem.durationMs;
    }

    if (nextItem === undefined) {
      throw new Error("timeline generator logic error");
    }

    const nextItemStartTimestamp = nextTargetTimestamp - nextItem.durationMs;

    const contentItem = contentList.items[nextItemIndex];

    const resultTimelineItem: TimelineItemContent = {
      startTimestamp: nextItemStartTimestamp,
      id: contentItem.id,
      type: contentItem.type,
      sizeType: contentItem.sizeType,
      preloadDurationMs: contentItem.preloadDurationMs,
      isInfinite: nextTargetTimestamp === Infinity,
      fullDurationMs: contentItem.durationMs,
      listId: contentItem.listId,
      transition: contentItem.transition,
    };

    return {
      timelineItem: resultTimelineItem,
      nextItemStartTimestamp:
        nextTargetTimestamp > scheduleFilterTargetRangeEnd
          ? scheduleFilterTargetRangeEnd
          : nextTargetTimestamp,
    };
  } else {
    // screen is empty
    return {
      timelineItem: {
        startTimestamp: scheduleFilterTargetRangeStart,
        isInfinite: scheduleFilterTargetRangeEnd === Infinity,
        fullDurationMs:
          scheduleFilterTargetRangeEnd - scheduleFilterTargetRangeStart,
        type: "void",
        transition: undefined,
      },
      nextItemStartTimestamp: scheduleFilterTargetRangeEnd,
    };
  }
}

/**
 * Finds the next item to show after current active item, taking schedule filter result into account
 */
function findNextIndex(
  currentIndex: number | undefined,
  filterList: boolean[]
): number | undefined {
  if (filterList.length === 0) {
    return undefined;
  }

  const startingIndex =
    currentIndex !== undefined && currentIndex < filterList.length
      ? currentIndex
      : -1;

  let result: number | undefined = undefined;
  const maxIndex = filterList.length - 1;

  let j = 0;
  const iterationLimit = filterList.length; // additional protection from infinite loop

  for (
    let i = startingIndex + 1;
    i !== startingIndex && j <= iterationLimit;
    i = i + 1 > maxIndex ? -1 : i + 1
  ) {
    j++;
    if (filterList[i]) {
      result = i;
      break;
    }
  }

  return result;
}

export type ScheduleBreakpointsGetter = (dateIsoShort: string) => number[];

export type FilterFunction = (targetTimestamp: number) => ScheduleFilterReport;

export type OptimizedGeneratorFunction = (
  generatorOptions: TimelineFragmentGeneratorOptions
) => TimelineItem[];
