import { REQUEST_FILE_SUCCESS, RequestFileSuccessAction } from "./types";
import { FileFragment } from "../../queries";

export const requestFileSuccess = (
  file: FileFragment
): RequestFileSuccessAction => {
  return {
    type: REQUEST_FILE_SUCCESS,
    payload: {
      files: { [file.id]: file },
    },
  };
};
