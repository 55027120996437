import {
  ADD_LIVE_UPDATE_NOTIFICATION,
  CLEANUP_NOTIFICATIONS,
  LIVE_UPDATE_LOADED,
  LiveUpdateItem,
  LiveUpdatesState,
} from "./types";
import { PlayerAction } from "../storeTypes";
import isEqual from "lodash/isEqual";

export const initialState: LiveUpdatesState = {
  byId: {},
};

export const liveUpdatesReducer = (
  state: LiveUpdatesState = initialState,
  action: PlayerAction
): LiveUpdatesState => {
  switch (action.type) {
    case ADD_LIVE_UPDATE_NOTIFICATION:
      return updateItem(state, action.payload.id, {
        timestamp: action.payload.timestamp,
        isLoaded: false,
      });

    case LIVE_UPDATE_LOADED:
      return updateItem(state, action.payload.id, {
        isLoaded: true,
      });

    case CLEANUP_NOTIFICATIONS:
      const updatedCollection: { [id: string]: LiveUpdateItem } = {
        ...state.byId,
      };
      action.payload.ids.forEach((id) => {
        delete updatedCollection[id];
      });
      if (!isEqual(updatedCollection, state.byId)) {
        return {
          ...state,
          byId: updatedCollection,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

function updateItem(
  state: LiveUpdatesState,
  compositeId: string,
  update: Partial<LiveUpdateItem>
): LiveUpdatesState {
  return {
    ...state,
    byId: {
      ...state.byId,
      [compositeId]: {
        ...state.byId[compositeId],
        id: compositeId,
        ...update,
      },
    },
  };
}
