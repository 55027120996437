import { ConnectedMessage, GenericMessage } from "../AppViewer/types";

export const makeConnectedMessage = (): ConnectedMessage => {
  return { type: "CONNECT_SUCCESS" };
};

// TODO - postMessage natively serializes elements. Why do we JSON.stringify everything?
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
export const sendMessage = (
  frame: HTMLIFrameElement,
  message: GenericMessage,
  targetOrigin: string,
  requestId?: number
): void => {
  const { type } = message;

  // Only CONNECT, CONNECT_SUCCESS, DISCONNECT messages add this ___ thing.
  // The rest nest their data under a 2nd "data" key.
  // `requestAuthToken` uses a specific format with a referenceId and requestId
  let processedMessage;

  if (["CONNECT", "CONNECT_SUCCESS", "DISCONNECT"].includes(type)) {
    processedMessage = `___${JSON.stringify(message)}`;
  } else if (type === "requestAuthToken") {
    processedMessage = JSON.stringify({
      data: message.payload,
      referenceId: requestId,
      requestId,
    });
  } else if (type === "requestConfigUpdate") {
    processedMessage = JSON.stringify({
      data: message,
      requestId: 1,
    });
  } else if (type === "requestFiles") {
    processedMessage = JSON.stringify({
      data: {
        ...message.payload,
        type: message.type,
      },
      referenceId: requestId,
    });
  } else {
    processedMessage = JSON.stringify({
      data: message,
    });
  }

  frame.contentWindow?.postMessage(processedMessage, targetOrigin);
};
