import React, { FunctionComponent, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GREENSCREENS, HOUR_DURATION_MS } from "../../../../constants";
import { Logger } from "../../../../logger/logger";
import { OrganizationState } from "../../../../store/organization/types";
import { PlayerState } from "../../../../store/rootReducer";
import { useInterval } from "../../../../utils/useInterval";

const log = new Logger("linkViewer");

export interface LinkViewerStandardProps {
  url: string;
  name: string;
}

export const LinkViewerStandard: FunctionComponent<LinkViewerStandardProps> = memo(
  (props) => {
    const { url, name } = props;

    const organization = useSelector<PlayerState, OrganizationState>(
      (state) => state.organization || {}
    );

    function getFinalURL() {
      return GREENSCREENS === organization.id
        ? url.indexOf("?") > -1
          ? url + "&nocache=" + new Date().getTime()
          : url + "?nocache=" + new Date().getTime()
        : url;
    }

    const [finalURL, setFinalURL] = useState(getFinalURL());

    // hack for Greenscreen will turn into feature later
    useInterval(() => {
      setFinalURL(getFinalURL());
    }, HOUR_DURATION_MS);

    useEffect(() => {
      log.info(`Show Link ${url}`, {
        name,
        contentType: "Link",
        linkType: "STANDARD",
      });
    }, [url, name]);

    return (
      <iframe
        data-testid="link-viewer"
        src={finalURL}
        title={name}
        style={{ border: "none", height: "100%", width: "100%" }}
      />
    );
  }
);
LinkViewerStandard.displayName = "LinkViewerStandard";
