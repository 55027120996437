import { ContentSizeType, LeafContentType } from "../../types/content";
import { GqlZoneItemTransition } from "../graphqlTypes";

export const UPDATE_TIMELINE = "UPDATE_TIMELINE";
export const REPLACE_TIMELINE = "REPLACE_TIMELINE";

export type TimelineAction = UpdateTimelineAction | ReplaceTimelineAction;

export type UpdateTimelineAction = {
  type: typeof UPDATE_TIMELINE;
  payload: {
    items: TimelineItem[];
    id: string;
    cleanupAmount?: number;
    targetTimestamp: number; // the moment when the update happened
    nextChunkRequestTimestamp: number;
  };
};

export type ReplaceTimelineAction = {
  type: typeof REPLACE_TIMELINE;
  payload: {
    items: TimelineItem[];
    id: string;
    targetTimestamp: number; // the moment when the update happened
    nextChunkRequestTimestamp: number;
  };
};

export interface TimelinesState {
  byId: {
    [id: string]: Timeline;
  };
  type: TimelineType;
}

export interface Timeline {
  id: string;
  items: TimelineItem[];
}

export type TimelineItem = TimelineItemVoid | TimelineItemContent;

interface TimelineItemBase {
  startTimestamp: number;
  isInfinite: boolean;
  fullDurationMs: number; // full duration declared for an item in content json props or media metadata
  transition?: GqlZoneItemTransition;
}

export interface TimelineItemContentBase extends TimelineItemBase {
  id: string;
  type: LeafContentType;
  listId: string; // required for matching timeline item with original content json item
  preloadDurationMs?: number; // undefined => Don't preload.
}

/**
 * Represents a period of emptiness on the screen
 */
export interface TimelineItemVoid extends TimelineItemBase {
  type: "void";
}

export type TimelineItemContent =
  | TimelineItemFile
  | TimelineItemApp
  | TimelineItemLink
  | TimelineItemSite;

export type TimelineType = "local" | "test";

export interface TimelineItemFile extends TimelineItemContentBase {
  type: "file";
  sizeType: ContentSizeType;
}

export interface TimelineItemApp extends TimelineItemContentBase {
  type: "app";
}

export interface TimelineItemLink extends TimelineItemContentBase {
  type: "link";
}

export interface TimelineItemSite extends TimelineItemContentBase {
  type: "site";
}
