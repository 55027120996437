import LokiTransport from "sc-winston-loki-beta";
import { createLogger } from "winston";
import { Maybe } from "../queries";
import { TimeOptions } from "../utils/timeManager";
import { LogLevel, LogOutput } from "./logger";
import { Context } from "@datadog/browser-core";
import { captureException } from "@sentry/minimal";

const LokiLogger = createLogger();

interface Event {
  level: LogLevel;
  message: string;
  sendToLoki?: boolean;
  context?: Context;
}

type EventQueues = Event[];

export interface GetLogger {
  debug: (message: string, context?: Context) => void;
  info: (message: string, context?: Context) => void;
  warn: (message: string, context?: Context) => void;
  error: (message: string, context?: Context) => void;
}

export interface RemoteLoggerConfig {
  clientToken: string;
  version: string;
  env: string;
  proxyHost?: string;
  platform: string;
  deviceId: string;
  screenId: string;
  orgId: string;
  timeOptions: TimeOptions;
  graphqlToken: string;
  lokiEndpoint: string;
}

class Loki {
  public isInitialized = false;
  private isEnabled = false;

  // using for store the events that are called before initialized
  private eventQueues: EventQueues = [];

  private shouldEnableLokiLogger = (featureFlags: Maybe<string>[]): boolean => {
    return featureFlags.includes("playback_logs");
  };

  public init = (
    config: RemoteLoggerConfig,
    featureFlags: Maybe<string>[]
  ): void => {
    // Loki initialization

    this.isEnabled = this.shouldEnableLokiLogger(featureFlags);

    if (this.isInitialized) return;
    try {
      LokiLogger.add(
        new LokiTransport({
          host: config.lokiEndpoint,
          json: true,
          labels: {
            job: "studio-player",
            env: config.env,
            version: config.version,
          },
          headers: {
            "X-Scope-OrgID": config.orgId ? config.orgId : "default",
            Authorization: `bearer ${config.graphqlToken}`,
          },
        })
      );
    } catch (error) {
      captureException(error);
      console.warn("Loki Initialization Failed");
      return;
    }

    LokiLogger.defaultMeta = {
      platform: config.platform,
      deviceId: config.deviceId,
      screenId: config.screenId,
      orgId: config.orgId,
    };

    this.isInitialized = true;

    this.flushEventQueues();
  };

  private log = (
    level: LogLevel,
    message: string,
    context?: Context,
    sendToLoki?: boolean
  ): void => {
    /**
     * handle when events are sent before initialized
     * need to add an event to the event queues to make it be able to sync later
     */
    if (!this.isInitialized) {
      this.addEventToQueues({
        level,
        message,
        context,
      });
      return;
    }

    this.eventLogger(level, message, context, sendToLoki);
  };

  private addEventToQueues = ({ level, message, context }: Event): void => {
    const eventQueuesLimit = parseInt(
      process.env.REACT_APP_EVENT_QUEUES_LIMIT || "20"
    );

    // need to have a limitation of event stack because it could effect a memory leak
    if (this.eventQueues.length > eventQueuesLimit) {
      this.eventQueues.shift();
    }

    this.eventQueues.push({
      level,
      message,
      context,
    });
  };

  private flushEventQueues = (): void => {
    /**
     * Even if loki events are added in initial state of logger if isLokiLoggingInitialized is not true
     * loki events are simply ignored in eventLogger method. The reason why is when we are initializing EventQueue
     * we do not have access to FeatureFlags yet, so we have to add all events and handle this edge case
     * in eventLogger because by then we would know the status of "isLokiLoggingInitialized" flag
     * */
    this.eventQueues
      .filter((e) => e.sendToLoki)
      .forEach((event) => {
        this.eventLogger(
          event.level,
          event.message,
          event.context,
          event?.sendToLoki
        );
      });
    this.clearEventQueues();
  };

  private clearEventQueues = (): void => {
    this.eventQueues = [];
  };

  private eventLogger = (
    level: LogLevel,
    message: string,
    context?: Context,
    sendToLoki?: boolean
  ): void => {
    if (!this.isInitialized || !this.isEnabled || !sendToLoki) {
      return;
    }
    switch (level) {
      case 4:
        this.isEnabled && LokiLogger.debug({ message, ...context });
        break;
      case 3:
        this.isEnabled && LokiLogger.info({ message, ...context });
        break;
      case 2:
        this.isEnabled && LokiLogger.warn({ message, ...context });
        break;
      case 1:
        // case error always send data to loki for debugging
        LokiLogger.error({ message, ...context });
        break;
    }
  };

  public getEventQueues = (): EventQueues => {
    return this.eventQueues;
  };

  public getLogger = (): LogOutput => {
    return this.log;
  };
}

export default new Loki();
