import { getLogger } from "../utils/debugLog";
import {
  convertDateToLocalTimezone,
  getDateIsoShort,
  getEndOfDay,
  getStartOfDay,
  getTimestampByTime,
  parseDateInLocalTime,
  targetSpacetime,
  TimeOptions,
  playbackNowTimestamp,
} from "./timeManager";
import sortedUniq from "lodash/sortedUniq";
import { FilteringMemo, FilterMemoItem } from "../store/playback/types";
import { ContentItemLeaf } from "../store/contentLists/types";
import { DAY_DURATION_MS } from "../constants";
import { WEEK_DAYS, WeekDay } from "./scheduleFilterConstants";
import moize from "moize";
const log = getLogger("schedule-filter:error");

export type DATETIME = string;

export interface WithAvailabilityRules {
  rules: ScheduleRules[] | undefined;
  availableAt: DATETIME | null;
  expireAt: DATETIME | null;
}

export interface ScheduleRules {
  time?: Array<{ start: string; end: string }>;
  day_of_week?: { [key in WeekDay]: boolean };
  date?: Array<{
    start: string | undefined;
    end: string | undefined;
  }>;
  exclusive?: boolean;
  specific_date?: boolean;
}

export type ScheduleFilterResult = boolean[];

export interface ScheduleFilterReport {
  result: ScheduleFilterResult;
  validItemsCount?: number;
  filteredListFullDurationMs?: number;
}

export function applyScheduleRulesToList(
  list: WithAvailabilityRules[],
  timeOptions: TimeOptions,
  targetTimestamp?: number
): ScheduleFilterResult {
  const targetTime = targetTimestamp || playbackNowTimestamp(timeOptions);
  const doesDateSatisfyRulesMemoized = moize(doesTargetTimeSatisfyRules, {
    maxSize: list.length,
  });
  const isActiveMemoized = moize(isActive, { maxSize: list.length });

  const scheduleFilterResult = list.map(
    (item) =>
      isActiveMemoized(
        timeOptions,
        item.availableAt || undefined,
        item.expireAt || undefined,
        targetTime
      ) && doesDateSatisfyRulesMemoized(timeOptions, item.rules, targetTime)
  );

  const isExclusiveItemPresent = !!list.find(
    (item, idx) =>
      !!item.rules &&
      Array.isArray(item.rules) &&
      !!item.rules.find((rule) => !!rule.exclusive) &&
      scheduleFilterResult[idx]
  );

  if (isExclusiveItemPresent) {
    const exclusiveFilterResult = list.map(
      (item, idx) =>
        scheduleFilterResult[idx] &&
        !!item.rules?.find((rule) => rule.exclusive)
    );
    return exclusiveFilterResult;
  } else {
    return scheduleFilterResult;
  }
}

function doesTargetTimeSatisfyRules(
  timeOptions: TimeOptions,
  rules: ScheduleRules[] | undefined,
  targetTime: number
): boolean {
  if (!rules || !Array.isArray(rules) || rules.length === 0) {
    return true;
  }

  return rules.some((rule) =>
    doesTargetTimeSatisfyRule(timeOptions, targetTime, rule)
  );
}

export function doesTargetTimeSatisfyRule(
  timeOptions: TimeOptions,
  targetTime: number,
  rule: ScheduleRules
): boolean {
  const targetTimeParsed = parseDateInLocalTime(timeOptions, targetTime);

  const dateFits =
    rule.date && rule.date.length > 0
      ? !!rule.date.find((dateRange) =>
          doesTimeFitDateRange(
            timeOptions,
            targetTimeParsed.timestamp,
            dateRange.start,
            dateRange.end
          )
        )
      : true;

  // return early to avoid unnecessary date calculations
  if (!dateFits) {
    return false;
  }

  let dayOfWeekFits =
    rule.day_of_week !== undefined && !rule.specific_date
      ? rule.day_of_week[targetTimeParsed.weekDay]
      : true; // eslint-disable-next-line
  if (typeof dayOfWeekFits !== "boolean") {
    // todo: notify developers about a wrong value in json
    dayOfWeekFits = true;
  }

  if (!dayOfWeekFits) {
    return false;
  }

  const timeFits =
    rule.time && rule.time.length > 0
      ? rule.time.some((time) =>
          doesTimeFitTimeRange(
            timeOptions,
            targetTimeParsed,
            time.start,
            time.end
          )
        )
      : true;

  if (!timeFits) {
    return false;
  }

  return true;
}

export function doesTimeFitDateRange(
  timeOptions: TimeOptions,
  targetTimestamp: number,
  startDate: string | undefined,
  endDate: string | undefined
): boolean {
  try {
    const start =
      typeof startDate === "string" && startDate.length > 0
        ? getStartOfDay(timeOptions, startDate)
        : -Infinity;
    const end =
      typeof endDate === "string" && endDate.length > 0
        ? getEndOfDay(timeOptions, endDate)
        : Infinity;
    return start <= targetTimestamp && targetTimestamp <= end;
  } catch (e) {
    // todo: notify developers about a invalid value in rule.date
    return false;
  }
}

export function doesTimeFitTimeRange(
  timeOptions: TimeOptions,
  targetTimeParsed: { millisecondFromDayStart: number },
  startTime: string,
  endTime: string
): boolean {
  try {
    const startMs = getMillisecondSinceDayStart(startTime);
    const endMs = getMillisecondSinceDayStart(endTime);

    if (startMs === null || endMs === null) {
      return false;
    }

    return (
      startMs <= targetTimeParsed.millisecondFromDayStart &&
      targetTimeParsed.millisecondFromDayStart < endMs
    );
  } catch (err) {
    log("Can not analyze time rules", err.message);

    return true;
  }
}

/**
 * Returns amount of milliseconds since day start
 * @param timeInput - hh:mm or hh:mm:ss
 */
function getMillisecondSinceDayStart(timeInput: string): number | null {
  if (
    (timeInput.length !== 5 || timeInput[2] !== ":") &&
    (timeInput.length !== 8 || timeInput[2] !== ":" || timeInput[5] !== ":")
  ) {
    // todo: notify developers about wrong value in json
    return null;
  }
  const splitValues = timeInput.split(":").map(parseTimeDigits);
  return (
    (splitValues[0] * 60 * 60 + splitValues[1] * 60 + (splitValues[2] ?? 0)) *
    1000
  );
}

function isActive(
  timeOptions: TimeOptions,
  availableAt: DATETIME | undefined,
  expireAt: DATETIME | undefined,
  targetTime: number
): boolean {
  const isAvailable = availableAt
    ? convertDateToLocalTimezone(timeOptions, availableAt) <= targetTime
    : true;

  const isNotExpired = expireAt
    ? convertDateToLocalTimezone(timeOptions, expireAt) >= targetTime
    : true;

  return isAvailable && isNotExpired;
}

export function parseTimeDigits(digits: string): number {
  if (digits.length !== 2) {
    throw new Error("Time digits must contain 2 digits.");
  }
  if (digits === "00") {
    return 0;
  } else {
    const result = parseInt(digits, 10);
    if (isNaN(result) || result < 0 || result > 60) {
      throw new Error(`Can not parse input as time digits. Input: ${digits}`);
    }
    return result;
  }
}

/**
 * Returns a filter function for a given list and time options that is capable of returning a filter result for any
 * target timestamp and utilises a memoization mechanism based on schedule rules' periods' breakpoints.
 */
export function getMemoizedFilterFunction(
  list: ContentItemLeaf[],
  timeOptions: TimeOptions,
  memoizedScheduleBreakpointsGetter: (targetDateIsoShort: string) => number[],
  shouldIncludeAdditionalReport = false
): (targetTimestamp: number) => ScheduleFilterReport {
  // Memory consumption note:
  //  In this array we memoize array of numbers and booleans. It means we need to store millions of items to see any
  //  noticeable impact on memory consumption. Due to the nature of the logic and data inputs (we're storing the
  //  filtering results of different time periods within a max of 3 day time period), the number of items in this
  //  array will hardly every exceed 10000 items
  let resultMemo: FilteringMemo = [];

  function getMemoizedItem(
    targetTimestamp: number
  ): FilterMemoItem | undefined {
    return resultMemo.find(
      (item) =>
        item.periodStart <= targetTimestamp && item.periodEnd >= targetTimestamp
    );
  }

  function writeMemoResult(
    targetTimestamp: number,
    filterResult: ScheduleFilterResult,
    filteredListFullDuration: number | undefined,
    validItemsCount: number | undefined
  ): void {
    let targetMemoItem = getMemoizedItem(targetTimestamp);

    if (!targetMemoItem) {
      resultMemo = produceFilteringMemoArray(
        list,
        targetTimestamp,
        timeOptions,
        memoizedScheduleBreakpointsGetter
      );
      targetMemoItem = getMemoizedItem(targetTimestamp) as FilterMemoItem;
    }

    if (!targetMemoItem) {
      throw new Error("Filtering memo array generated incorrectly.");
    }

    targetMemoItem.result = filterResult;
    targetMemoItem.validItemsCount = validItemsCount;
    targetMemoItem.filteredListFullDurationMs = filteredListFullDuration;
  }

  return (targetTimestamp: number): ScheduleFilterReport => {
    const memoizedItem = getMemoizedItem(targetTimestamp);

    if (memoizedItem?.result) {
      return {
        result: memoizedItem.result,
        validItemsCount: memoizedItem.validItemsCount,
        filteredListFullDurationMs: memoizedItem.filteredListFullDurationMs,
      };
    } else {
      const result = applyScheduleRulesToList(
        list,
        timeOptions,
        targetTimestamp
      );

      const validItemsCount = shouldIncludeAdditionalReport
        ? result.reduce<number>((sum, item) => (item ? sum + 1 : sum), 0)
        : undefined;

      const filteredListFullDurationMs = shouldIncludeAdditionalReport
        ? getFilteredListDuration(list, result)
        : undefined;

      writeMemoResult(
        targetTimestamp,
        result,
        filteredListFullDurationMs,
        validItemsCount
      );

      return { result, validItemsCount, filteredListFullDurationMs };
    }
  };
}

/**
 * Returns a sorted unique list of schedule breakpoints within a target day
 * Schedule breakpoints are points in time, when a given items' schedule filter result changes
 * If neither of items have rules to be applied in future, an empty array is returned
 * Note: breakpoints for recurring rules are calculated in the target period only: target week for weekdays, target
 * day for time ranges
 */
export function getScheduleBreakpointsForItemList(
  items: WithAvailabilityRules[],
  targetDateIsoShort: string,
  timeOptions: TimeOptions
): number[] {
  const st = targetSpacetime(timeOptions, targetDateIsoShort);
  const targetDayStart = getStartOfDay(timeOptions, targetDateIsoShort);
  const targetDayEnd = targetDayStart + DAY_DURATION_MS;

  const result: Set<number> = new Set<number>();
  const timeRuleBreakpoints: Set<number> = new Set<number>();
  const weekRuleBreakPoints: Set<number> = new Set<number>();

  // this is for manual memoization purpose to avoid any unnecessary calculations
  const memoObject = {
    time: new Set<string>(),
    date: new Set<DATETIME>(),
    weekDay: new Set<WeekDay>(),
    rulesObject: new Set<ScheduleRules[]>(),
  };

  items.forEach((item) => {
    let availableTimestamp = -Infinity;
    let expireTimeistamp = Infinity;

    const addValue = (value: number) => {
      if (value >= availableTimestamp && value <= expireTimeistamp) {
        result.add(value);
      }
    };

    if (item.availableAt && !memoObject.date.has(item.availableAt)) {
      availableTimestamp = convertDateToLocalTimezone(
        timeOptions,
        item.availableAt
      );
      addValue(availableTimestamp);
      memoObject.date.add(item.availableAt);
    }

    if (item.expireAt && !memoObject.date.has(item.expireAt)) {
      expireTimeistamp = convertDateToLocalTimezone(timeOptions, item.expireAt);
      addValue(expireTimeistamp);
      memoObject.date.add(item.expireAt);
    }

    if (item.rules && !memoObject.rulesObject.has(item.rules)) {
      item.rules.forEach((rule) => {
        if (rule.date) {
          rule.date.forEach((dateRule) => {
            if (dateRule.start && !memoObject.date.has(dateRule.start)) {
              const startTimestamp = getStartOfDay(timeOptions, dateRule.start);

              addValue(startTimestamp);
              memoObject.date.add(dateRule.start);
            }

            if (dateRule.end && !memoObject.date.has(dateRule.end)) {
              const endTimestamp = getEndOfDay(timeOptions, dateRule.end);

              addValue(endTimestamp);
              memoObject.date.add(dateRule.end);
            }
          });
        }

        // todo: if item is not valid whole target week - ignore day of week parsing completely
        if (rule.day_of_week && !rule.specific_date) {
          const breakpointDays: number[] = [];

          WEEK_DAYS.forEach((day, index) => {
            const nextDayIndex = index + 1 < WEEK_DAYS.length ? index + 1 : 0;

            if (
              rule.day_of_week &&
              rule.day_of_week[day] !==
                rule.day_of_week[WEEK_DAYS[nextDayIndex]]
            ) {
              breakpointDays.push(index);
            }
          });

          breakpointDays.forEach((dayNumber) => {
            // adding one millisecond here for breakpoint to match start of next day (useful for following dedupe
            //  operation and avoiding 1 millisecond time ranges)
            if (!memoObject.weekDay.has(WEEK_DAYS[dayNumber])) {
              const value = st.day(dayNumber).endOf("day").epoch + 1;
              weekRuleBreakPoints.add(value);
              result.add(value);
              memoObject.weekDay.add(WEEK_DAYS[dayNumber]);
            }
          });
        }

        if (rule.time) {
          const doesDayFitDateRules =
            rule.date && rule.date.length > 0
              ? !!rule.date
                  .map((rule) =>
                    doesTimeFitDateRange(
                      timeOptions,
                      targetDayStart,
                      rule.start,
                      rule.end
                    )
                  )
                  .find((result) => result)
              : true;
          const doesDayFitAvailabilityRules =
            (availableTimestamp ? availableTimestamp < targetDayEnd : true) &&
            (expireTimeistamp ? expireTimeistamp > targetDayStart : true);

          // parse time rules only if item is active in the target day, skip otherwise
          if (doesDayFitDateRules && doesDayFitAvailabilityRules) {
            rule.time.forEach((timeRule) => {
              if (!memoObject.time.has(timeRule.start)) {
                const startTimestamp = getTimestampByTime(
                  timeOptions,
                  timeRule.start,
                  targetDateIsoShort
                );

                if (
                  startTimestamp > availableTimestamp &&
                  startTimestamp < expireTimeistamp
                ) {
                  result.add(startTimestamp);
                  timeRuleBreakpoints.add(startTimestamp);
                  memoObject.time.add(timeRule.start);
                }
              }

              if (!memoObject.time.has(timeRule.end)) {
                const endTimestamp = getTimestampByTime(
                  timeOptions,
                  timeRule.end,
                  targetDateIsoShort
                );

                if (
                  endTimestamp > availableTimestamp &&
                  endTimestamp < expireTimeistamp
                ) {
                  result.add(endTimestamp);
                  timeRuleBreakpoints.add(endTimestamp);
                  memoObject.time.add(timeRule.end);
                }
              }
            });
          }
        }
      });

      memoObject.rulesObject.add(item.rules);
    }
  });

  if (timeRuleBreakpoints.size > 0) {
    const firstBreakpointOfNextDay =
      Math.min(...timeRuleBreakpoints) + DAY_DURATION_MS;
    const lastBreakpointOfPreviousDay =
      Math.max(...timeRuleBreakpoints) - DAY_DURATION_MS;

    // this is to be sure that we include the closest time rule breakpoints of previous and next day (relative
    //  to given targetTimestamp, because all the other breakpoints that we include are from the same day
    //  with targetTimestamp, so if targetTimestamp is between beginning of the day and earliest breakpoint
    //  or last breakpoint and end of the day, then you won't be able to see closest
    result.add(firstBreakpointOfNextDay);
    result.add(lastBreakpointOfPreviousDay);
  }

  if (weekRuleBreakPoints.size > 0) {
    const firstBreakpointOfNextWeek =
      Math.min(...weekRuleBreakPoints) + 7 * DAY_DURATION_MS;
    const lastBreakpointOfPreviousWeek =
      Math.max(...weekRuleBreakPoints) - 7 * DAY_DURATION_MS;

    // this is needed because week rules are recurring
    result.add(firstBreakpointOfNextWeek);
    result.add(lastBreakpointOfPreviousWeek);
  }

  return sortedUniq([...result].sort((a, b) => a - b));
}

/**
 * Produces the filtering results memoization array, it contains the schedule rule timeframes within the 3 day period:
 * previous day - target day - next day. This is done to make sure Filtering periods
 */
export function produceFilteringMemoArray(
  items: WithAvailabilityRules[],
  targetTimestamp: number,
  timeOptions: TimeOptions,
  memoizedScheduleBreakpointsGetter: (targetDateIsoShort: string) => number[]
): FilteringMemo {
  const targetST = targetSpacetime(timeOptions, targetTimestamp);
  const startOfPreviousDay = targetST.startOf("day").epoch - DAY_DURATION_MS;
  const endOfNextDay = startOfPreviousDay + DAY_DURATION_MS * 3;

  const sortedBreakpoints = sortedUniq(
    memoizedScheduleBreakpointsGetter(
      getDateIsoShort(timeOptions, targetTimestamp - DAY_DURATION_MS)
    )
      .concat(
        memoizedScheduleBreakpointsGetter(
          getDateIsoShort(timeOptions, targetTimestamp)
        )
      )
      .concat(
        memoizedScheduleBreakpointsGetter(
          getDateIsoShort(timeOptions, targetTimestamp + DAY_DURATION_MS)
        )
      )
      .filter(
        (breakpoint) =>
          breakpoint > startOfPreviousDay && breakpoint < endOfNextDay
      )
      .sort((a, b) => a - b)
  );

  sortedBreakpoints.push(endOfNextDay);
  sortedBreakpoints.unshift(startOfPreviousDay);

  const result: FilteringMemo = [];

  sortedBreakpoints.forEach((item, idx) => {
    if (idx + 1 === sortedBreakpoints.length) {
      return;
    }
    result.push({
      // 1 millisecond is subtracted for a precise no overlap time frame borders in ms
      periodEnd: sortedBreakpoints[idx + 1] - 1,
      periodStart: item,
      result: undefined,
    });
  });

  return result;
}

/**
 * Returns full duration of a flattened list according to filter results.
 */
export function getFilteredListDuration(
  list: ContentItemLeaf[],
  filterList: ScheduleFilterResult
): number {
  return filterList.reduce<number>((sum, value, idx) => {
    if (!value) {
      return sum;
    }

    const contentItem = list[idx];

    return sum + contentItem.durationMs;
  }, 0);
}
