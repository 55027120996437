import { createContext } from "react";

export interface ScreenContextInterface {
  timezoneOverride: string | undefined;
}

export const defaultScreenContext: ScreenContextInterface = {
  timezoneOverride: undefined,
};

export const ScreenContext = createContext<ScreenContextInterface>(
  defaultScreenContext
);
