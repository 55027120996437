import { APIError } from "graphql-hooks";

export function grqphqlHooksErrorMessage(error: APIError): string | undefined {
  if (error.fetchError) {
    return `fetch error: ${error.fetchError.message}`;
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors.reduce(
      (sum, gqlError) => sum + `Graphql error: ${gqlError}. \n`,
      ""
    );
  }
  if (error.httpError) {
    return `http error: ${error.httpError.statusText}. Status: ${error.httpError.status}.`;
  }

  return undefined;
}
