import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";
import { GenericViewer } from "../GenericViewer/GenericViewer";

export const MenuViewerContainer: FunctionComponent = () => {
  const { items, currentIndex, liveMode } = useEmbedMenu();

  const item = items[currentIndex];

  if (liveMode) {
    return null;
  }

  return <GenericViewer contentItem={item} />;
};
