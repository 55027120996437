import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { GenericViewer } from "../../../viewers/containers/GenericViewer/GenericViewer";
import { ContentViewItem } from "../../../../types/content";
import { ContentConfig } from "../../../../store/config/types";
import { setTag } from "../../../../utils/bugTracker";
import { OrganizationState } from "../../../../store/organization/types";
import { TimeOptions } from "../../../../utils/timeManager";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { setDailyReload } from "../../../../utils/dailyReloadManager";
import { getRootContent } from "../../../../utils/rootContent";
import { MenuViewerContainer } from "../../../viewers/containers/MenuViewer/MenuViewer";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";

export const PlayerCoreContainer: FunctionComponent = () => {
  const organization = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const contentConfig = useSelector<PlayerState, ContentConfig>(
    (state) => state.config.contentConfig
  );
  const { liveMode } = useEmbedMenu();
  const embedMenuVisible = !liveMode;

  const contentItem: ContentViewItem = useMemo<ContentViewItem>(() => {
    return getRootContent(contentConfig);
  }, [contentConfig]);

  const timeOptions: TimeOptions = useTimeOptions();

  useEffect(() => {
    setDailyReload(timeOptions);
  }, [timeOptions]);

  useEffect(() => {
    setTag("root_content_type", contentItem.type);
    setTag(
      "root_content_id",
      contentItem.type === "void" ? "undefined" : contentItem.id
    );
  }, [contentItem]);

  useEffect(() => {
    setTag("org_id", organization.id);
  }, [organization.id]);

  if (embedMenuVisible) {
    return <MenuViewerContainer />;
  }
  return (
    <GenericViewer
      isRoot
      contentItem={contentItem} //ScreenViewerContainer key
    />
  );
};
