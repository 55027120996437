import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";

import {
  Timeline,
  TimelineItem,
  TimelineType,
} from "../../../../store/timelines/types";
import { PlayerState } from "../../../../store/rootReducer";
import TimelineOperatorLocal from "./TimelineOperatorLocal";
import { TimelineOperatorPropsBase } from "./types";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import { nextItemAction } from "../../../../store/playback/actions";
import {
  TimeOptions,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import { isTimelineItemVoid } from "../../../../utils/contentItemTypeGuards";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { usePreviousValue } from "../../../../utils/usePreviousValue";
import Slot from "./Slot";
import { TimelinePlaybackReportContainer } from "./TimelinePlaybackReportContainer";
import { ContentListUpdateReportContainer } from "./ContentListUpdateReportContainer";
import { parseLocalTimelineId } from "../../../../store/contentLists/utils";

interface TimelineViewerContainerProps {
  id: string;
}

export const TimelineViewerContainer: FunctionComponent<TimelineViewerContainerProps> = ({
  id,
}: TimelineViewerContainerProps) => {
  const dispatch = useDispatch();
  const timeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => state.timelines.byId[id]
  );
  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => state.playback.timelines[id]);
  const timelineType = useSelector<PlayerState, TimelineType>(
    (state) => state.timelines.type
  );

  const timeOptions = useTimeOptions();

  const onActiveItemEnd = useCallback(() => {
    if (!timeline) {
      return;
    }
    // we add 50 ms to utcNow() value to make sure there is no race condition when determining the active item,
    //  because time comparisons are done with 1ms precision
    dispatch(
      nextItemAction(timeline.id, playbackNowTimestamp(timeOptions) + 50)
    );
  }, [timeline, dispatch, timeOptions]);

  if (!timeline) {
    return null;
  }

  return (
    <>
      <TimelinePlaybackReportContainer timelineId={id} />
      {/*
        ContentListUpdateReportContainer is placed here because we're interested in reporting only content lists
        that are involved into actual playback at the moment.
      */}
      <ContentListUpdateReportContainer
        contentListId={parseLocalTimelineId(id).sourceContentListId}
      />
      <TimelineOperator timelineId={id} type={timelineType} />
      {!playbackState ? null : (
        <TimelineViewer
          timeline={timeline}
          playbackState={playbackState}
          timeOptions={timeOptions}
          onActiveItemEnd={onActiveItemEnd}
          timelineType={timelineType}
        />
      )}
    </>
  );
};

const TimelineOperator: FunctionComponent<
  TimelineOperatorPropsBase & {
    type: TimelineType;
  }
> = (props: TimelineOperatorPropsBase & { type: TimelineType }) => {
  switch (props.type) {
    case "local":
      return <TimelineOperatorLocal {...props} />;
    case "test":
      return null;
    default:
      return <TimelineOperatorLocal {...props} />;
  }
};

interface TimelineViewerProps {
  timeline: Timeline;
  playbackState: TimelinePlaybackState;
  timeOptions: TimeOptions;
  onActiveItemEnd: () => void;
  timelineType: TimelineType;
}

interface RenderSlot {
  item: TimelineItem | undefined;
  isPreload: boolean;
}

/**
 * Checks if an item inside the slot represents the targetTimelineItem
 */
function doesSlotRepresentTimelineItem(
  slotItem: TimelineItem | undefined,
  targetTimelineItem: TimelineItem | undefined
): boolean {
  return (
    slotItem !== undefined &&
    targetTimelineItem !== undefined &&
    slotItem.type === targetTimelineItem.type &&
    ((slotItem.type !== "void" &&
      targetTimelineItem.type !== "void" &&
      // checking list id is not enough, startTimestamp must be compared too, but I leave it like this for now until
      //  we solve https://github.com/screencloud/studio-player/issues/316
      //  https://github.com/screencloud/studio-player/issues/317,
      //  https://github.com/screencloud/studio-player/issues/318, cause otherwise a change of a slot causes
      //  apps restart, which in case of youtube causes video restart, that is required to be avoided
      slotItem.listId === targetTimelineItem.listId) ||
      (slotItem.type === "void" &&
        targetTimelineItem.type === "void" &&
        slotItem.startTimestamp === targetTimelineItem.startTimestamp))
  );
}

export const TimelineViewer: FunctionComponent<TimelineViewerProps> = memo(
  ({
    timeline,
    playbackState,
    timeOptions,
    onActiveItemEnd,
    timelineType,
  }: TimelineViewerProps) => {
    const [isPreloadingStarted, setIsPreloadingStarted] = useState(false);
    const [renderSlots, setRenderSlots] = useState<RenderSlot[]>([]);
    const [itemSwitchTicker, setItemSwitchTicker] = useState<number>(0);
    const [
      isActiveItemTransitioningOut,
      setIsActiveItemTransitioningOut,
    ] = useState(false);

    const activeItem: TimelineItem | undefined =
      playbackState.activeIndex !== undefined
        ? timeline.items[playbackState.activeIndex]
        : undefined;

    const nextItem: TimelineItem | undefined =
      playbackState.activeIndex !== undefined
        ? timeline.items[playbackState.activeIndex + 1]
        : undefined;

    const nextItemPrev = usePreviousValue(nextItem);

    const preloadItem: TimelineItem | undefined =
      playbackState.preloadIndex !== undefined
        ? timeline.items[playbackState.preloadIndex]
        : undefined;

    const activeTransition: number = activeItem?.transition?.duration || 0;

    useEffect(() => {
      if (nextItem && activeItem) {
        const now = playbackNowTimestamp(timeOptions);
        const activeItemScreenTimeMs =
          nextItem.startTimestamp - now >= 0
            ? nextItem.startTimestamp - now
            : 0;

        // if nextItem stays the same at this point and active screen time equals zero (which means it's time to switch
        //  to different item) - there must be a logic flaw on playback state update side. We artificially extend
        //  duration timeout here to avoid infinite timeout execution.
        const activeItemSwitchTimeoutValue =
          nextItem === nextItemPrev && activeItemScreenTimeMs === 0
            ? 3000
            : activeItemScreenTimeMs;

        const durationTimeout = window.setTimeout(() => {
          setIsActiveItemTransitioningOut(false);

          onActiveItemEnd();

          // switch ticker is used to make sure the component never gets stuck in a state without the duration timeout
          setItemSwitchTicker(itemSwitchTicker === 0 ? 1 : 0);
        }, activeItemSwitchTimeoutValue);

        const timeUntilTransitionMs = activeItemScreenTimeMs - activeTransition;

        let transitionTimeout: number | undefined = undefined;

        if (timeUntilTransitionMs > activeTransition) {
          transitionTimeout = window.setTimeout(() => {
            setIsActiveItemTransitioningOut(true);
          }, timeUntilTransitionMs);
        }

        return (): void => {
          window.clearTimeout(durationTimeout);
          window.clearTimeout(transitionTimeout);
        };
      }
    }, [
      activeItem,
      nextItem,
      activeTransition,
      timeOptions,
      onActiveItemEnd,
      nextItemPrev,

      // switch ticker is forces the duration timeout to be reset on each timeout callback execution to avoid
      itemSwitchTicker,
    ]);

    useEffect(() => {
      if (
        preloadItem &&
        !isTimelineItemVoid(preloadItem) &&
        preloadItem.preloadDurationMs !== undefined
      ) {
        let timeUntilPreload =
          preloadItem.startTimestamp -
          playbackNowTimestamp(timeOptions) -
          preloadItem.preloadDurationMs;

        if (timeUntilPreload < 0) {
          timeUntilPreload = 0;
        }

        const startPreloadTimeout = window.setTimeout(() => {
          setIsPreloadingStarted(true);
        }, timeUntilPreload);

        return (): void => {
          setIsPreloadingStarted(false);
          window.clearTimeout(startPreloadTimeout);
        };
      }
    }, [preloadItem, timeOptions]);

    useEffect(() => {
      // this effect always sets 2 slots. Even if there is no content to show or preload.

      const updatedSlots: RenderSlot[] = [];
      const existingActiveItemSlotIndex = renderSlots.findIndex((slot) =>
        doesSlotRepresentTimelineItem(slot.item, activeItem)
      );
      const existingPreloadItemSlotIndex = renderSlots.findIndex((slot) =>
        doesSlotRepresentTimelineItem(slot.item, preloadItem)
      );
      const freeSlotIndexes: number[] = [0, 1].filter(
        (indexNumber) =>
          ![existingActiveItemSlotIndex, existingPreloadItemSlotIndex].includes(
            indexNumber
          )
      );

      const activeItemSlotIndex =
        existingActiveItemSlotIndex > -1
          ? existingActiveItemSlotIndex
          : freeSlotIndexes.pop();
      const preloadItemSlotIndex =
        existingPreloadItemSlotIndex > -1 &&
        existingPreloadItemSlotIndex !== existingActiveItemSlotIndex
          ? existingPreloadItemSlotIndex
          : freeSlotIndexes.pop();

      if (activeItemSlotIndex !== undefined) {
        updatedSlots[activeItemSlotIndex] = {
          item: activeItem,
          isPreload: false,
        };
      } else {
        throw new Error("Slot index can not be undefined");
      }
      if (preloadItemSlotIndex !== undefined) {
        updatedSlots[preloadItemSlotIndex] = {
          item: preloadItem,
          isPreload: true,
        };
      } else {
        throw new Error("Slot index can not be undefined");
      }

      if (!isEqual(updatedSlots, renderSlots)) {
        setRenderSlots(updatedSlots);
      }
    }, [activeItem, preloadItem, renderSlots]);

    return (
      <>
        {
          /* Main purpose of "render slot": keep a DOM node representing a content item at exactly same place in the
           * DOM tree when switching from preload mode to active mode. This is especially important for iframes, rendered
           * for preloading - chrome reloads iframe's url if you move the iframe node in a DOM tree.
           * */
          renderSlots.map((slot, idx) => (
            <Slot
              index={idx}
              key={idx}
              {...slot}
              isPreloadingStarted={slot.isPreload && isPreloadingStarted}
              transition={slot.item?.transition}
              isActiveItemTransitioningOut={isActiveItemTransitioningOut}
            />
          ))
        }
      </>
    );
  }
);
TimelineViewer.displayName = "TimelineViewer";
