import { REQUEST_PLAYLIST_SUCCESS } from "./../playlists/types";
import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { SitesState, NormalizedSiteFragment, Site } from "./types";
import isEqual from "lodash/isEqual";

/**
 * Data about the available app instances.
 */
const initialState: SitesState = {
  byId: {},
};

export function sitesReducer(
  state = initialState,
  action: PlayerAction
): SitesState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS: {
        const sites = action.payload.sites;
        const siteIds = Object.keys(sites);
        siteIds.forEach((id) => {
          if (sites[id]) {
            const newSite = mapGqlSiteToPlayerSite(sites[id]);
            if (!isEqual(newSite, state.byId[id])) {
              draft.byId[id] = newSite;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlSiteToPlayerSite = (data: NormalizedSiteFragment): Site => {
  return {
    config: data.config,
    id: data.id,
    name: data.name,
    type: data.type,
    url: data.url,
    fileByThumbnailId: data.fileByThumbnailId || null,
  };
};
