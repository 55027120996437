import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";

/**
 * Returns theme id to be applied to a specified channel
 */
export default function useThemeId(
  channelId: string | undefined
): string | undefined {
  return useSelector<PlayerState, string | undefined>((state) => {
    if (!channelId) {
      return undefined;
    }

    if (state.channels.byId[channelId]?.themeId) {
      return state.channels.byId[channelId].themeId;
    }

    return state.organization.defaultThemeId;
  });
}
