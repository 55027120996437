import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { App } from "../../../../store/apps/types";
import { PlayerState } from "../../../../store/rootReducer";
import styles from "./AppViewer.module.css";
import {
  AppTokenPayload,
  GenericMessage,
  InitializeMessagePayload,
  PMIMessageReceivedPayload,
} from "./types";
import { Theme } from "../../../../store/themes/types";
import { ScreenData } from "../../../../store/screen/types";
import { ContextConfig, DeviceConfig } from "../../../../store/config/types";
import { activeChannelId } from "../../../../store/screen/selectors";
import { PlayerFile } from "../../../../store/files/types";
import { useManualQuery } from "graphql-hooks";
import { ConfigurationManager } from "../../../../configurationManager";
import { CREATE_APP_TOKEN } from "../../../../store/graphqlQueries";
import { requestAppSuccess } from "../../../../store/apps/actions";
import { Loading } from "../../../core/components/Loading/Loading";
import { EntityType } from "@screencloud/signage-firestore-client";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import useThemeId from "../../../../utils/useThemeId";
import { DEFAULT_APP_INSTANCE_ID } from "../../../../constants";
import { useAppInstanceFiles } from "./useAppInstanceFiles";
import { useInitialPlaybackPosition } from "../../../../utils/useInitialPlaybackPosition";
import {
  makeConnectedMessage,
  makeInitializeMessage,
  makeStartMessage,
  sendMessage,
} from "./utils";
import { OrganizationState } from "../../../../store/organization/types";
import { Maybe, useAppInstanceRoot } from "../../../../queries";
import { Logger } from "../../../../logger/logger";

const log = new Logger("appViewer");

interface AppViewerContainerProps {
  id: string;
  isPreload?: boolean;
  fullDurationMs: number;
  isRoot?: boolean;
  itemStartTimestamp?: number;
}

export const AppViewerContainer = (
  props: AppViewerContainerProps
): ReactElement<AppViewerContainerProps> => {
  const dispatch = useDispatch();
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );
  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );
  const contentPathId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );

  const app = useSelector<PlayerState, App | undefined>((state) => {
    if (!isPreview) {
      return state.apps.byId[props.id];
    }
    return undefined;
  });

  const overrideAppInitialize = useSelector<
    PlayerState,
    Partial<InitializeMessagePayload> | undefined
  >((state) => {
    if (isPreview) {
      const overrideAppInitialize = state.config.overrideAppInitialize;
      // Ensure content path matches overrideAppInitialize being sent
      if (overrideAppInitialize?.appInstanceId === contentPathId) {
        return overrideAppInitialize;
      }
    }
    return undefined;
  });

  // Only fetch when either objects are false
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(
    !!app || !!overrideAppInitialize
  );

  const [fetchApp, { data }] = useAppInstanceRoot({
    useCache: false,
    skipCache: true,
    variables: {
      id: props.id,
    },
  });

  // watch for data dependency only if appInstanceById change to prevent too many dispatch call
  // we’re only using a appInstanceById on the data object.
  // so instead of useEffect depend on the whole data we only depend on appInstanceById object
  useEffect(() => {
    if (data?.appInstanceById) {
      dispatch(requestAppSuccess(data.appInstanceById));
      setIsDataLoaded(true);
    }
  }, [data?.appInstanceById, dispatch]);

  useInitialFetch(!!props.isRoot && !isPreview, fetchApp);

  const channelId = useSelector<PlayerState, string | undefined>(
    activeChannelId
  );

  const themeId = useThemeId(channelId);

  const theme = useSelector<PlayerState, Theme | undefined>((state) =>
    themeId ? state.themes.byId[themeId] : undefined
  );

  const screenData = useSelector<PlayerState, ScreenData | undefined>(
    (state) => state.screen.screenData
  );

  const org = useSelector<PlayerState, OrganizationState | undefined>(
    (state) => state.organization
  );
  const featureFlags = org?.featureFlags;
  const orgId = org?.id;

  const screenId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.id
  );

  const spaceId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.spaceId || undefined
  );

  const filesByAppInstanceId = useAppInstanceFiles(props.id, isPreview);

  const device = useSelector<PlayerState, DeviceConfig | undefined>(
    (state) => state.config.device
  );

  // Using a key to re-mount the app when app data objects are updated
  // TODO - Should this be handled by the key in GenericViewer? State here will not remount the component, just re-render it.
  const [reMountKey, setReMountKey] = useState<number | undefined>();
  const isFirstRun = useRef(true);

  const initialPlaybackPositionMs = useInitialPlaybackPosition(
    props.itemStartTimestamp,
    props.fullDurationMs
  );

  /**
   * When data changes, set a new key which will mount a new child AppViewer component
   * i.e. trigger any unmount code as well
   **/

  useEffect(() => {
    // Do not update the stack on the initial render.
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setReMountKey(new Date().getTime());
  }, [app, overrideAppInitialize, featureFlags, theme]);

  // TODO fix rerender and remove useffect hook
  useEffect(() => {
    log.debug(`Show App container`, {
      viewUrl: app?.viewerUrl,
      name: app?.name,
      contentType: "app",
      isPreview: isPreview,
      isPreload: props.isPreload,
    });
  }, [app?.id, props.isPreload]);

  if (!app && !overrideAppInitialize) {
    return <p>Sorry, we can&apos;t find the app you are looking for.</p>;
  }

  return (
    <>
      <LiveUpdateConnector
        entityId={props.id}
        entityType={EntityType.APP_INSTANCE}
      />
      {!isDataLoaded ? (
        <Loading />
      ) : (
        <AppViewer
          key={reMountKey}
          app={app}
          overrideAppInitialize={overrideAppInitialize}
          theme={theme}
          orgId={orgId}
          screenId={screenId}
          spaceId={spaceId}
          screenData={screenData}
          filesByAppInstanceId={filesByAppInstanceId}
          fullDurationMs={props.fullDurationMs}
          isPreload={props.isPreload}
          contextConfig={contextConfig}
          isPreview={isPreview}
          device={device}
          initialPlaybackPositionMs={initialPlaybackPositionMs}
          durationElapsedMs={initialPlaybackPositionMs}
          featureFlags={featureFlags}
        />
      )}
    </>
  );
};

interface AppViewerProps {
  app?: App;
  overrideAppInitialize?: Partial<InitializeMessagePayload>;
  theme?: Theme;
  screenData?: ScreenData;
  orgId?: string;
  screenId?: string;
  spaceId?: string;
  filesByAppInstanceId: {
    nodes: Array<PlayerFile>;
  };
  fullDurationMs: number;
  isPreload?: boolean;
  contextConfig: ContextConfig;
  isPreview: boolean;
  device?: DeviceConfig;
  initialPlaybackPositionMs: number;
  durationElapsedMs: number;
  featureFlags?: Maybe<string>[];
}

type RequestAuthTokenListener = ({
  data,
  requestId,
}: PMIMessageReceivedPayload) => void;

// The AppViewer is exported for reuse in the SiteViewerContainer.
// This will no longer need to be exported after Secure Sites has been refactored to use App Instances under the hood.
export const AppViewer = (
  props: AppViewerProps
): ReactElement<AppViewerProps> => {
  const {
    app,
    overrideAppInitialize,
    theme,
    screenData,
    orgId,
    screenId,
    spaceId,
    filesByAppInstanceId,
    fullDurationMs,
    contextConfig,
    isPreview,
    device,
    durationElapsedMs,
    featureFlags,
  } = props;
  // TODO - How does memo() interact with this ref? When does it reset?
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const viewerUrl = app?.viewerUrl || overrideAppInitialize?.viewerUrl || "";

  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const [fetchAppToken] = useManualQuery<{
    createSignedRuntimeJwt: AppTokenPayload;
  }>(CREATE_APP_TOKEN, {
    useCache: false,
    skipCache: true,
    variables: {
      input: {
        name: app?.name || "",
        appId: app?.id || "",
        appInstanceId:
          overrideAppInitialize?.appInstanceId || DEFAULT_APP_INSTANCE_ID,
        screenId: screenId || "",
      },
    },
  });

  const requestAppToken = useCallback(async (): Promise<
    AppTokenPayload | undefined
  > => {
    try {
      const response = await fetchAppToken();
      return (response.data as {
        createSignedRuntimeJwt: AppTokenPayload;
      }).createSignedRuntimeJwt;
    } catch (e) {
      log.error("fetchAppToken failed", {
        message: e?.message,
      });
      return undefined;
    }
  }, [fetchAppToken]);

  useEffect(() => {
    const onMessage = (event: MessageEvent): void => {
      try {
        const { data, source } = event;

        // There may be multiple AppViewers on screen at once, e.g. multiple zones.
        if (source !== iframeRef.current?.contentWindow) {
          return;
        }

        // Only CONNECT, CONNECT_SUCCESS, DISCONNECT messages add this ___ thing.
        // The rest nest their data under a 2nd "data" key.
        // TODO - Remove this complexity.
        if (data.substr(0, 3) === "___") {
          handleMessage(JSON.parse(data.substring(3)));
        } else {
          const parsed = JSON.parse(data);
          handleMessage(parsed.data, parsed.requestId);
        }
      } catch (err) {
        log.warn("Could not parse received postMessage", {
          err: err?.message,
        });
      }
    };

    /**
     * 1 - Receive all messages from this app.
     */
    window.addEventListener("message", onMessage, false);
    /**
     * 2 - Deal with the messages.
     */
    const handleMessage = async (
      receivedMessage: GenericMessage,
      requestId?: number
    ): Promise<void> => {
      const replyMessages: GenericMessage[] = [];

      switch (receivedMessage.type) {
        case "CONNECT": {
          replyMessages.push(makeConnectedMessage());
          if (app) {
            replyMessages.push(
              makeInitializeMessage(
                app,
                filesByAppInstanceId,
                theme,
                orgId,
                screenId,
                spaceId,
                screenData,
                fullDurationMs,
                contextConfig,
                device,
                durationElapsedMs,
                featureFlags
              )
            );
          } else if (overrideAppInitialize) {
            replyMessages.push({
              type: "initialize",
              payload: {
                ...overrideAppInitialize,
                context: {
                  ...contextConfig,
                  theme,
                  screenData,
                },
                durationElapsedMs,
                featureFlags,
              },
            });
          }
          break;
        }
        case "initialized":
          setIsAppInitialized(true);
          break;
        case "started":
          log.info(
            `Player received app "started" confirmation for[app-${app?.name}]`,
            {
              name: app?.name,
              appid: app?.id,
              contentType: "app",
              isPreview: isPreview,
              isPreload: props.isPreload,
            }
          );
          break;
        case "requestAuthToken":
          log.info(`Auth token requested for [app-${app?.name}]`, {
            appname: app?.name,
            contentType: "app",
            isPreview: isPreview,
            isPreload: props.isPreload,
          });

          // if playing in a preview in editor apps
          // the request auth token has to come from studio as unsaved editor apps don't have an app instance yet
          if (isPreview) {
            ConfigurationManager.getInstance()
              .getRemoteInterface()
              .fire("requestAuthToken", { requestId });
          } else {
            const appTokenPayload = await requestAppToken();
            if (iframeRef.current) {
              replyMessages.push({
                type: "requestAuthToken",
                payload: { authToken: appTokenPayload?.signedRuntimeToken },
                requestId,
              });
            }
          }

          break;
      }

      replyMessages.forEach((message) => {
        if (iframeRef.current) {
          if (app || overrideAppInitialize) {
            const { requestId, ...messageToSend } = message;
            sendMessage(iframeRef.current, messageToSend, viewerUrl, requestId);
          }
        }
      });
    };

    /**
     * Clean up when the App is removed from screen.
     */
    return (): void => {
      log.debug(`AppViewer - removeEventListener.`, {
        appId: app?.id,
        viewerUrl: app?.viewerUrl,
        name: app?.name,
        appInstallId: app?.appInstallId,
        contentType: "app",
        isPreview: isPreview,
        isPreload: props.isPreload,
      });
      window.removeEventListener("message", onMessage, false);
    };
  }, [
    app,
    overrideAppInitialize,
    theme,
    screenData,
    screenId,
    orgId,
    filesByAppInstanceId,
    fullDurationMs,
    props.isPreload,
    viewerUrl,
    contextConfig,
    requestAppToken,
    spaceId,
    isPreview,
    device,
    durationElapsedMs,
    featureFlags,
  ]);

  useEffect(() => {
    // send the started message if the app changes from preload to current
    if (iframeRef.current && isAppInitialized && !props.isPreload) {
      sendMessage(iframeRef.current, makeStartMessage(), viewerUrl);
    }
  }, [props.isPreload, isAppInitialized, viewerUrl]);

  useEffect(() => {
    let listener: RequestAuthTokenListener | undefined = undefined;
    if (isPreview) {
      listener = ({ data, requestId }: PMIMessageReceivedPayload) => {
        if (iframeRef.current) {
          sendMessage(
            iframeRef.current,
            {
              type: "requestAuthToken",
              payload: data,
            },
            viewerUrl,
            requestId
          );
        }
      };

      ConfigurationManager.getInstance()
        .getRemoteInterface()
        .on("SP_SEND_REQUEST_AUTH_TOKEN", listener);
    }

    return () => {
      if (listener) {
        ConfigurationManager.getInstance()
          .getRemoteInterface()
          .off("SP_SEND_REQUEST_AUTH_TOKEN", listener);
      }
    };
  }, [viewerUrl, isPreview]);

  const onError = useCallback(
    (event: SyntheticEvent<HTMLIFrameElement>) => {
      // this is not of much use, as iframes of a different origin do not expose any meaningful details about errors.
      log.error(`AppViewer iframe error`, {
        viewerUrl,
        appName: app?.name,
        appInstanceId: app?.id,
      });
    },
    [viewerUrl, app]
  );

  /**
   * Cleanup
   */
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      log.info(`Unmount called for app.[app-${app?.name}]`, {
        name: app?.name,
        contentType: "app",
        isPreview: isPreview,
        isPreload: props.isPreload,
      });
    };
  }, []);

  useEffect(() => {
    // TODO remove this hook and fix app  multiple rerender
    log.info(`Show App [app-${app?.name}]`, {
      viewUrl: app?.viewerUrl,
      name: app?.name,
      contentType: "app",
      isPreview: isPreview,
      isPreload: props.isPreload,
    });
  }, [app?.id, props.isPreload]);

  return (
    <>
      <iframe
        ref={iframeRef}
        className={styles.iframe}
        title={app?.name || ""}
        src={viewerUrl}
        onError={onError}
      />
    </>
  );
};

AppViewer.displayName = "AppViewer";
