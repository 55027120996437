import { RequestScreenSuccessAction } from "../screen/types";
import { ScheduleFilterResult } from "../../utils/scheduleFilter";

// Action types
export const NEXT_ITEM = "NEXT_ITEM";
export const SET_TIMELINE_CONTROL_OFFSET = "SET_TIMELINE_CONTROL_OFFSET";
export const TIMELINE_SKIP_FORWARD = "TIMELINE_SKIP_FORWARD";
export const TIMELINE_SKIP_BACK = "TIMELINE_SKIP_BACK";

export type PlaybackAction =
  | RequestScreenSuccessAction
  | NextItemAction
  | SetTimelineControlOffsetAction
  | TimelineSkipForwardAction
  | TimelineSkipBackAction;

export interface NextItemAction {
  type: typeof NEXT_ITEM;
  payload: {
    timelineId: string;
    targetTimestamp: number;
    deterministicTimelineStartTimestamp?: number;
  };
}

export interface SetTimelineControlOffsetAction {
  type: typeof SET_TIMELINE_CONTROL_OFFSET;
  payload: {
    offsetValue: number;
  };
}

export type PlaybackState = {
  timelines: {
    [key: string]: TimelinePlaybackState;
  };
  controls: {
    timelineOffset: number;
  };
};

export interface ContentListPlaybackState {
  activeIndex: number | undefined; // undefined = list is empty after applying schedule filters
  activeScreenTimeMs: number | undefined; // undefined = active item is the only one and never ends
  activeStartedTimestamp: number; // indicates a point in time when activeIndex was changed last time
  preloadIndex?: number; // Undefined => Don't preload.
  id: string;
  scheduleFilteringMemo: FilteringMemo;
}

export interface TimelinePlaybackState {
  activeIndex: number | undefined; // undefined => timeline is empty
  activeScreenTimeMs: number | undefined; // undefined = the item was indicated to stay on screen until further timeline update
  preloadIndex: number | undefined;
  id: string;
  nextChunkRequestTimestamp: number | undefined; // when should the timeline operator request the next chunk of items
}

export type FilteringMemo = FilterMemoItem[];

export interface FilterMemoItem {
  result: ScheduleFilterResult | undefined;
  filteredListFullDurationMs?: number;
  validItemsCount?: number;
  periodStart: number;
  periodEnd: number;
}

export interface TimelineSkipForwardAction {
  type: typeof TIMELINE_SKIP_FORWARD;
}

export interface TimelineSkipBackAction {
  type: typeof TIMELINE_SKIP_BACK;
}
