import { DAILY_RELOAD_KEY } from "./../constants";
import { LocalStorage } from "./../storage";
import {
  TimeOptions,
  getTimestampByTime,
  playbackNowTimestamp,
} from "./timeManager";
import { DAY_DURATION_MS, HOUR_DURATION_MS } from "../constants";
import { isURLReachable } from "./isUrlReachable";
import { Logger } from "../logger/logger";

const log = new Logger("singleContentLogs.tsx");
let timeout: number;

/*
  Reloads at a random time between 2am and 4am every day.
*/

export function setDailyReload(timeOptions: TimeOptions): void {
  const reloadPeriodStart =
    getTimestampByTime(timeOptions, "02:00") + DAY_DURATION_MS; // next 2am
  const reloadPeriodDurationMs = HOUR_DURATION_MS * 2;
  const randomOffset = Math.round(Math.random() * reloadPeriodDurationMs);

  const reloadTime = reloadPeriodStart + randomOffset;

  const timeoutValue = reloadTime - playbackNowTimestamp(timeOptions);

  if (timeout) {
    window.clearTimeout(timeout);
  }

  log.info(`Set daily reload timeout.`, {
    reloadTimestamp: reloadTime,
    reloadTime: new Date(reloadTime).toLocaleString(),
  });

  timeout = window.setTimeout(() => {
    // set dailyReload to trigger API call to clean up any old state items
    LocalStorage.getInstance().setItem(DAILY_RELOAD_KEY, "true");

    log.info(`Daily reload triggered.`, {});

    window.location.reload();
  }, timeoutValue);
}

export const clearLocalStorageAfterDailyReload = async (
  testUrl: string
): Promise<void> => {
  // check if daily refresh has occurred
  if (LocalStorage.getInstance().getItem(DAILY_RELOAD_KEY) === "true") {
    // check for network connection
    if (navigator.onLine) {
      try {
        // check for internet connection
        const result = await isURLReachable(addNoCacheParam(testUrl));
        if (result) {
          log.info("Removing localStorage key 'reduxpersist_root'", {});

          // clear state in local storage to trigger a new API call
          LocalStorage.getInstance().removeItem("reduxpersist_root");
        }
      } catch (e) {
        log.error(`clearLocalStorageAfterDailyReload process ERROR `, {
          message: e?.message,
        });
      }
    }
    // remove daily reload flag once data has been requested
    LocalStorage.getInstance().removeItem(DAILY_RELOAD_KEY);
  }
};

function addNoCacheParam(url: string): string {
  return `${url}${url.indexOf("?") > -1 ? "&" : "?"}nc=${new Date().getTime()}`;
}
