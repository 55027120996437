import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { appsReducer } from "./apps/reducer";
import { AppsState } from "./apps/types";
import { chainReducers } from "./chainReducers";
import { channelsReducer } from "./channels/reducer";
import { ChannelsState } from "./channels/types";
import { configReducer } from "./config/reducer";
import { ConfigState } from "./config/types";
import { contentListsReducer } from "./contentLists/reducer";
import { ContentListsState } from "./contentLists/types";
import { filesReducer } from "./files/reducer";
import { FilesState } from "./files/types";
import { layoutsReducer } from "./layouts/reducer";
import { LayoutsState } from "./layouts/types";
import { linksReducer } from "./links/reducer";
import { LinksState } from "./links/types";
import { organizationReducer } from "./organization/reducer";
import { OrganizationState } from "./organization/types";
import { playbackReducer, emptyPlaybackReducer } from "./playback/reducer";
import { playListReducer } from "./playlists/reducer";
import { screenReducer } from "./screen/reducer";
import { ScreenState } from "./screen/types";
import { PlayerAction, unusedAction } from "./storeTypes";
import { themesReducer } from "./themes/reducer";
import { ThemesState } from "./themes/types";
import reduxPersistStorage from "redux-persist/lib/storage";
import { PlaybackState } from "./playback/types";
import { TimelinesState } from "./timelines/types";
import { emptyTimelinesReducer, timelinesReducer } from "./timelines/reducer";
import { SpacesState } from "./spaces/types";
import { spacesReducer } from "./spaces/reducer";
import { sitesReducer } from "./sites/reducer";
import { LiveUpdatesState } from "./liveUpdates/types";
import { liveUpdatesReducer } from "./liveUpdates/reducer";
import { PersistMigrate } from "redux-persist/es/types";
import ValuesTransform from "./ValuesTransform";
import pThrottle from "p-throttle";
import { getLogger } from "../utils/debugLog";
import { ReduxPersistCounter } from "../utils/interactionCounter";
import { captureMessage } from "../utils/bugTracker";
import utilReducer from "./util/utilReducer";
import { SitesState } from "./sites/types";
import { PlaylistsState } from "./playlists/types";
const log = getLogger("rootReducer");

function getStoreVersion(): number {
  return process.env.REACT_APP_REDUX_SCHEMA_VERSION !== undefined
    ? parseInt(process.env.REACT_APP_REDUX_SCHEMA_VERSION)
    : -1;
}

export const combinedReducer = combineReducers<PlayerState>({
  apps: appsReducer,
  channels: channelsReducer,
  playlists: playListReducer,
  config: configReducer,
  contentLists: contentListsReducer,
  files: filesReducer,
  layouts: layoutsReducer,
  links: linksReducer,
  organization: organizationReducer,
  screen: screenReducer,
  themes: themesReducer,
  timelines: emptyTimelinesReducer,
  playback: emptyPlaybackReducer,
  spaces: spacesReducer,
  liveUpdates: liveUpdatesReducer,
  sites: sitesReducer,
});

export const ROOT_STORAGE_KEY = "root";
export const PERSIST_KEY_PREFIX = "reduxpersist_";

const droppingMigration: PersistMigrate = (state) => {
  if (state?._persist.version !== getStoreVersion()) {
    return Promise.resolve(emptyInitialState);
  } else {
    return Promise.resolve(state);
  }
};

const { setItem, getItem, removeItem } = reduxPersistStorage;
const throttle = pThrottle({ limit: 1, interval: 500 });
const persistLogCountThreshold = 5;
// Setting time window of 5 secs to count amount of persist logs
// persistLogCount will be reset after 5 secs
const setReduxPersistCounter = new ReduxPersistCounter(5000); // record the persist logs within a 5 sec time period

const blacklist: Array<keyof PlayerState> = [
  "playback",
  "timelines",
  "liveUpdates",
];
const persistConfigRoot = {
  key: ROOT_STORAGE_KEY,
  keyPrefix: PERSIST_KEY_PREFIX,
  migrate: droppingMigration,
  storage: {
    setItem: throttle((key: string, value: string) => {
      const persistLogCount = setReduxPersistCounter.add();
      log(
        `redux-persist setItem, string length: ${value?.length}, count: ${persistLogCount}`
      );
      if (persistLogCount % persistLogCountThreshold === 0) {
        captureMessage(
          `Too many redux-persist setItem calls. Calls count: ${persistLogCount}`
        );
      }
      return setItem(key, value);
    }),
    getItem,
    removeItem,
  },
  version: getStoreVersion(),
  blacklist,
  transforms: [ValuesTransform],
};

const dataReducer = chainReducers<PlayerState, PlayerAction>(
  combinedReducer,
  timelinesReducer,
  playbackReducer
);

export const rootReducer =
  process.env.NODE_ENV === "test"
    ? chainReducers<PlayerState, PlayerAction>(dataReducer, utilReducer)
    : dataReducer;

export const rootPersistedReducer = persistReducer<PlayerState>(
  persistConfigRoot,
  rootReducer
);

export const emptyInitialState = rootPersistedReducer(
  undefined,
  unusedAction()
);

export interface PlayerState {
  apps: AppsState;
  channels: ChannelsState;
  config: ConfigState;
  contentLists: ContentListsState;
  files: FilesState;
  layouts: LayoutsState;
  links: LinksState;
  organization: OrganizationState;
  playlists: PlaylistsState;
  screen: ScreenState;
  themes: ThemesState;
  timelines: TimelinesState;
  playback: PlaybackState;
  spaces: SpacesState;
  sites: SitesState;
  liveUpdates: LiveUpdatesState;
}
