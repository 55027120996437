import React, { FunctionComponent } from "react";
import styles from "./SidePanel.module.css";
import classNames from "classnames";
import { MenuItemType } from "../types";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";
import PlaylistIcon from "../../../../img/icon-playlist.svg";
import ImageIcon from "../../../../img/icon-image.svg";
import VideoIcon from "../../../../img/icon-video.svg";
import DashboardsIcon from "../../../../img/icon-dashboards.svg";
import DocumentsIcon from "../../../../img/icon-documents.svg";
import EmbedLinkIcon from "../../../../img/icon-embed-link.svg";
interface Props {
  appsCount: number;
  videosCount: number;
  imagesCount: number;
  documentsCount: number;
  dashboardsCount: number;
  linksCount: number;
  type: MenuItemType;
  setType: (type: MenuItemType) => void;
  setPlayListId: (type: string) => void;
  playlistId: string;
}

const SidePanel: FunctionComponent<Props> = ({
  appsCount,
  videosCount,
  imagesCount,
  documentsCount,
  dashboardsCount,
  linksCount,
  type,
  setType,
  setPlayListId,
  playlistId,
}: Props) => {
  const { playlistContent } = useEmbedMenu();
  const allContentCount =
    videosCount +
    imagesCount +
    documentsCount +
    dashboardsCount +
    linksCount +
    appsCount;
  return (
    <>
      <div className={styles.top} />
      <div className={styles.typeWrapper}>
        {/* TODO: Make these presentational components */}
        <div
          className={type === MenuItemType.All ? styles.selectedBar : ""}
        ></div>
        <div
          className={classNames([
            styles.type,
            type === MenuItemType.All ? styles.selectedType : "",
          ])}
          onClick={() => setType(MenuItemType.All)}
        >
          <div>All Content</div>
          <div className={styles.count}>
            <p>{allContentCount}</p>
          </div>
        </div>
      </div>

      {/* PlayList Section */}
      <div className={styles.playlistSection}>
        <div className={styles.divider}></div>
        <p className={styles.sectionTitle}>PLAYLISTS</p>
        {playlistContent?.map((playlist: any, i: number) => (
          <div key={i} className={styles.typeWrapper}>
            <div
              className={
                type === MenuItemType.Playlist && playlistId === playlist.id
                  ? styles.selectedBar
                  : ""
              }
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Playlist && playlistId === playlist.id
                  ? styles.selectedType
                  : "",
              ])}
              onClick={() => {
                setPlayListId(playlist.id);
                setType(MenuItemType.Playlist);
              }}
            >
              <img src={PlaylistIcon} className={styles.icon} alt="Apps" />
              <div className={styles.playlistName}>{playlist.name}</div>
              <div className={styles.count}>
                <p>{playlist.items.length}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Categories Section */}
      <div className={styles.categoriesSection}>
        <div className={styles.divider}></div>
        <p className={styles.sectionTitle}>CATEGORIES</p>

        {/* Images */}
        {imagesCount > 0 && (
          <div className={styles.typeWrapper}>
            <div
              className={type === MenuItemType.Image ? styles.selectedBar : ""}
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Image ? styles.selectedType : "",
              ])}
              onClick={() => setType(MenuItemType.Image)}
            >
              <img src={ImageIcon} className={styles.icon} alt="Images" />
              <div>Images</div>
              <div className={styles.count}>
                <p>{imagesCount}</p>
              </div>
            </div>
          </div>
        )}

        {/* VIDEOS */}
        {videosCount > 0 && (
          <div className={styles.typeWrapper}>
            <div
              className={type === MenuItemType.Video ? styles.selectedBar : ""}
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Video ? styles.selectedType : "",
              ])}
              onClick={() => setType(MenuItemType.Video)}
            >
              <img src={VideoIcon} className={styles.icon} alt="Videos" />
              <div>Videos</div>
              <div className={styles.count}>
                <p>{videosCount}</p>
              </div>
            </div>
          </div>
        )}

        {/* DASHBOARDS */}
        {dashboardsCount > 0 && (
          <div className={styles.typeWrapper}>
            <div
              className={
                type === MenuItemType.Dashboards ? styles.selectedBar : ""
              }
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Dashboards ? styles.selectedType : "",
              ])}
              onClick={() => setType(MenuItemType.Dashboards)}
            >
              <img
                src={DashboardsIcon}
                className={styles.icon}
                alt="Dashboards"
              />
              <div>Dashboards</div>
              <div className={styles.count}>
                <p>{dashboardsCount}</p>
              </div>
            </div>
          </div>
        )}

        {/* DOCUMENTS */}
        {documentsCount > 0 && (
          <div className={styles.typeWrapper}>
            <div
              className={
                type === MenuItemType.Document ? styles.selectedBar : ""
              }
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Document ? styles.selectedType : "",
              ])}
              onClick={() => setType(MenuItemType.Document)}
            >
              <img
                src={DocumentsIcon}
                className={styles.icon}
                alt="Documents"
              />
              <div>Documents</div>
              <div className={styles.count}>
                <p>{documentsCount}</p>
              </div>
            </div>
          </div>
        )}

        {/* Links */}
        {linksCount > 0 && (
          <div className={styles.typeWrapper}>
            <div
              className={type === MenuItemType.Links ? styles.selectedBar : ""}
            ></div>
            <div
              className={classNames([
                styles.type,
                type === MenuItemType.Links ? styles.selectedType : "",
              ])}
              onClick={() => setType(MenuItemType.Links)}
            >
              <img src={EmbedLinkIcon} className={styles.icon} alt="Links" />
              <div>Webpages</div>
              <div className={styles.count}>
                <p>{linksCount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.block}></div>
    </>
  );
};
export default SidePanel;
