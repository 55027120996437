import {
  Mimetype,
  VideoStreamingMimetypesPriorityList,
} from "../store/files/types";

/**
 * This setting is enclosed in it's own module, cause it is supposed to be set only once on app bootstrap.
 */
let shouldPrioritizeStreamingVideoValue = false;

export const setShouldPrioritizeStreamingVideo = (value: boolean): void => {
  shouldPrioritizeStreamingVideoValue = value;
};

export const shouldPrioritizeStreamingVideo = (): boolean => {
  return shouldPrioritizeStreamingVideoValue;
};

export const getVideoMimetypePriority = (): Mimetype[] => {
  if (shouldPrioritizeStreamingVideo()) {
    return [
      ...VideoStreamingMimetypesPriorityList,
      "video/mp4", // regular mp4
    ];
  } else {
    return [
      "video/mp4", // regular mp4
      ...VideoStreamingMimetypesPriorityList,
    ];
  }
};
