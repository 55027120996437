import { LiveUpdateItemType, LiveUpdatesState } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../rootReducer";
import { useEffect } from "react";
import { liveUpdateLoaded } from "./actions";
import { getLiveUpdateCompositeId } from "./utils";
import { useShouldHoldLiveUpdates } from "../../utils/useShouldHoldLiveUpdates";
import { EntityType } from "@screencloud/signage-firestore-client";

interface ActiveLiveUpdate {
  id: string;
  timestamp: number;
}

// to make this hook testable need to return
interface LiveUpdateRef {
  targetNotificationId: string;
}

// this is used to avoid duplicate requests of the same notification
const pendingNotificationRequests: { [id: string]: ActiveLiveUpdate } = {};

export function useLiveUpdate(
  entityType: LiveUpdateItemType,
  entityId: string,
  fetchCallback: () => Promise<unknown>
): LiveUpdateRef {
  // This is going to be used to artificially delay the live update data fetches
  //  Related discussion in this gh issue https://github.com/screencloud/issues/issues/448#issuecomment-828442873
  //  I expect it to be removed once the team comes to a consensus on highlighted timeline/playback issues.
  //  Note: live updates for screen entity is always loaded instantly to prevent different content assignment halts
  const shouldHoldLiveUpdates =
    useShouldHoldLiveUpdates() && entityType !== EntityType.SCREEN;

  const dispatch = useDispatch();

  const liveUpdateNotifications = useSelector<PlayerState, LiveUpdatesState>(
    (state) => state.liveUpdates
  );

  const targetNotificationId = getLiveUpdateCompositeId(entityType, entityId);
  const targetNotification = liveUpdateNotifications.byId[targetNotificationId];

  const isLoaded = targetNotification?.isLoaded;
  const notificationTimestamp = targetNotification?.timestamp;

  useEffect(() => {
    let isDismissed = false;

    if (
      !shouldHoldLiveUpdates &&
      isLoaded === false &&
      notificationTimestamp !== undefined &&
      (!pendingNotificationRequests[targetNotificationId] ||
        pendingNotificationRequests[targetNotificationId].timestamp <
          notificationTimestamp)
    ) {
      pendingNotificationRequests[targetNotificationId] = {
        id: targetNotificationId,
        timestamp: notificationTimestamp,
      };

      const removeFromPendingRequests = (): void => {
        if (
          pendingNotificationRequests[targetNotificationId]?.timestamp ===
          notificationTimestamp
        ) {
          delete pendingNotificationRequests[targetNotificationId];
        }
      };

      fetchCallback()
        .then(() => {
          removeFromPendingRequests();
          if (!isDismissed) {
            dispatch(liveUpdateLoaded(entityId, entityType));
          }
        })
        .catch(() => {
          removeFromPendingRequests();
        });
    }

    return (): void => {
      isDismissed = true;
    };
  }, [
    entityType,
    entityId,
    fetchCallback,
    dispatch,
    shouldHoldLiveUpdates,
    targetNotificationId,
    isLoaded,
    notificationTimestamp,
  ]);

  return {
    targetNotificationId,
  };
}
