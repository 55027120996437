import React, { FunctionComponent } from "react";
import MainPanel from "./MainPanel";
import styles from "./Menu.module.css";
import MenuActions from "./MenuActions";
import SidePanel from "./SidePanel";
import { MenuItemType } from "./types";
import { useEmbedMenu } from "../../../providers/MenuProvider/MenuProvider";
import { MenuContentItem } from "../../../providers/MenuProvider/types";

interface Props {
  search: string;
  setSearch: (search: string) => void;
  type: MenuItemType;
  setType: (type: MenuItemType) => void;
  setPlayListId: (type: string) => void;
  playlistId: string;
}

export const count = (
  itemType: MenuItemType,
  searchedItems: MenuContentItem[]
): number => {
  // Adding video apps e.g youtube, to total video count
  if (itemType === MenuItemType.Video) {
    const appVideos = searchedItems.filter(
      (item) =>
        item.type === MenuItemType.Apps && item.appType === MenuItemType.Video
    );
    const videos = searchedItems.filter(
      (item) => item.type === "file" && item.fileType === MenuItemType.Video
    );
    return appVideos.length + videos.length;
  } else if (
    itemType === MenuItemType.Apps ||
    itemType === MenuItemType.Dashboards ||
    itemType === MenuItemType.Links
  ) {
    return searchedItems.filter((item) => item.type === itemType).length;
  } else {
    return searchedItems.filter(
      (item) => item.type === "file" && item.fileType === itemType
    ).length;
  }
};

const Menu: FunctionComponent<Props> = ({
  search,
  setSearch,
  type,
  setType,
  setPlayListId,
  playlistId,
}: Props) => {
  const { currentIndex, searchedItems } = useEmbedMenu();

  return (
    <div className={styles.root} data-testid="main-menu">
      <div className={styles.sidePanel} data-testid="side-panel">
        <SidePanel
          appsCount={count(MenuItemType.Apps, searchedItems)}
          videosCount={count(MenuItemType.Video, searchedItems)}
          imagesCount={count(MenuItemType.Image, searchedItems)}
          documentsCount={count(MenuItemType.Document, searchedItems)}
          dashboardsCount={count(MenuItemType.Dashboards, searchedItems)}
          linksCount={count(MenuItemType.Links, searchedItems)}
          type={type}
          setType={setType}
          setPlayListId={setPlayListId}
          playlistId={playlistId}
        />
      </div>
      <div className={styles.mainPanel} data-testid="main-menu-panel">
        <MainPanel
          currentIndex={currentIndex}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div
        className={styles.closeButtonContainer}
        data-testid="main-menu-actions"
      >
        <MenuActions />
      </div>
    </div>
  );
};

export default Menu;
