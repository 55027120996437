/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLogger } from "./utils/debugLog";
const log = getLogger("storage");

interface Storage {
  getItem(key: string): any;
  setItem(key: string, value: any): void;
  removeItem(key: string): void;
}

type storageType = "localStorage" | "sessionStorage";

export class StorageBase implements Storage {
  private storageRef: Storage;

  constructor(storageRef: Storage) {
    this.storageRef = storageRef;
  }

  static canAccess(storageStr: storageType): boolean {
    if (!storageStr) {
      return false;
    }

    const someStr = Array(50).join("c");
    const key = "__testkey";

    // Accessing local storage in chrome in incognito mode fails, the try/catch handles the error better
    // to allow the screen to display minus this storage feature.
    try {
      const storageObj = {
        localStorage: window.localStorage,
        sessionStorage: window.sessionStorage,
      };

      const storageRef: Storage = storageObj[storageStr];

      storageRef.setItem(key, someStr);
      storageRef.removeItem(key);

      return true;
    } catch (e) {
      log(`Cannot access ${storageStr}`, e);
      return false;
    }
  }

  getItem(key: string): any {
    const item = this.storageRef.getItem(key);
    try {
      if (item) {
        return JSON.parse(item).value;
      }
    } catch (e) {
      log("getItem failed:", e);
    }
    return null;
  }

  setItem(key: string, value: unknown): void {
    try {
      // JSON.stringify throw in case circular
      return this.storageRef.setItem(key, JSON.stringify({ value }));
    } catch (e) {
      log("setItem failed:", e);
    }
  }

  removeItem(key: string): void {
    return this.storageRef.removeItem(key);
  }
}

const noop = (): any => null;
const noopStorage: Storage = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
};

export class LocalStorage extends StorageBase {
  private static instance: Storage;

  constructor() {
    super(window.localStorage);
  }

  static getInstance(): Storage {
    if (!this.instance) {
      if (StorageBase.canAccess("localStorage")) {
        this.instance = new LocalStorage();
      } else {
        this.instance = noopStorage;
      }
    }

    return this.instance;
  }
}

export class SessionStorage extends StorageBase {
  private static instance: Storage;

  constructor() {
    super(window.sessionStorage);
  }

  static getInstance(): Storage {
    if (!this.instance) {
      if (StorageBase.canAccess("sessionStorage")) {
        this.instance = new SessionStorage();
      } else {
        this.instance = noopStorage;
      }
    }

    return this.instance;
  }
}
