/**
 * This function will help when we want to see the logs from somewhere else which is not a browser Ex. SCoS device
 * because it could show like this `[object Object]`
 */
export function errorToStr(err: any): string {
  // add try catch just in case that it could not parse to string
  try {
    return JSON.stringify(err);
  } catch {
    return err;
  }
}
