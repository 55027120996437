import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { PlaybackState } from "../../../../store/playback/types";
import { TimelinesState } from "../../../../store/timelines/types";
import { PlayerState } from "../../../../store/rootReducer";
import { AppsState } from "../../../../store/apps/types";
import { FilesState } from "../../../../store/files/types";
import { LinksState } from "../../../../store/links/types";
import { LayoutsState } from "../../../../store/layouts/types";
import { ScreenState } from "../../../../store/screen/types";
import { ChannelsState } from "../../../../store/channels/types";
import { isEmbedPlayerMode } from "../../../../utils/embedPlayerHelper";
import { useTimelinePlaybackLogs } from "./timelinePlaybackLogs";
import { useSingleContentLogs } from "./singleContentLogs";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { OrganizationState } from "../../../../store/organization/types";
import { PlaylistsState } from "../../../../store/playlists/types";
import { SitesState } from "../../../../store/sites/types";
import { Logger } from "../../../../logger/logger";

export const LoggingContainer = (): ReactElement => {
  const playbackState = useSelector<PlayerState, PlaybackState>(
    (state) => state.playback
  );
  const timelines = useSelector<PlayerState, TimelinesState>(
    (state) => state.timelines
  );
  const apps = useSelector<PlayerState, AppsState>((state) => state.apps);
  const files = useSelector<PlayerState, FilesState>((state) => state.files);
  const links = useSelector<PlayerState, LinksState>((state) => state.links);
  const sites = useSelector<PlayerState, SitesState>((state) => state.sites);
  const layouts = useSelector<PlayerState, LayoutsState>(
    (state) => state.layouts
  );
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const channels = useSelector<PlayerState, ChannelsState>(
    (state) => state.channels
  );
  const organization = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const playlists = useSelector<PlayerState, PlaylistsState>(
    (state) => state.playlists
  );
  const timeOptions = useTimeOptions();

  const platform = isEmbedPlayerMode()
    ? "embed"
    : screen.deviceMeta.devicePlatform;

  useTimelinePlaybackLogs({
    apps,
    files,
    links,
    sites,
    playlists,
    screen,
    playbackState,
    timelines,
    platform,
    channels,
    layouts,
    organization,
  });

  useSingleContentLogs({
    apps,
    files,
    links,
    sites,
    screen,
    platform,
    organization,
  });

  useEffect(() => {
    Logger.setTimeOptions(timeOptions);
  }, [timeOptions]);

  return <></>;
};
