import { TimeOptions } from "./timeManager";
import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { useContext, useMemo } from "react";
import { ScreenContext } from "../modules/viewers/containers/ScreenViewer/context";

export function useTimeOptions(): TimeOptions {
  const timeOffset = useSelector<PlayerState, number>(
    (state) => state.config.timeOffset
  );
  const timelineControlOffset = useSelector<PlayerState, number>(
    (state) => state.playback.controls.timelineOffset
  );
  const timezoneOverride = useContext(ScreenContext).timezoneOverride;

  return useMemo<TimeOptions>(
    () => ({
      timeOffset,
      timezoneOverride,
      timelineControlOffset,
    }),
    [timeOffset, timezoneOverride, timelineControlOffset]
  );
}
