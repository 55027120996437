import React, { FunctionComponent } from "react";
import styles from "./LandingMenu.module.css";
import Logo from "../../../img/logo-default.svg";

interface Props {
  onInitialise: () => void;
  channelName?: string;
}

const LandingMenu: FunctionComponent<Props> = ({
  onInitialise,
  channelName,
}: Props) => {
  const channel =
    channelName && channelName.length > 0 ? channelName : "ScreenCloud";

  return (
    <div data-testid="landing-menu" className={styles.root}>
      <div className={styles.content}>
        <img src={Logo} alt="logo" />
        <h1 data-testid="landing-menu-header">Welcome to {channel} TV</h1>
        <button
          data-testid="initialise-button"
          className={styles.button}
          onClick={onInitialise}
        >
          Launch
        </button>
        <h2>
          Catch up on what&apos;s been happening in the world of {channel},
          <br />
          from company notices to industry wide news.
        </h2>
      </div>
    </div>
  );
};
export default LandingMenu;
