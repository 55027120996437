import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "../../../../store/links/types";
import { PlayerState } from "../../../../store/rootReducer";
import { ElementSize } from "../../../../utils/helpers";
import styles from "./LinkViewer.module.css";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { requestLinkSuccess } from "../../../../store/links/actions";
import { useLinkById } from "../../../../queries";
import { LinkViewerStandard } from "./LinkViewerStandard";
import { LinkViewerCloudContainer } from "./LinkViewerCloud";

interface LinkViewerContainerProps {
  id: string;
  containerSize: ElementSize;
  isRoot?: boolean;
  isPreload: boolean;
}

export const LinkViewerContainer: FunctionComponent<LinkViewerContainerProps> = (
  props: LinkViewerContainerProps
) => {
  const dispatch = useDispatch();

  const link = useSelector<PlayerState, Link | undefined>((state) => {
    return state.links.byId[props.id];
  });
  const linkCloudServiceUrl = useSelector<PlayerState, string>(
    (state) => state.config.linkCloudServiceUrl
  );
  const ref = useRef<HTMLDivElement>(null);

  const [fetchLink, { data }] = useLinkById({
    useCache: false,
    skipCache: true,
    variables: {
      id: props.id,
    },
  });

  useEffect(() => {
    if (data?.linkById) {
      dispatch(requestLinkSuccess(data.linkById));
    }
  }, [data?.linkById, dispatch]);

  useInitialFetch(!!props.isRoot, fetchLink);

  const height = props.containerSize.height ?? ref.current?.offsetHeight;
  const width = props.containerSize.width ?? ref.current?.offsetWidth;

  if (!link || !height || !width) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={styles.linkViewerWrapper}
      data-testid="link-viewer-container"
    >
      {link.linkType === "CLOUD" ? (
        <LinkViewerCloudContainer
          linkCloudServiceUrl={linkCloudServiceUrl}
          link={link}
          height={height}
          width={width}
          isPreload={props.isPreload}
        />
      ) : (
        <LinkViewerStandard url={link.url} name={link.name} />
      )}
    </div>
  );
};
