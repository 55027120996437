import {
  REPLACE_TIMELINE,
  Timeline,
  TimelinesState,
  UpdateTimelineAction,
  UPDATE_TIMELINE,
} from "./types";
import { PlayerAction } from "../storeTypes";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { DEFAULT_CONTENT_PATH, DEFAULT_TIMELINE_SOURCE } from "../../constants";
import { PlayerState } from "../rootReducer";
import { TimelinePlaybackState } from "../playback/types";
import isEqual from "lodash/isEqual";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { REHYDRATE } from "redux-persist";
import { getRootContent } from "../../utils/rootContent";
import { getAllActiveChannelZoneTimelineIds } from "../../utils/channelContent";

export const initialState: TimelinesState = {
  byId: {},
  type: DEFAULT_TIMELINE_SOURCE,
};

export function emptyTimelinesReducer(
  state: TimelinesState = initialState,
  action: PlayerAction
): TimelinesState {
  return state;
}

export function timelinesReducer(
  state: PlayerState,
  action: PlayerAction
): PlayerState {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        const stateWithPayload = {
          ...state,
          ...action.payload,
        };
        const activeTimelineIds = getActiveTimelineIds(stateWithPayload);
        const updatedState = createMissingNewTimelines(
          stateWithPayload,
          activeTimelineIds
        );
        return updatedState;
      }
      return state;
    case REQUEST_CHANNEL_SUCCESS:
    case REQUEST_PLAYLIST_SUCCESS:
    case REQUEST_SCREEN_SUCCESS: {
      const activeTimelineIds = getActiveTimelineIds(state);
      let updatedState = cleanUpUnusedTimelines(state, activeTimelineIds);
      updatedState = createMissingNewTimelines(updatedState, activeTimelineIds);
      return updatedState;
    }

    case UPDATE_TIMELINE: {
      return handleUpdateTimeline(state, action);
    }

    case REPLACE_TIMELINE: {
      const targetTimeline = selectTimeline(state, action.payload.id);
      if (!isEqual(targetTimeline.items, action.payload.items)) {
        return updateTimeline(state, action.payload.id, {
          items: action.payload.items,
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export function handleUpdateTimeline(
  state: PlayerState,
  action: UpdateTimelineAction
): PlayerState {
  const { items, cleanupAmount, id } = action.payload;

  const targetTimeline = selectTimeline(state, id);
  let updatedItems = targetTimeline.items.slice();

  if (cleanupAmount !== undefined && cleanupAmount > 0) {
    updatedItems = updatedItems.slice(cleanupAmount);
  }

  const updateStartTimestamp = items[0].startTimestamp;
  let appendStartIndex = updatedItems.findIndex((item, index) => {
    return (
      (index === 0 && item.startTimestamp >= updateStartTimestamp) ||
      (item.startTimestamp >= updateStartTimestamp &&
        targetTimeline.items[index - 1].startTimestamp < updateStartTimestamp)
    );
  });

  if (appendStartIndex === -1) {
    appendStartIndex = updatedItems.length;
  }

  updatedItems.splice(appendStartIndex);
  updatedItems = updatedItems.concat(items);

  if (!isEqual(targetTimeline.items, updatedItems)) {
    return updateTimeline(state, targetTimeline.id, { items: updatedItems });
  } else {
    return state;
  }
}

export function updateTimeline(
  state: PlayerState,
  timelineId: string,
  updates: Partial<Timeline>
): PlayerState {
  return {
    ...state,
    timelines: {
      ...state.timelines,
      byId: {
        ...state.timelines.byId,
        [timelineId]: {
          ...state.timelines.byId[timelineId],
          ...updates,
        },
      },
    },
  };
}

export function selectTimeline(
  playerState: PlayerState,
  timelineId: string
): Timeline {
  return playerState.timelines.byId[timelineId];
}

export function cleanUpUnusedTimelines(
  state: PlayerState,
  activeTimelineIds: string[]
): PlayerState {
  let updatedState = state;
  let updatedTimelines: { [key: string]: Timeline } = state.timelines.byId;
  let updatedTimelinePlaybackState: { [key: string]: TimelinePlaybackState } =
    state.playback.timelines;

  Object.keys(updatedTimelines).forEach((timelineId, index) => {
    if (!activeTimelineIds.includes(timelineId)) {
      if (updatedTimelines === state.timelines.byId) {
        updatedTimelines = { ...state.timelines.byId };
      }
      delete updatedTimelines[timelineId];
    }
  });

  if (updatedTimelines !== state.timelines.byId) {
    updatedState = {
      ...updatedState,
      timelines: {
        ...updatedState.timelines,
        byId: updatedTimelines,
      },
    };
  }

  Object.keys(updatedTimelinePlaybackState).forEach((timelineId) => {
    if (!activeTimelineIds.includes(timelineId)) {
      if (updatedTimelinePlaybackState === state.playback.timelines) {
        updatedTimelinePlaybackState = { ...state.playback.timelines };
      }
      delete updatedTimelinePlaybackState[timelineId];
    }
  });

  if (updatedTimelinePlaybackState !== state.playback.timelines) {
    updatedState = {
      ...updatedState,
      playback: {
        ...updatedState.playback,
        timelines: updatedTimelinePlaybackState,
      },
    };
  }

  return updatedState;
}

/**
 * Checks if there is a timeline object for each active timeline id. Creates an empty timeline object if missing.
 */
export function createMissingNewTimelines(
  state: PlayerState,
  activeTimelineIds: string[]
): PlayerState {
  let updatedTimelines = state.timelines.byId;
  let updatedTimelinePlaybackState = state.playback.timelines;

  activeTimelineIds.forEach((timelineId) => {
    if (!updatedTimelines[timelineId]) {
      if (updatedTimelines === state.timelines.byId) {
        updatedTimelines = { ...state.timelines.byId };
      }

      updatedTimelines[timelineId] = {
        items: [],
        id: timelineId,
      };
    }

    if (!updatedTimelinePlaybackState[timelineId]) {
      if (updatedTimelinePlaybackState === state.playback.timelines) {
        updatedTimelinePlaybackState = { ...state.playback.timelines };
      }

      updatedTimelinePlaybackState[timelineId] = {
        activeIndex: undefined,
        activeScreenTimeMs: undefined,
        preloadIndex: undefined,
        id: timelineId,
        nextChunkRequestTimestamp: undefined,
      };
    }
  });

  if (
    updatedTimelines !== state.timelines.byId ||
    updatedTimelinePlaybackState !== state.playback.timelines
  ) {
    return {
      ...state,
      timelines: {
        ...state.timelines,
        byId: updatedTimelines,
      },
      playback: {
        ...state.playback,
        timelines: updatedTimelinePlaybackState,
      },
    };
  } else {
    return state;
  }
}

/**
 * Returns list of active timeline ids, based on what is set to be shown on screen
 */
export function getActiveTimelineIds(state: PlayerState): string[] {
  const rootContent = getRootContent(state.config.contentConfig);

  if (rootContent.type === "screen") {
    return getActiveTimelineIdsForScreen(rootContent.id, state);
  }

  if (rootContent.type === "channel") {
    return getActiveTimelineIdsForChannel(rootContent.id, state);
  }

  if (rootContent.type === "playlist") {
    return getActiveTimelineIdsForPlaylist(rootContent.id, state);
  }

  return [];
}

export function getActiveTimelineIdsForScreen(
  screenId: string,
  playerState: PlayerState
): string[] {
  if (
    (screenId !== playerState.screen.id && screenId !== DEFAULT_CONTENT_PATH) ||
    !playerState.screen.activeContentItem
  ) {
    return [];
  }

  if (playerState.screen.activeContentItem.type === "channel") {
    return getActiveTimelineIdsForChannel(
      playerState.screen.activeContentItem.id,
      playerState
    );
  }

  if (playerState.screen.activeContentItem.type === "playlist") {
    return getActiveTimelineIdsForPlaylist(
      playerState.screen.activeContentItem.id,
      playerState
    );
  }

  return [];
}

export function getActiveTimelineIdsForChannel(
  channelId: string,
  playerState: PlayerState
): string[] {
  const channel = playerState.channels.byId[channelId];
  if (!channel) {
    return [];
  }
  const layout = playerState.layouts.byId[channel.layoutId];

  return getAllActiveChannelZoneTimelineIds(channel, layout);
}

export function getActiveTimelineIdsForPlaylist(
  playlistId: string,
  playerState: PlayerState
): string[] {
  // todo: this should use a getter for better future maintenance
  return [playlistId];
}
