import { useCallback, useState } from "react";
import { Logger } from "../../../../logger/logger";

const log = new Logger("getTimeOffset");

interface TimeOffset {
  hasError: boolean;
  getTimeOffset: () => void;
  timeOffset: number | undefined;
}

export const useTimeOffset = (timeServerUrl: string): TimeOffset => {
  const [hasError, setHasError] = useState(false);
  const [timeOffset, setTimeOffset] = useState<number | undefined>(undefined);
  const getTimeOffset = useCallback(async () => {
    try {
      const requestStartTime = new Date().getTime();
      const response = await window.fetch(
        `${timeServerUrl}?nc=${new Date().getTime()}`
      );

      const requestEndTime = new Date().getTime();
      const timeServerResponse: number = await response.json();
      const responseTravelTime = Math.round(
        (requestEndTime - requestStartTime) / 2
      );

      let timeOffset =
        timeServerResponse - responseTravelTime - requestStartTime;
      if (timeOffset < 200) {
        timeOffset = 0;
      }

      setHasError(false);
      setTimeOffset(timeOffset);
    } catch (err) {
      log.warn(`Failed to fetch ${timeServerUrl}: ${err}`, {
        timeServerUrl: timeServerUrl,
      });
      setHasError(true);
      setTimeOffset(undefined);
    }
  }, [timeServerUrl]);

  return {
    hasError,
    getTimeOffset,
    timeOffset,
  };
};
