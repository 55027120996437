import React, { FunctionComponent } from "react";
import styles from "./MenuActions.module.css";
import CloseIcon from "../../../../img/close.svg";
import LiveIcon from "../../../../img/live.svg";
import FullscreenOpenIcon from "../../../../img/fullscreen-open.svg";
import FullscreenCloseIcon from "../../../../img/fullscreen-close.svg";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";

const MenuActions: FunctionComponent = () => {
  const {
    items,
    filteredItems,
    currentIndex,
    onClose,
    returnToLiveMode,
    liveMode,
    toggleFullscreen,
    isFullscreen,
  } = useEmbedMenu();

  const currentItem = items[currentIndex];

  return (
    <div className={styles.root} data-testid="menu-actions">
      <div className={styles.topRow}>
        {!liveMode && (
          <button
            className={styles.liveButton}
            onClick={() => {
              returnToLiveMode();
              onClose();
            }}
          >
            <img src={LiveIcon} alt="Live" />
            <span>Switch to Live playback</span>
          </button>
        )}
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.column}>
          {currentItem.type !== "void" ? (
            liveMode ? (
              <div className={styles.liveMessage} data-testid="livemode-action">
                <div />
                <p>
                  <span>LIVE</span>
                  <span>{currentItem.name}</span>
                </p>
              </div>
            ) : (
              <div className={styles.pausedMessage} data-testid="paused-action">
                <p>
                  <span>PAUSED:</span>
                  <span
                    className={
                      filteredItems.length === 0 ? styles.disabled : ""
                    }
                  >
                    {currentItem.name}
                  </span>
                </p>
              </div>
            )
          ) : null}
        </div>
        <div className={styles.column} style={{ justifyContent: "center" }}>
          <button
            data-testid="onclose-action"
            className={
              filteredItems.length === 0 && !liveMode
                ? styles.disabled
                : styles.menuIcon
            }
            disabled={filteredItems.length === 0 && !liveMode}
            onClick={() => {
              if (filteredItems.length > 0 || liveMode) {
                onClose();
              }
            }}
          >
            <img className={styles.icon} src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className={styles.column} style={{ justifyContent: "flex-end" }}>
          <button
            data-testid="toggle-fullscreen-action"
            className={styles.menuIcon}
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <img
                className={styles.icon}
                src={FullscreenCloseIcon}
                alt="fullscreen exit"
              />
            ) : (
              <img
                className={styles.icon}
                src={FullscreenOpenIcon}
                alt="fullscreen open"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuActions;
