import { LayoutFragment } from "../../queries";

export interface LayoutsState {
  byId: {
    [id: string]: Layout;
  };
}

export interface Zone {
  contentListId: string;
  id: string;
  name: string;
}

export interface Layout {
  css: string;
  height: number;
  id: string;
  isFlexible: boolean;
  isScalable: boolean;
  name: string;
  width: number;
  zones: Zone[];
}

export type LayoutAction = RequestLayoutSuccessAction;

export const REQUEST_LAYOUT_SUCCESS = "REQUEST_LAYOUT_SUCCESS";

export interface RequestLayoutSuccessAction {
  type: typeof REQUEST_LAYOUT_SUCCESS;
  payload: {
    layouts: { [id: string]: LayoutFragment };
  };
}
