import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./Alert.module.css";

export interface AlertProps {
  message: string;
  animationInterval?: number;
}

interface PositionState {
  top?: number;
  left?: number;
}

const getAvailableScreenSize = (
  alertRef: React.RefObject<HTMLDivElement>
): number[] => {
  const { current } = alertRef;
  if (current && current.parentElement) {
    const maxHeight = current.parentElement.offsetHeight - current.offsetHeight;
    const maxWidth = current.parentElement.offsetWidth - current.offsetWidth;
    return [maxHeight, maxWidth];
  } else {
    return [0, 0];
  }
};

export const Alert = ({
  message,
  animationInterval = 10,
}: AlertProps): ReactElement => {
  const [position, setPositionState] = useState<PositionState>({
    top: undefined,
    left: undefined,
  });
  const alertRef = useRef<HTMLDivElement>(null);

  const updateBoxPosition = useCallback(() => {
    const screenDimension = getAvailableScreenSize(alertRef);
    const top = Math.round(Math.random() * screenDimension[0]);
    const left = Math.round(Math.random() * screenDimension[1]);
    setPositionState({
      left,
      top,
    });
  }, []);

  useEffect(() => {
    // bounce this box every X seconds, the default is 10 seconds
    const updateBoxPositionInterval = window.setInterval(
      updateBoxPosition,
      animationInterval * 1000
    );
    return (): void => {
      window.clearInterval(updateBoxPositionInterval);
    };
  }, [updateBoxPosition, animationInterval]);

  return (
    <div
      data-testid="alert"
      ref={alertRef}
      className={styles.container}
      style={{
        left: position.left ? `${position.left}px` : "50%",
        top: position.top ? `${position.top}px` : "50%",
        transform:
          !position.top || !position.left ? "translate(-50%,-50%)" : undefined,
      }}
    >
      <div className={styles.message}>{message}</div>
    </div>
  );
};
