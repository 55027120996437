import { REQUEST_LAYOUT_SUCCESS, RequestLayoutSuccessAction } from "./types";
import { LayoutFragment } from "../../queries";

export const requestLayoutSuccess = (
  layout: LayoutFragment
): RequestLayoutSuccessAction => {
  return {
    type: REQUEST_LAYOUT_SUCCESS,
    payload: {
      layouts: { [layout.id]: layout },
    },
  };
};
