import {
  FileFragment,
  PlaylistFragment,
  SpaceFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { Modify } from "../storeTypes";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedOrgFragment } from "../organization/types";

export const REQUEST_PLAYLIST_SUCCESS = "REQUEST_PLAYLIST_SUCCESS";
export const REQUEST_PLAYLIST = "REQUEST_PLAYLIST";

export interface PlaylistsState {
  byId: {
    [id: string]: Playlist;
  };
}

export interface Playlist {
  name: string;
  id: string;
}

// Export all action types here.
export type PlaylistAction =
  | RequestPlaylistAction
  | RequestPlaylistSuccessAction;

export interface RequestPlaylistAction {
  type: typeof REQUEST_PLAYLIST;
}

export interface RequestPlaylistSuccessAction {
  type: typeof REQUEST_PLAYLIST_SUCCESS;
  payload: {
    apps: {
      [id: string]: NormalizedAppInstanceFragment;
    };
    files: {
      [id: string]: FileFragment;
    };
    links: {
      [id: string]: NormalizedLinkFragment;
    };
    playlists: {
      [id: string]: NormalizedPlaylistFragment;
    };
    themes: {
      [id: string]: ThemeFragment;
    };
    organization: NormalizedOrgFragment | null | undefined;
    space: SpaceFragment | null | undefined;
    sites: {
      [id: string]: NormalizedSiteFragment;
    };
  };
}

export type NormalizedPlaylistFragment = Modify<
  PlaylistFragment,
  {
    appInstancesByPlaylistId: {
      nodes: string[];
    };
    filesByPlaylistId: {
      nodes: string[];
    };
    linksByPlaylistId: {
      nodes: string[];
    };
    sitesByPlaylistId: {
      nodes: string[];
    };
  }
>;
