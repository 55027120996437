import { normalize } from "normalizr";
import { RequestChannelSuccessAction, REQUEST_CHANNEL_SUCCESS } from "./types";
import { ChannelFragment, SpaceFragment } from "../../queries";
import {
  channelSchema,
  NormalizedChannelEntities,
} from "../normalizationSchemas";

export function requestChannelSuccess(
  channelById: ChannelFragment & {
    spaceBySpaceId?: SpaceFragment | null | undefined;
  }
): RequestChannelSuccessAction {
  const space = channelById.spaceBySpaceId;

  const normalizedData = normalize<unknown, NormalizedChannelEntities>(
    channelById,
    channelSchema
  );

  const organization = normalizedData.entities.orgs
    ? Object.values(normalizedData.entities.orgs)[0]
    : undefined;

  return {
    type: REQUEST_CHANNEL_SUCCESS,
    payload: {
      apps: normalizedData.entities.apps || {},
      channels: normalizedData.entities.channels || {},
      files: normalizedData.entities.files || {},
      layouts: normalizedData.entities.layouts || {},
      links: normalizedData.entities.links || {},
      organization,
      playlists: normalizedData.entities.playlists || {},
      themes: normalizedData.entities.themes || {},
      space,
      sites: normalizedData.entities.sites || {},
    },
  };
}
