import { PlayerManager } from "@screencloud/persistent-video-player";

let _videoPlayerManager: PlayerManager;

declare global {
  interface Window {
    persie: {
      playerManager?: PlayerManager;
    };
  }
}

function getInstance(): PlayerManager {
  if (!_videoPlayerManager) {
    _videoPlayerManager = new PlayerManager();
    // This is needed to be able to debug video player
    window.persie = {
      playerManager: _videoPlayerManager,
    };
  }
  return _videoPlayerManager;
}

const videoPlayerManager = getInstance();
export { videoPlayerManager };
