import { createTransform } from "redux-persist";
import { produce } from "immer";

// example: { byId: {...} }
interface State {
  [
    key: string
  ]: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}

const checkAndReplace = (state: State) => {
  Object.entries(state).forEach(([key, value]) => {
    // map Infinity when caching
    if (value === Infinity) {
      state[key] = "__Infinity__";
    }
    // map "__Infinity__" when rehydrating
    else if (value === "__Infinity__") {
      state[key] = Infinity;
    }

    // map undefined when caching
    else if (value === undefined) {
      state[key] = "__undefined__";
    }
    // map "__undefined__" when rehydrating
    else if (value === "__undefined__") {
      state[key] = undefined;
    }

    // check within nested objects
    else if (!!value && typeof value === "object") {
      checkAndReplace(value);
    } else if (Array.isArray(value)) {
      value.forEach(checkAndReplace);
    }
  });
};

const mapState = (state: State) => {
  return produce(state, (draft) => {
    checkAndReplace(draft);
  });
};

const ValuesTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: State): State => {
    return mapState(inboundState);
  },
  // transform state being rehydrated
  (outboundState: State): State => {
    return mapState(outboundState);
  }
);

export default ValuesTransform;
