import { Modify } from "../storeTypes";
import {
  AppInstanceFragment,
  FileFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedOrgFragment } from "../organization/types";

export interface AppsState {
  byId: {
    [id: string]: App;
  };
}

export interface App {
  appInstallId: string;
  config: AppConfig;
  fileIds: string[]; // TODO - These will map to normalized IDs in files reducer.
  fileByThumbnailFileId?: FileFragment;
  id: string;
  name: string;
  // state: AppInstanceState; TODO - Should state be normalized in future?
  version: string;
  viewerUrl: string;
  iconUrl?: string;
  slug: string | null;
}

export type NormalizedAppInstanceFragment = Modify<
  AppInstanceFragment,
  {
    filesByAppInstanceId: {
      nodes: string[];
    };
  }
>;

export interface AppConfig {
  [
    key: string
  ]: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}

export interface AppInstanceState {
  [
    key: string
  ]: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}

// Action types
export const REQUEST_APP = "REQUEST_APP";
export const REQUEST_APP_SUCCESS = "REQUEST_APP_SUCCESS";
export const REMOVE_APP_INSTANCE = "REMOVE_APP_INSTANCE";

// Export all action types here.
export type AppAction =
  | RequestAppAction
  | RequestAppSuccessAction
  | RemoveAppInstanceAction;

export interface RequestAppAction {
  type: typeof REQUEST_APP;
}

export interface RequestAppSuccessAction {
  type: typeof REQUEST_APP_SUCCESS;
  payload: {
    organization: NormalizedOrgFragment | null | undefined;
    apps: {
      [id: string]: NormalizedAppInstanceFragment;
    };
    files: {
      [id: string]: FileFragment;
    };
    orgs: {
      [id: string]: NormalizedOrgFragment;
    };
    themes: {
      [id: string]: ThemeFragment;
    };
  };
}

export interface RemoveAppInstanceAction {
  type: typeof REMOVE_APP_INSTANCE;
  payload: {
    id: string;
  };
}
