export type WeekDay = "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat";

export const WEEK_DAYS: WeekDay[] = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
];
