import { SitesState } from "./../../store/sites/types";
import { MenuContentItem } from "./types";
import { ContentItemLeaf } from "./../../store/contentLists/types";
import { TimelineItem } from "./../../store/timelines/types";
import { LinksState } from "./../../store/links/types";
import { FilesState } from "./../../store/files/types";
import { AppsState } from "./../../store/apps/types";
import { MenuItemType } from "../../modules/menu/MainMenu/types";
import { FileProcessingClientInterface } from "../../types/filesClient";
import DashboardsTileIcon from "../../img/dashboards-tile.svg";

const thumbnailOptions = {
  size: { w: 204, h: 114 },
};

export const mapMenuContentItems = (
  fileProcessingClient: FileProcessingClientInterface | undefined,
  contentItems: ContentItemLeaf[],
  apps: AppsState,
  files: FilesState,
  links: LinksState,
  sites: SitesState
): MenuContentItem[] => {
  return contentItems.map((item) => {
    if (item.type === "app") {
      const app = apps.byId[item.id];
      if (app.slug === "canvas" && app.fileByThumbnailFileId) {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.fileByThumbnailFileId.source || app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
        };
      }
      // treat video apps as a video type
      else if (
        app.slug === "youtube" ||
        app.slug === "youtube-live" ||
        app.slug === "vimeo" ||
        app.slug === "live-news"
      ) {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
          appType: "video",
        };
      } else {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
        };
      }
    } else if (item.type === "file") {
      const file = files.byId[item.id];
      if (file.type === "image") {
        return {
          id: file.id,
          name: file.name,
          // name: "Image",
          thumbnail: fileProcessingClient?.getImgixUrl(file.urlKey, {
            ...thumbnailOptions,
            sizingType: "fill",
          }),
          type: "file",
          fileType: "image",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
        };
      } else if (file.type === "video") {
        return {
          id: file.id,
          name: file.name,
          // name: "Video",
          thumbnail: fileProcessingClient?.getImgixUrl(file.thumbnail || "", {
            ...thumbnailOptions,
            sizingType: "fill",
          }),
          type: "file",
          fileType: "video",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
        };
      } else if (file.type === "document") {
        return {
          id: file.id,
          name: file.name,
          // name: "Document",
          thumbnail: fileProcessingClient?.getImgixUrl(
            file.images[0].urlKey || "",
            { ...thumbnailOptions, sizingType: "fill" }
          ),
          type: "file",
          fileType: "document",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: item.parent ? item.parent.id : "",
        };
      }
    } else if (item.type === "link") {
      const link = links.byId[item.id];
      const file = files.byId[link.fileId || ""];
      let thumbnail = undefined;
      if (file && file.type === "image") {
        thumbnail = fileProcessingClient?.getImgixUrl(file.urlKey, {
          ...thumbnailOptions,
          sizingType: "fill",
        });
      }
      return {
        id: link.id,
        type: "link",
        name: link.name,
        thumbnail,
        fullDurationMs: Infinity,
        listId: item.listId,
        playlistId: item.parent ? item.parent.id : "",
      };
    } else if (item.type === "site") {
      const site = sites.byId[item.id];
      const file = files.byId[site.fileByThumbnailId || ""];
      let thumbnail = undefined;
      if (file?.type === "image") {
        thumbnail = fileProcessingClient?.getImgixUrl(file.urlKey, {
          ...thumbnailOptions,
          sizingType: "fill",
        });
      }
      if (thumbnail === undefined) {
        thumbnail = DashboardsTileIcon;
      }

      return {
        id: site.id,
        type: "site",
        name: site.name,
        thumbnail,
        fullDurationMs: Infinity,
        listId: item.listId,
        playlistId: item.parent ? item.parent.id : "",
      };
    }
    return {
      type: "void",
    };
  });
};

export const getCurrentItemId = (
  timelineItems: TimelineItem[],
  currentActiveIndex?: number
): string | undefined => {
  if (currentActiveIndex !== undefined) {
    const item = timelineItems[currentActiveIndex];
    if (item && item.type !== "void") {
      return item.listId;
    }
  }
  return undefined;
};

const filterItemsBySearch = (
  search: string,
  items: MenuContentItem[]
): MenuContentItem[] => {
  return items.filter((item) => {
    return (
      item.type !== "void" &&
      (search.length < 1 ||
        item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    );
  });
};

const filterItemsByType = (
  menuType: MenuItemType,
  items: MenuContentItem[]
): MenuContentItem[] => {
  let itemsByActiveType: MenuContentItem[] = [];
  if (menuType === MenuItemType.All) {
    itemsByActiveType = items;
  } else {
    items.forEach((item: MenuContentItem) => {
      if (item.type === "file") {
        if (
          (menuType === MenuItemType.Video &&
            item.fileType === MenuItemType.Video) ||
          (menuType === MenuItemType.Image &&
            item.fileType === MenuItemType.Image) ||
          (menuType === MenuItemType.Document &&
            item.fileType === MenuItemType.Document)
        ) {
          itemsByActiveType.push(item);
        }
      }
      // if the app item contains appType = video, add to video content list
      if (
        item.type === "app" &&
        menuType === MenuItemType.Video &&
        item.appType === "video"
      ) {
        itemsByActiveType.push(item);
      } else if (item.type === "app" && menuType === MenuItemType.Apps) {
        itemsByActiveType.push(item);
      } else if (item.type === "site" && menuType === MenuItemType.Dashboards) {
        itemsByActiveType.push(item);
      } else if (item.type === "link" && menuType === MenuItemType.Links) {
        itemsByActiveType.push(item);
      }
    });
  }
  return itemsByActiveType;
};

export const searchItems = (
  items: MenuContentItem[],
  search: string
): MenuContentItem[] => {
  return filterItemsBySearch(search, items);
};

export const filterByPlaylistItems = (
  items: any[],
  playlistId: string
): MenuContentItem[] => {
  return items.filter((item) => item.playlistId === playlistId);
};

export const filterItems = (
  items: MenuContentItem[],
  type: MenuItemType,
  playlistId: string
): MenuContentItem[] => {
  if (type === "playlist") {
    return filterByPlaylistItems(items, playlistId);
  }
  return filterItemsByType(type, items);
};
