import { useEffect, useState } from "react";
import { HOUR_DURATION_MS } from "../constants";

const MAX_TIMEOUT_MS = HOUR_DURATION_MS / 6;

export function useInitialFetch(
  shouldFetch: boolean,
  fetchCallback: () => Promise<{ error?: unknown }>
): void {
  const [isInitialFetchFinished, setIsInitialFetchFinished] = useState<boolean>(
    false
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [attemptCount, setAttemptCount] = useState<number>(0);

  useEffect(() => {
    let isDismissed = false;

    if (shouldFetch && !isInitialFetchFinished) {
      fetchCallback().then(({ error }) => {
        if (!isDismissed) {
          if (!error) {
            setIsInitialFetchFinished(true);
          } else {
            setHasError(true);
          }
        }
      });
    }

    return (): void => {
      isDismissed = true;
    };
  }, [isInitialFetchFinished, fetchCallback, shouldFetch]);

  useEffect(() => {
    if (hasError && shouldFetch && !isInitialFetchFinished) {
      let isDismissed = false;
      const exponentialTimeoutMs = Math.pow(2, attemptCount) * 1000;
      const timeoutMs =
        exponentialTimeoutMs > MAX_TIMEOUT_MS
          ? MAX_TIMEOUT_MS
          : exponentialTimeoutMs;

      const retryTimeout = window.setTimeout(() => {
        fetchCallback().then((response) => {
          if (!isDismissed) {
            if (response.error) {
              setAttemptCount(attemptCount + 1);
            } else {
              setIsInitialFetchFinished(false);
              setHasError(false);
            }
          }
        });
      }, timeoutMs);

      return (): void => {
        window.clearTimeout(retryTimeout);
        isDismissed = true;
      };
    }
  }, [
    hasError,
    isInitialFetchFinished,
    attemptCount,
    shouldFetch,
    fetchCallback,
  ]);
}
