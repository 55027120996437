import { normalize } from "normalizr";
import {
  RequestPlaylistSuccessAction,
  REQUEST_PLAYLIST_SUCCESS,
} from "./types";
import { PlaylistFragment, SpaceFragment } from "../../queries";
import {
  NormalizedPlaylistEntities,
  playlistSchema,
} from "../normalizationSchemas";

export function requestPlaylistSuccess(
  playlistById: PlaylistFragment & {
    spaceBySpaceId?: SpaceFragment | null | undefined;
  }
): RequestPlaylistSuccessAction {
  const normalizedData = normalize<unknown, NormalizedPlaylistEntities>(
    playlistById,
    playlistSchema
  );

  const organization = normalizedData.entities.orgs
    ? Object.values(normalizedData.entities.orgs)[0]
    : undefined;
  const space = playlistById.spaceBySpaceId;

  return {
    type: REQUEST_PLAYLIST_SUCCESS,
    payload: {
      apps: normalizedData.entities.apps || {},
      files: normalizedData.entities.files || {},
      links: normalizedData.entities.links || {},
      playlists: normalizedData.entities.playlists || {},
      themes: normalizedData.entities.themes || {},
      organization,
      space,
      sites: normalizedData.entities.sites || {},
    },
  };
}
