import { ContentGenericType } from "../../../../types/content";
import { App, AppsState } from "../../../../store/apps/types";
import { PlayerFile, FilesState } from "../../../../store/files/types";
import { Link, LinksState } from "../../../../store/links/types";
import { Site, SitesState } from "../../../../store/sites/types";

export const getContentContext = (
  id: string,
  type: ContentGenericType | "void",
  apps: AppsState,
  files: FilesState,
  links: LinksState,
  sites: SitesState
): Record<string, string> => {
  switch (type) {
    case "app":
      const app: App | undefined = apps.byId[id];
      return {
        name: app?.name,
        instanceId: app?.id,
        appViewerUrl: app?.viewerUrl,
      };
    case "file":
      const file: PlayerFile | undefined = files.byId[id];
      return {
        name: file?.name,
      };
    case "link":
      const link: Link | undefined = links.byId[id];
      return {
        name: link?.name,
      };
    case "site":
      const site: Site | undefined = sites.byId[id];
      return {
        name: site?.name,
      };
  }
  return {};
};
