import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { PlaybackState } from "../store/playback/types";
import { useEffect, useMemo, useState } from "react";
import { TimelinesState } from "../store/timelines/types";
import { App, AppsState } from "../store/apps/types";
import {
  ContentItemLeaf,
  ContentListsState,
} from "../store/contentLists/types";
import { FilesState } from "../store/files/types";
import { useTimeOptions } from "./useTimeOptions";
import { playbackNowTimestamp } from "./timeManager";
import {
  parseContentListId,
  parseLocalTimelineId,
} from "../store/contentLists/utils";

const mainZoneId = "zone1"; // is ok to hardcode?

/**
 * Checks if an app involves video content playback
 */
function isVideoApp(app: App): boolean {
  return (
    app.slug !== null &&
    (app.slug.includes("youtube") || app.slug.includes("vimeo"))
  );
}

/**
 * Determines if the player should load a live update at this particular state and time.
 *
 * Main idea behind this: if there is video content in the main zone playing, then it should not be interrupted by
 * any updates. If the video in the main zone has just started (first 1 minute) - live updates still allowed.
 */
export function useShouldHoldLiveUpdates(): boolean {
  const timeOptions = useTimeOptions();
  const playbackState = useSelector<PlayerState, PlaybackState>(
    (state) => state.playback
  );
  const timelines = useSelector<PlayerState, TimelinesState>(
    (state) => state.timelines
  );
  const contentLists = useSelector<PlayerState, ContentListsState>(
    (state) => state.contentLists
  );
  const activeTimelineIds = useMemo(
    () => Object.keys(playbackState.timelines),
    [playbackState.timelines]
  );
  const apps = useSelector<PlayerState, AppsState>((state) => state.apps);
  const files = useSelector<PlayerState, FilesState>((state) => state.files);

  const mainZoneTimelineId = useMemo(() => {
    return activeTimelineIds.find((timelineId) => {
      const parsedContentListId = parseContentListId(
        parseLocalTimelineId(timelineId).sourceContentListId
      );
      return (
        parsedContentListId.zoneId === mainZoneId ||
        (activeTimelineIds.length === 1 &&
          parsedContentListId.zoneId === undefined)
      );
    });
  }, [activeTimelineIds]);

  const mainZoneActiveIndex =
    mainZoneTimelineId !== undefined
      ? playbackState.timelines[mainZoneTimelineId]?.activeIndex
      : undefined;

  const activeZoneTimelineItem =
    mainZoneTimelineId && mainZoneActiveIndex !== undefined
      ? timelines.byId[mainZoneTimelineId]?.items[mainZoneActiveIndex]
      : undefined;

  const activeMainZoneContentItem = useMemo<ContentItemLeaf | undefined>(() => {
    if (!mainZoneTimelineId || mainZoneActiveIndex === undefined) {
      return undefined;
    }
    const activeTimelineItem =
      timelines.byId[mainZoneTimelineId]?.items[mainZoneActiveIndex];
    const contentItem =
      activeTimelineItem.type !== "void"
        ? contentLists.byId[mainZoneTimelineId]?.items.find(
            (contentItem) => contentItem.listId === activeTimelineItem.listId
          )
        : undefined;
    return contentItem;
  }, [mainZoneTimelineId, timelines, contentLists.byId, mainZoneActiveIndex]);

  const isVideoContent = useMemo<boolean>(() => {
    if (activeMainZoneContentItem === undefined) {
      return false;
    }
    if (activeMainZoneContentItem.type === "file") {
      const targetFile = files.byId[activeMainZoneContentItem.id];
      return targetFile?.type === "video";
    }
    if (activeMainZoneContentItem.type === "app") {
      const targetApp = apps.byId[activeMainZoneContentItem.id];
      return targetApp && isVideoApp(targetApp);
    }
    return false;
  }, [activeMainZoneContentItem, apps.byId, files.byId]);

  const [isBeginningOfVideoPlayback, setIsBeginningOfVideoPlayback] = useState<
    boolean
  >(false);
  const [
    isVideoPlayingLongerThanItsLength,
    setIsVideoPlayingLongerThanItsLength,
  ] = useState(false);

  useEffect(() => {
    // in the very beginning of the video, we don't block live updates. This is to ensure the updates come through
    //  once currently active video is over
    const timeframeMs = 60000;
    let timeout: number | undefined;

    if (isVideoContent && activeZoneTimelineItem) {
      const now = playbackNowTimestamp(timeOptions);
      const timeDiff = now - activeZoneTimelineItem.startTimestamp;
      if (timeDiff < timeframeMs) {
        if (!isBeginningOfVideoPlayback) {
          setIsBeginningOfVideoPlayback(true);
        }
        timeout = window.setTimeout(() => {
          setIsBeginningOfVideoPlayback(false);
        }, activeZoneTimelineItem.startTimestamp + timeframeMs - now);
      }
    } else {
      if (isBeginningOfVideoPlayback) {
        setIsBeginningOfVideoPlayback(false);
      }
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [
    activeMainZoneContentItem,
    activeZoneTimelineItem,
    isVideoContent,
    timeOptions,
    isBeginningOfVideoPlayback,
    apps.byId,
    contentLists.byId,
  ]);

  useEffect(() => {
    // if video is playing more than 1 loop, after first loop, we don't want to hold the live update anymore
    let timeout: number | undefined;

    if (isVideoContent && activeZoneTimelineItem) {
      const now = playbackNowTimestamp(timeOptions);
      const timeDiff = now - activeZoneTimelineItem.startTimestamp;

      if (timeDiff <= activeZoneTimelineItem.fullDurationMs) {
        if (!isVideoPlayingLongerThanItsLength) {
          setIsVideoPlayingLongerThanItsLength(false);
        }
        timeout = window.setTimeout(() => {
          setIsVideoPlayingLongerThanItsLength(true);
        }, activeZoneTimelineItem.startTimestamp + activeZoneTimelineItem.fullDurationMs - now);
      } else {
        setIsVideoPlayingLongerThanItsLength(true);
      }
    } else {
      if (isVideoPlayingLongerThanItsLength) {
        setIsVideoPlayingLongerThanItsLength(false);
      }
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [
    activeZoneTimelineItem,
    isVideoContent,
    isVideoPlayingLongerThanItsLength,
    timeOptions,
  ]);

  if (activeZoneTimelineItem?.isInfinite) {
    // If this is an infinite video item, we won't be able to tell, when does the current video loop end.
    // So we're going to load any live update right away as a simplification.
    return false;
  }

  return (
    isVideoContent &&
    !isBeginningOfVideoPlayback &&
    !isVideoPlayingLongerThanItsLength
  );
}
