import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import {
  Channel,
  ChannelsState,
  NormalizedChannelFragment,
  REQUEST_CHANNEL_SUCCESS,
} from "./types";
import { HIDDEN_ZONE_ID } from "../../constants";
import isEqual from "lodash/isEqual";

/**
 * Data about the available app instances.
 */
const initialState: ChannelsState = {
  byId: {},
};

export function channelsReducer(
  state = initialState,
  action: PlayerAction
): ChannelsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const channels = action.payload.channels;
        const channelIds = Object.keys(channels);
        channelIds.forEach((id) => {
          if (channels[id]) {
            const newChannel = mapGqlChannelToPlayerChannel(channels[id]);
            if (!isEqual(newChannel, state.byId[id])) {
              draft.byId[id] = newChannel;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlChannelToPlayerChannel = (
  data: NormalizedChannelFragment
): Channel => {
  return {
    name: data.name,
    height: data.height ?? 1080,
    id: data.id,
    layoutId: data.layoutByChannel,
    themeId: data.themeByThemeId,
    width: data.width ?? 1920,
    // todo: this is the old way of splash channel layout definition
    //  must be removed once https://github.com/screencloud/studio-player/issues/613 is resolved
    deprecateContentLayout: data.content?.props?.layout,
    isHiddenZoneActive: !!data.content?.zones[HIDDEN_ZONE_ID],
  };
};
