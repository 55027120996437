import { GqlScreenDeviceJson, Operating } from "../graphqlTypes";

import { ContentViewItem } from "../../types/content";
import {
  FileFragment,
  LayoutFragment,
  ScreenStatus,
  SpaceFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { NormalizedPlaylistFragment } from "../playlists/types";
import { NormalizedChannelFragment } from "../channels/types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedOrgFragment } from "../organization/types";

// Action types
export const SET_SCREEN_ACTIVE_ITEM = "SET_SCREEN_ACTIVE_ITEM";
export const REQUEST_SCREEN = "REQUEST_SCREEN";
export const REQUEST_SCREEN_SUCCESS = "REQUEST_SCREEN_SUCCESS";
export const UPDATE_SCREEN_DEVICE_JSON_SUCCESS =
  "UPDATE_SCREEN_DEVICE_JSON_SUCCESS";

// Export all action types here.
export type ScreenAction =
  | RequestScreenAction
  | RequestScreenSuccessAction
  | SetScreenActiveItemAction
  | UpdateScreenDeviceJsonSuccessAction;

export interface ScreenData {
  [key: string]: string;
}

export interface ScreenState {
  id: string;
  activeContentItem?: ContentViewItem;
  screenData?: ScreenData;
  spaceId: string | null;
  operatingHours: Operating | undefined;
  deviceMeta: GqlScreenDeviceJson;
  status: ScreenStatus;
  isPreview: boolean;
}

export interface SetScreenActiveItemAction {
  type: typeof SET_SCREEN_ACTIVE_ITEM;
  payload: ContentViewItem;
}

export interface RequestScreenAction {
  type: typeof REQUEST_SCREEN;
}

export interface RequestScreenSuccessAction {
  type: typeof REQUEST_SCREEN_SUCCESS;
  payload: {
    screen: Partial<ScreenState>;
    organization: NormalizedOrgFragment | null | undefined;
    apps: {
      [id: string]: NormalizedAppInstanceFragment;
    };
    channels: {
      [id: string]: NormalizedChannelFragment;
    };
    files: {
      [id: string]: FileFragment;
    };
    layouts: {
      [id: string]: LayoutFragment;
    };
    links: {
      [id: string]: NormalizedLinkFragment;
    };
    playlists: {
      [id: string]: NormalizedPlaylistFragment;
    };
    themes: {
      [id: string]: ThemeFragment;
    };
    spaces: {
      [id: string]: SpaceFragment;
    };
    sites: {
      [id: string]: NormalizedSiteFragment;
    };
  };
}

export interface UpdateScreenDeviceJsonSuccessAction {
  type: typeof UPDATE_SCREEN_DEVICE_JSON_SUCCESS;
  payload: {
    device: GqlScreenDeviceJson;
  };
}
