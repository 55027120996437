/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'Logger';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```

 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */

import { Maybe } from "../queries";
import { actualUtcNow, TimeOptions } from "../utils/timeManager";
import datadog from "./datadog";
import loki from "./loki";
import remoteEndPointLogger from "./remoteEndPointLogger";
import { PMILogger } from "./pmiLogger";
import { Context } from "@datadog/browser-core";

const pmsLogger = new PMILogger();

export interface RemoteLoggerConfig {
  clientToken: string;
  version: string;
  env: string;
  proxyHost?: string;
  platform: string;
  deviceId: string;
  screenId: string;
  orgId: string;
  timeOptions: TimeOptions;
  graphqlToken: string;
  lokiEndpoint: string;
  spaceName?: string;
  remoteLogEndPoint?: string;
}

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

export type logObject = [string, Context, boolean?];

/**
 * Log output handler function.
 */
export type LogOutput = (
  level: LogLevel,
  message: string,
  context: Context,
  sendToLOki?: boolean
) => void;

export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Error;

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = []; // data dog , loki , custom functions to send logs

  /**
   * Logger initialised status
   */
  static isInitialized = false;

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode(): void {
    // TODO set log level by environment variables or flags in future
    Logger.level = LogLevel.Warning;
  }

  /**
   * Default context that is added to every log context
   */
  static defaultContext: Context = {};

  static timeOptions: TimeOptions = {
    timeOffset: 0,
    timelineControlOffset: 0,
  };

  static enableConsoleLogs = false;

  /**  initialise the parent logger */
  static init = (
    config: RemoteLoggerConfig,
    featureFlags: Maybe<string>[]
  ): void => {
    if (!Logger.isInitialized) {
      // set log level from env
      if (process.env.REACT_APP_LOG_LEVEL) {
        Logger.level = Number(process.env.REACT_APP_LOG_LEVEL);
      }

      // set timeoptions
      Logger.timeOptions = config.timeOptions;

      // init datadog
      datadog.init(config, featureFlags);

      // init loki
      !!config.lokiEndpoint && loki.init(config, featureFlags);

      // init remote endpoint logger
      remoteEndPointLogger.init(config);

      Logger.defaultContext = {
        ...Logger.defaultContext,
        screenId: config.screenId,
        spaceName: config.spaceName,
        platform: config.platform,
        orgId: config.orgId,
        studioPlayerVersion: config.version,
        screenCloudApiId: config.deviceId,
        env: config.env,
        deviceTimeStamp: actualUtcNow(Logger.timeOptions), // log actual utc timestamp
      };

      // TEMP FIX UNTILL ORG & SCREEN LOG lEVEL IS THERE
      if (
        Logger.isDataDogEnabled(featureFlags) ||
        Logger.isLokiEnabled(featureFlags)
      ) {
        Logger.level = LogLevel.Info;
      }

      Logger.isInitialized = true;
    }
  };

  /**
   * getters for feature flags
   *
   */
  static isDataDogEnabled = (featureFlags: Maybe<string>[]): boolean => {
    return featureFlags.includes("player_log_enable");
  };

  static isLokiEnabled = (featureFlags: Maybe<string>[]): boolean => {
    return featureFlags.includes("playback_logs");
  };

  static setTimeOptions = (timeoptions: TimeOptions) => {
    Logger.timeOptions = timeoptions;
  };

  constructor(private origin: string) {
    // add output sources adding sources to constructor to make sure events before initialisation are captured
    if (Logger.outputs.length === 0) {
      Logger.outputs.push(datadog.getLogger());
      Logger.outputs.push(loki.getLogger());
      Logger.outputs.push(pmsLogger.getlogger());
    }
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: logObject): void {
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: logObject): void {
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: logObject): void {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: logObject): void {
    this.log(console.error, LogLevel.Error, objects);
  }

  log(func: (...args: any) => void, level: LogLevel, objects: logObject) {
    /** filters log output on
     * 1 - when in preload change info messages to debug
     * 2 - when in preview donot output logs except error
     */
    if (objects[1]?.isPreview && level > 1) {
      return;
    }
    if (objects[1]?.isPreload && level > 1) {
      // downgrade logs to debug when in preload state
      if (level === LogLevel.Info) {
        level = LogLevel.Debug;
      }
      objects[0] = "[preload]" + objects[0];
    }

    // default context is merged with log context

    const defaultContext = Logger.defaultContext;
    const additionalContext = objects[1];
    const logcontext: Context = { ...defaultContext, ...additionalContext };

    // show output on console
    if (
      process.env.REACT_APP_SC_ENV === "development" ||
      process.env.REACT_APP_ENABLE_CONSOLE_LOGS ||
      localStorage.getItem("enable_console_logs") ||
      level === 1 // log all errors to console
    ) {
      func.apply(console, [
        "[" + this.origin + "]",
        "[" + LogLevel[level] + "]",
        objects[0],
        logcontext,
      ]);
    }

    // Messages being sent to regitsered outputs
    if (level <= Logger.level) {
      //  log lovel = 0 = off no logs go to output
      Logger.outputs.forEach((output) =>
        output.apply(output, [
          level,
          `[${this.origin}]` + objects[0],
          logcontext,
          objects[2],
        ])
      );
    }
  }
}
