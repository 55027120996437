import React from "react";
import classnames from "classnames";

import { TransitionTypeInternal, Transition, Props } from "./types";
import "./animate.min.css";
import "./customAnimate.css";
import { TransitionType, Direction } from "../../../../store/graphqlTypes";

export const transitionDefinitions: Transition = {
  [TransitionTypeInternal.None]: {
    in: "",
    out: "",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.Fade]: {
    in: "fadeIn",
    out: "fadeOut",
    isCross: false,
    isColor: true,
  },
  [TransitionTypeInternal.Dissolve]: {
    in: "fadeIn",
    out: "fadeOut",
    isCross: true,
    isColor: false,
  },
  [TransitionTypeInternal.FlipX]: {
    in: "cardFlipInX",
    out: "cardFlipOutX",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.FlipY]: {
    in: "cardFlipInY",
    out: "cardFlipOutY",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.SlideDown]: {
    in: "slideInDown",
    out: "slideOutDown",
    isCross: true,
    isColor: false,
  },
  [TransitionTypeInternal.SlideUp]: {
    in: "slideInUp",
    out: "slideOutUp",
    isCross: true,
    isColor: false,
  },
  [TransitionTypeInternal.SlideRight]: {
    in: "slideInLeft",
    out: "slideOutRight",
    isCross: true,
    isColor: false,
  },
  [TransitionTypeInternal.SlideLeft]: {
    in: "slideInRight",
    out: "slideOutLeft",
    isCross: true,
    isColor: false,
  },
  [TransitionTypeInternal.BounceCenter]: {
    in: "bounceIn",
    out: "bounceOut",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.BounceUp]: {
    in: "bounceInUp",
    out: "bounceOutUp",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.BounceDown]: {
    in: "bounceInDown",
    out: "bounceOutDown",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.BounceLeft]: {
    in: "bounceInRight",
    out: "bounceOutLeft",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.BounceRight]: {
    in: "bounceInLeft",
    out: "bounceOutRight",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.RotateCounterclockwise]: {
    in: "rotateInCounterclockwise",
    isColor: false,
    isCross: false,
    out: "rotateOutCounterclockwise",
  },
  [TransitionTypeInternal.RotateClockwise]: {
    in: "rotateInClockwise",
    isColor: false,
    isCross: false,
    out: "rotateOutClockwise",
  },
  [TransitionTypeInternal.ZoomCenter]: {
    in: "zoomIn",
    out: "zoomOut",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.ZoomUp]: {
    in: "customZoomInUp",
    isColor: false,
    isCross: false,
    out: "customZoomOutUp",
  },
  [TransitionTypeInternal.ZoomDown]: {
    in: "customZoomInDown",
    isColor: false,
    isCross: false,
    out: "customZoomOutDown",
  },
  [TransitionTypeInternal.ZoomLeft]: {
    in: "customZoomInRight",
    isColor: false,
    isCross: false,
    out: "customZoomOutLeft",
  },
  [TransitionTypeInternal.ZoomRight]: {
    in: "customZoomInLeft",
    isColor: false,
    isCross: false,
    out: "customZoomOutRight",
  },
  [TransitionTypeInternal.RollUp]: {
    in: "rollInUp",
    out: "rollOutUp",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.RollDown]: {
    in: "rollInDown",
    out: "rollOutDown",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.RollLeft]: {
    in: "rollInRight",
    out: "rollOutLeft",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.RollRight]: {
    in: "rollIn",
    out: "rollOut",
    isCross: false,
    isColor: false,
  },
  [TransitionTypeInternal.JackInTheBox]: {
    in: "jackInTheBox",
    out: "jackInTheBoxOut",
    isCross: false,
    isColor: false,
  },
};

export function getInternalTransitionType(
  type: TransitionType,
  direction?: Direction
): TransitionTypeInternal {
  switch (type) {
    case TransitionType.None:
      return TransitionTypeInternal.None;
    case TransitionType.Dissolve:
      return TransitionTypeInternal.Dissolve;
    case TransitionType.Fade:
      return TransitionTypeInternal.Fade;
    case TransitionType.Flip:
      if (direction === "up" || direction === "down") {
        return TransitionTypeInternal.FlipX;
      } else {
        return TransitionTypeInternal.FlipY;
      }
    case TransitionType.Slide:
      if (direction === "left") {
        return TransitionTypeInternal.SlideLeft;
      } else if (direction === "right") {
        return TransitionTypeInternal.SlideRight;
      } else if (direction === "up") {
        return TransitionTypeInternal.SlideUp;
      } else {
        return TransitionTypeInternal.SlideDown;
      }
    case TransitionType.Bounce:
      if (direction === "left") {
        return TransitionTypeInternal.BounceLeft;
      } else if (direction === "right") {
        return TransitionTypeInternal.BounceRight;
      } else if (direction === "up") {
        return TransitionTypeInternal.BounceUp;
      } else if (direction === "down") {
        return TransitionTypeInternal.BounceDown;
      } else {
        return TransitionTypeInternal.BounceCenter;
      }
    case TransitionType.Rotate:
      if (direction === "counterclockwise") {
        return TransitionTypeInternal.RotateCounterclockwise;
      } else {
        return TransitionTypeInternal.RotateClockwise;
      }
    case TransitionType.Zoom:
      if (direction === "left") {
        return TransitionTypeInternal.ZoomLeft;
      } else if (direction === "right") {
        return TransitionTypeInternal.ZoomRight;
      } else if (direction === "up") {
        return TransitionTypeInternal.ZoomUp;
      } else if (direction === "down") {
        return TransitionTypeInternal.ZoomDown;
      } else {
        return TransitionTypeInternal.ZoomCenter;
      }
    case TransitionType.Roll:
      if (direction === "left") {
        return TransitionTypeInternal.RollLeft;
      } else if (direction === "right") {
        return TransitionTypeInternal.RollRight;
      } else if (direction === "up") {
        return TransitionTypeInternal.RollUp;
      } else {
        return TransitionTypeInternal.RollDown;
      }
    case TransitionType.JackInTheBox:
      return TransitionTypeInternal.JackInTheBox;
    default:
      return TransitionTypeInternal.None;
  }
}

export function isCrossTransition(
  type: TransitionType,
  direction: Direction | undefined
): boolean {
  const internalType = getInternalTransitionType(type, direction);
  return transitionDefinitions[internalType]?.isCross;
}

export const TransitionComponent: React.FunctionComponent<Props> = ({
  transitionProps,
  children,
  transitionStatus,
}: Props) => {
  const transitionDef =
    transitionDefinitions[
      getInternalTransitionType(transitionProps.type, transitionProps.direction)
    ];

  if (transitionProps.type !== TransitionType.None) {
    const transitionBackgroundClasses = classnames(
      "background-block",
      "animated",
      {
        fadeOut: transitionStatus === "in",
        fadeIn: transitionStatus === "out",
      }
    );

    const animatedBackgroundClasses = classnames("animated", {
      [transitionDef.in]: transitionStatus === "in",
      [transitionDef.out]: transitionStatus === "out",
    });

    const transitionBgBlock =
      transitionProps.color && !transitionDef.isCross ? (
        <div
          data-testid="transition-bg-block"
          className={transitionBackgroundClasses}
          style={{
            animationDuration: `${transitionProps.duration}ms`,
            background: transitionDef.isColor
              ? transitionProps.color
              : "transparent",
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        />
      ) : null;

    return (
      <>
        {transitionBgBlock}
        <div
          data-testid="animate-bg"
          className={animatedBackgroundClasses}
          style={{
            animationDuration: `${transitionProps.duration}ms`,
            height: "100%",
            width: "100%",
          }}
        >
          {children}
        </div>
      </>
    );
  }

  return <div>{children}</div>;
};

export default TransitionComponent;
