import { useState, useEffect, useMemo } from "react";
import {
  PlaybackState,
  TimelinePlaybackState,
} from "../../../../store/playback/types";
import { TimelinesState } from "../../../../store/timelines/types";
import { getContentContext } from "./contentContext";
import { AppsState } from "../../../../store/apps/types";
import { FilesState } from "../../../../store/files/types";
import { LinksState } from "../../../../store/links/types";
import { ScreenState } from "../../../../store/screen/types";
import { ChannelsState } from "../../../../store/channels/types";
import { PlaylistsState } from "../../../../store/playlists/types";
import { LayoutsState } from "../../../../store/layouts/types";
import { OrganizationState } from "../../../../store/organization/types";
import { getCommitHash } from "../../../../utils/helpers";
import {
  parseContentListId,
  parseLocalTimelineId,
} from "../../../../store/contentLists/utils";
import { getSourceDetail } from "./sourceDetail";
import { SitesState } from "../../../../store/sites/types";
import { Logger } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";

const log = new Logger("timelinePlayBackLogs");

interface TimelinePlaybackLogsProps {
  apps: AppsState;
  files: FilesState;
  links: LinksState;
  sites: SitesState;
  playlists: PlaylistsState;
  screen: ScreenState;
  platform: string;
  playbackState: PlaybackState;
  timelines: TimelinesState;
  channels: ChannelsState;
  layouts: LayoutsState;
  organization: OrganizationState;
}

interface TimelinePlayback {
  [key: string]: TimelinePlaybackState;
}

interface Timeline {
  id: string;
  zone: string;
}

export const useTimelinePlaybackLogs = ({
  apps,
  files,
  links,
  sites,
  screen,
  playbackState,
  timelines,
  platform,
  channels,
  layouts,
  organization,
  playlists,
}: TimelinePlaybackLogsProps): void => {
  const [previousTimelinesPlayback, setPreviousTimelinesPlayback] = useState<
    TimelinePlayback
  >({});
  const activeContentItem = screen.activeContentItem;
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );
  const activeTimelinesWithZoneData = useMemo<Timeline[]>(() => {
    if (!activeContentItem) return [];

    if (activeContentItem.type === "channel") {
      const layoutId = channels.byId[activeContentItem.id].layoutId;
      const zones = layouts.byId[layoutId]?.zones || [];
      return zones.map((zone) => {
        return {
          zone: zone.id,
          id: zone.contentListId,
        };
      });
    } else if (activeContentItem?.type === "playlist") {
      return [
        {
          zone: "root",
          id: activeContentItem.id,
        },
      ];
    }
    return [];
  }, [activeContentItem, channels, layouts]);

  if (
    activeContentItem &&
    (activeContentItem.type === "channel" ||
      activeContentItem.type === "playlist")
  ) {
    activeTimelinesWithZoneData.forEach((activeTimeline) => {
      if (
        previousTimelinesPlayback[activeTimeline.id]?.activeIndex !==
        playbackState.timelines[activeTimeline.id]?.activeIndex
      ) {
        const activePlaybackTimeline =
          playbackState.timelines[activeTimeline.id];
        const activeTimelineItem =
          timelines.byId[activeTimeline.id]?.items?.[
            activePlaybackTimeline.activeIndex || 0
          ];
        const parsedContentListId = parseContentListId(
          parseLocalTimelineId(activeTimeline.id).sourceContentListId
        );
        const previousItemIndex =
          previousTimelinesPlayback[activeTimeline.id]?.activeIndex;
        const emptyItem = {
          zoneId: activeTimeline.zone,
        };

        if (!activeTimelineItem) {
          log.info(
            "Screen is empty. No active timeline item assigned.",
            {
              ...emptyItem,
              isPreview: isPreview,
            },
            true
          );
          return;
        } else if (activeTimelineItem.type === "void") {
          log.info(
            "Screen is empty. Void timeline item assigned to screen.",
            {
              ...emptyItem,
              isPreview: isPreview,
            },
            true
          );
          return;
        }

        // previous item is exists
        if (previousItemIndex !== undefined) {
          const previousTimelineItem =
            timelines.byId[activeTimeline.id]?.items?.[previousItemIndex];

          if (!previousTimelineItem || previousTimelineItem?.type === "void") {
            log.info(
              `Finish timeline item empty screen period.`,
              {
                previousTimelineItemType: previousTimelineItem?.type,
                isPreview: isPreview,
              },
              true
            );
          } else {
            log.info(
              `Finish playback timeline item.`,
              {
                listId: previousTimelineItem.listId,
                platform,
                status: screen.status,
                screenId: screen.id,
                spaceId: screen.spaceId || "unavailable",
                deviceId: screen.deviceMeta.id,
                contentId: previousTimelineItem.id,
                contentType: previousTimelineItem.type,
                ...getSourceDetail(
                  "timeline",
                  activeContentItem,
                  parsedContentListId,
                  channels,
                  playlists
                ),
                playerVersion: getCommitHash(),
                ...getContentContext(
                  previousTimelineItem.id,
                  previousTimelineItem.type,
                  apps,
                  files,
                  links,
                  sites
                ),
                screenName: screen.deviceMeta.settings.name,
                location: screen.screenData?.sc_address,
                orgId: organization.id,
                zoneId: activeTimeline.zone,
                isPreview: isPreview,
              },
              true
            );
          }
        }

        log.info(
          `Start playback timeline item.`,
          {
            listId: activeTimelineItem.listId,
            platform,
            status: screen.status,
            screenId: screen.id,
            spaceId: screen.spaceId || "unavailable",
            deviceId: screen.deviceMeta.id,
            contentId: activeTimelineItem.id,
            contentType: activeTimelineItem.type,
            ...getSourceDetail(
              "timeline",
              activeContentItem,
              parsedContentListId,
              channels,
              playlists
            ),
            playerVersion: getCommitHash(),
            ...getContentContext(
              activeTimelineItem.id,
              activeTimelineItem.type,
              apps,
              files,
              links,
              sites
            ),
            screenName: screen.deviceMeta.settings.name,
            location: screen.screenData?.sc_address,
            orgId: organization.id,
            zoneId: activeTimeline.zone,
            isPreview: isPreview,
          },
          true
        );
      }
    });
  } else {
    log.info(
      "No active timeline item assigned.",
      { isPreview: isPreview },
      true
    );
  }

  useEffect(() => {
    // reset previous timeline playback state when content item is changed to a single content
    if (
      activeContentItem &&
      activeContentItem.type !== "channel" &&
      activeContentItem.type !== "playlist"
    ) {
      setPreviousTimelinesPlayback({});
    } else {
      if (Object.keys(playbackState.timelines).length === 0) return;
      setPreviousTimelinesPlayback(playbackState.timelines);
    }
  }, [activeContentItem, playbackState.timelines]);
};
