// Schemas for the leaf nodes items.
import { schema } from "normalizr";
import {
  FileFragment,
  LayoutFragment,
  SpaceFragment,
  ThemeFragment,
} from "../queries";
import { NormalizedSiteFragment } from "./sites/types";
import { NormalizedAppInstanceFragment } from "./apps/types";
import { NormalizedPlaylistFragment } from "./playlists/types";
import { NormalizedChannelFragment } from "./channels/types";
import { NormalizedLinkFragment } from "./links/types";
import { NormalizedOrgFragment } from "./organization/types";

export const fileSchema = new schema.Entity("files");
export const layoutSchema = new schema.Entity("layouts");
export const themesSchema = new schema.Entity("themes");
export const spaceSchema = new schema.Entity("spaces");

export const orgSchema = new schema.Entity("orgs", {
  themeByDefaultChannelThemeId: themesSchema,
});

export const linkSchema = new schema.Entity("links", {
  fileByFileId: fileSchema,
});
export type NormalizedLinkEntities = {
  files: { [key: string]: FileFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
};

export const siteSchema = new schema.Entity("sites", {
  fileByThumbnailId: fileSchema,
});
export type NormalizedSiteEntities = {
  files: { [key: string]: FileFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
};

export const appSchema = new schema.Entity("apps", {
  filesByAppInstanceId: {
    nodes: [fileSchema],
  },
  orgByOrgId: orgSchema,
});
export type NormalizedAppInstanceEntities = {
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
};

export const playlistSchema = new schema.Entity("playlists", {
  appInstancesByPlaylistId: {
    nodes: [appSchema],
  },
  filesByPlaylistId: {
    nodes: [fileSchema],
  },
  linksByPlaylistId: {
    nodes: [linkSchema],
  },
  sitesByPlaylistId: {
    nodes: [siteSchema],
  },
  orgByOrgId: orgSchema,
});
export type NormalizedPlaylistEntities = {
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
};

// Schema for how these relate in a channel
export const channelSchema = new schema.Entity("channels", {
  appInstancesByChannelId: {
    nodes: [appSchema],
  },
  filesByChannelId: {
    nodes: [fileSchema],
  },
  layoutByChannel: layoutSchema,
  linksByChannelId: {
    nodes: [linkSchema],
  },
  playlistsByChannelId: {
    nodes: [playlistSchema],
  },
  sitesByChannelId: {
    nodes: [siteSchema],
  },
  themeByThemeId: themesSchema,
  orgByOrgId: orgSchema,
});
export type NormalizedChannelEntities = {
  channels: { [key: string]: NormalizedChannelFragment } | null;
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  layouts: { [key: string]: LayoutFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
};

export const castSchema = new schema.Entity("cast", {
  appInstanceByCastId: appSchema,
  fileByCastId: fileSchema,
  linkByCastId: linkSchema,
  playlistByCastId: playlistSchema,
  channelByCastId: channelSchema,
  siteByCastId: siteSchema,
});

// Define the overall response.
export const screenSchema = new schema.Entity("screen", {
  orgByOrgId: orgSchema,
  appInstancesByScreenId: {
    nodes: [appSchema],
  },
  channelsByScreenId: {
    nodes: [channelSchema],
  },
  filesByScreenId: {
    nodes: [fileSchema],
  },
  linksByScreenId: {
    nodes: [linkSchema],
  },
  playlistsByScreenId: {
    nodes: [playlistSchema],
  },
  playlistsByChannelId: {
    nodes: [playlistSchema],
  },
  spaceBySpaceId: spaceSchema,
  castByCastId: castSchema,
  sitesByScreenId: {
    nodes: [siteSchema],
  },
});

export type NormalizedScreenEntities = {
  files: { [key: string]: FileFragment } | null;
  layouts: { [key: string]: LayoutFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  spaces: { [key: string]: SpaceFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  channels: { [key: string]: NormalizedChannelFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  cast: unknown | null;
  screen: unknown | null;
};
