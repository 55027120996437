import React, { FunctionComponent, memo, useEffect } from "react";
import { PlayerFile } from "../../../../store/files/types";
import { ImageViewer } from "../ImageViewer/ImageViewer";
import { VideoViewer } from "../VideoViewer/VideoViewer";
import { AudioViewer } from "../AudioViewer/AudioViewer";
import { DocumentContainer } from "../DocumentViewer/DocumentViewer";
import { ContentSizeType } from "../../../../types/content";
import { ElementSize } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { requestFileSuccess } from "../../../../store/files/actions";
import { useInitialPlaybackPosition } from "../../../../utils/useInitialPlaybackPosition";
import { useMediaSource } from "../../../../utils/useMediaSource";
import { ConfigState } from "../../../../store/config/types";
import { Maybe, useFileById } from "../../../../queries";

export interface FileViewerContainerProps {
  id: string;
  sizeType: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  fullDurationMs: number;
  itemStartTimestamp?: number;
  isRoot?: boolean;
}

export const FileViewerContainer: FunctionComponent<FileViewerContainerProps> = (
  props: FileViewerContainerProps
) => {
  const { id, isRoot, itemStartTimestamp, sizeType, containerSize } = props;
  const dispatch = useDispatch();

  const initialPlaybackPositionMs = useInitialPlaybackPosition(
    itemStartTimestamp,
    props.fullDurationMs
  );

  const file = useSelector<PlayerState, PlayerFile | undefined>((state) => {
    return state.files.byId[id];
  });

  const config = useSelector<PlayerState, ConfigState>((state) => {
    return state.config;
  });

  const featureFlags = useSelector<PlayerState, Maybe<string>[]>(
    (state) => state.organization?.featureFlags || []
  );

  const [fetchFile, { data }] = useFileById({
    useCache: false,
    skipCache: true,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.fileById) {
      dispatch(requestFileSuccess(data.fileById));
    }
  }, [data?.fileById, dispatch]);

  useInitialFetch(!!isRoot, fetchFile);

  const src = useMediaSource(
    file,
    config,
    sizeType,
    containerSize,
    featureFlags
  );

  if (!file) {
    return (
      <p data-testid="can-not-find-file">
        Sorry, we can&apos;t find the file you are looking for.
      </p>
    );
  }

  return (
    <FileViewer
      src={src}
      file={file}
      initialPlaybackPositionMs={initialPlaybackPositionMs}
      userInteractionEnabled={
        (config.contextConfig?.userInteractionEnabled || false) as boolean
      }
      {...props}
    />
  );
};

interface FileViewerProps {
  src: string | string[];
  file: PlayerFile;
  sizeType: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  fullDurationMs: number;
  initialPlaybackPositionMs: number;
  itemStartTimestamp?: number;
  userInteractionEnabled: boolean;
}

export const FileViewer: FunctionComponent<FileViewerProps> = memo(
  ({
    src,
    file,
    sizeType,
    containerSize,
    isPreload,
    fullDurationMs,
    itemStartTimestamp,
    initialPlaybackPositionMs,
    userInteractionEnabled,
  }: FileViewerProps) => {
    const { id } = file;

    switch (file.type) {
      case "image":
        if (typeof src !== "string") {
          return null;
        }
        return (
          <ImageViewer
            src={src}
            name={file.name}
            urlKey={file.urlKey}
            mimetype={file.mimetype}
            key={id}
            sizeType={sizeType}
            containerSize={containerSize}
            isPreload={isPreload}
          />
        );
      case "video":
        if (typeof src !== "string") {
          return null;
        }
        return (
          <VideoViewer
            src={src}
            file={file}
            key={id}
            isPreload={isPreload}
            userInteractionEnabled={userInteractionEnabled}
            sizeType={sizeType}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
          />
        );
      case "audio":
        if (typeof src !== "string") {
          return null;
        }
        return (
          <AudioViewer
            src={src}
            key={id}
            isPreload={isPreload}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
            fileName={file.name}
          />
        );
      case "document":
        if (!Array.isArray(src)) {
          return null;
        }
        return (
          <DocumentContainer
            src={src}
            file={file}
            key={id}
            sizeType={sizeType}
            containerSize={containerSize}
            durationMs={fullDurationMs}
            itemStartTimestamp={itemStartTimestamp || 0}
            isPreload={isPreload}
          />
        );
      default:
        return (
          <div data-testid="file-not-support">
            File type support coming soon.
          </div>
        );
    }
  }
);
FileViewer.displayName = "FileViewer";
