import { GqlScreenDeviceJson } from "../graphqlTypes";
import {
  REQUEST_SCREEN,
  REQUEST_SCREEN_SUCCESS,
  RequestScreenAction,
  RequestScreenSuccessAction,
  ScreenState,
  UPDATE_SCREEN_DEVICE_JSON_SUCCESS,
  UpdateScreenDeviceJsonSuccessAction,
} from "./types";
import { normalize } from "normalizr";
import { ContentViewItem } from "../../types/content";
import { DEFAULT_SIZE_TYPE_FALLBACK } from "../../constants";
import { CurrentScreenQuery, ScreenByIdQuery } from "../../queries";
import {
  NormalizedScreenEntities,
  screenSchema,
} from "../normalizationSchemas";

export function requestScreen(): RequestScreenAction {
  return {
    type: REQUEST_SCREEN,
  };
}

/**
 * This action creator will parse the API response into the various normalized objects here.
 * More efficient as each reducer doesn't then have to parse the entire response itself.
 *
 * NB - Normalizr will replace a normalized child with its ID.
 * Look at NormalizedGqlChannel in types.ts as an example of safely informing TS of this.
 */
export function requestScreenSuccess(
  response: ScreenByIdQuery | CurrentScreenQuery
): RequestScreenSuccessAction | undefined {
  const screenData =
    "currentScreen" in response
      ? response.currentScreen
      : (response as ScreenByIdQuery).screenById;

  if (screenData) {
    const targetContentRef = screenData.castByCastId
      ? screenData.castByCastId.content
      : screenData.content;

    let activeContentItem: ContentViewItem;

    switch (targetContentRef._ref.type) {
      case "file":
      case "app":
      case "link":
      case "site":
        activeContentItem = {
          type: targetContentRef._ref.type,
          id: targetContentRef._ref.id,
          sizeType: DEFAULT_SIZE_TYPE_FALLBACK,
          fullDurationMs: Infinity,
        };
        break;
      case "channel":
      case "playlist":
        activeContentItem = {
          type: targetContentRef._ref.type,
          id: targetContentRef._ref.id,
        };
        break;
      default:
        activeContentItem = {
          type: "void",
        };
    }

    const screen: ScreenState = {
      activeContentItem,
      id: screenData.id,
      screenData: screenData.env,
      spaceId: screenData.spaceBySpaceId?.id || null,
      operatingHours: screenData.preferences.operating,
      deviceMeta: screenData.device,
      status: screenData.status,
      isPreview: screenData.preview,
    };

    const normalizedData = normalize<unknown, NormalizedScreenEntities>(
      screenData,
      screenSchema
    );

    const organization = normalizedData.entities.orgs
      ? Object.values(normalizedData.entities.orgs)[0]
      : undefined;

    return {
      type: REQUEST_SCREEN_SUCCESS,
      payload: {
        apps: normalizedData.entities.apps || {},
        channels: normalizedData.entities.channels || {},
        files: normalizedData.entities.files || {},
        layouts: normalizedData.entities.layouts || {},
        links: normalizedData.entities.links || {},
        organization: organization,
        playlists: normalizedData.entities.playlists || {},
        screen,
        themes: normalizedData.entities.themes || {},
        spaces: normalizedData.entities.spaces || {},
        sites: normalizedData.entities.sites || {},
      },
    };
  }
  return undefined;
}

export function updateScreenDeviceInfoSuccess(
  deviceJson: GqlScreenDeviceJson
): UpdateScreenDeviceJsonSuccessAction {
  return {
    type: UPDATE_SCREEN_DEVICE_JSON_SUCCESS,
    payload: {
      device: deviceJson,
    },
  };
}
