import React, { FunctionComponent } from "react";
import styles from "./SidePanel.module.css";
import SearchIcon from "../../../../img/search.svg";
import CloseIcon from "../../../../img/close.svg";

interface Props {
  search: string;
  setSearch: (search: string) => void;
}

const SearchFilter: FunctionComponent<Props> = ({
  search,
  setSearch,
}: Props) => {
  return (
    <div className={styles.search}>
      <img src={SearchIcon} alt="Search" />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
        placeholder="Type to filter"
      />
      {search && search.length > 0 && (
        <img
          src={CloseIcon}
          alt="Clear"
          onClick={() => setSearch("")}
          className={styles.clearSearch}
        />
      )}
    </div>
  );
};
export default SearchFilter;
