export enum MenuItemType {
  All = "all",
  Dashboards = "site",
  Apps = "app",
  Image = "image",
  Video = "video",
  Document = "document",
  Media = "media",
  Links = "link",
  Playlist = "playlist",
}
