import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { emptyInitialState, PlayerState, rootReducer } from "./rootReducer";
import { PlayerAction } from "./storeTypes";

export const createPlayerStore = (): {
  store: Store<PlayerState, AnyAction>;
} => {
  const isLocalDev = process.env.NODE_ENV === "development";

  let enhancer: StoreEnhancer;

  if (isLocalDev) {
    // Middleware you want to use in local development, e.g. Redux Devtools.
    enhancer = composeWithDevTools(applyMiddleware());
  } else {
    enhancer = compose(
      // Middleware you want to use in production
      applyMiddleware()
    );
  }

  const store = createStore<PlayerState, PlayerAction, unknown, unknown>(
    rootReducer,
    emptyInitialState,
    enhancer
  );

  return {
    store,
  };
};
