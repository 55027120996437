import "./utils/importMandatoryPolyfills";
import "./wdyr";

import "reset-css"; // Before other imports. Removes all native browser styling.
import "whatwg-fetch"; // required until cypress supports fetch API. https://github.com/cypress-io/cypress/issues/95
import "./index.css";

import { AnyAction, Store } from "redux";
import { ClientContext, GraphQLClient } from "graphql-hooks";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { createFileProcessingClient, createGraphQLClient } from "./setup";

import { ClientApiProvider } from "./ClientApiContext";
import { ConfigState } from "./store/config/types";
import { ConfigurationManager } from "./configurationManager";
import { LiveUpdatesContainer } from "./modules/core/containers/LiveUpdatesContainer/LiveUpdatesContainer";
import { PlayerCoreContainer } from "./modules/core/containers/PlayerCoreContainer/PlayerCoreContainer";
import { LoggingContainer } from "./modules/core/containers/LoggingContainer/LoggingContainer";
import { PlayerState } from "./store/rootReducer";
import { Provider, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { ServiceWorkerManager } from "./serviceWorkerManager";
import { TimeSynchroniserContainer } from "./modules/core/containers/TimeSynchroniserContainer/TimeSynchroniserContainer";
import { connectToPMIForUpdates } from "./utils/connectToPMIForUpdates";
import { createPlayerStore } from "./store/createPlayerStore";
import { initAutoplayCheck } from "./utils/autoplay";
import { registerHttpInterceptor } from "./utils/httpInterceptor";
import { setConfig } from "./store/config/actions";
import { videoPlayerManager } from "./videoPlayerManager";
import { getShortCommitHash } from "./utils/helpers";
import { clearLocalStorageAfterDailyReload } from "./utils/dailyReloadManager";
import MenuProvider from "./providers/MenuProvider/MenuProvider";
import { FirestoreClient } from "@screencloud/signage-firestore-client";
import RemoteApi from "./remoteApi";
import { OrganizationState } from "./store/organization/types";
import { Logger } from "./logger/logger";
import { usePreviousValue } from "./utils/usePreviousValue";

const log = new Logger("index");

interface ContentProps {
  store: Store<PlayerState, AnyAction>;
  config: ConfigState;
  client: GraphQLClient;
}

export function createFirestoreClient(
  orgId: string,
  region: string
): FirestoreClient | undefined {
  if (!orgId) {
    return undefined;
  }
  return new FirestoreClient({
    firebase: {
      apiKey: process.env[
        `REACT_APP_FIREBASE_API_KEY_${region.toUpperCase()}`
      ] as string,
      authDomain: process.env[
        `REACT_APP_FIREBASE_AUTH_DOMAIN_${region.toUpperCase()}`
      ] as string,
      projectId: process.env[
        `REACT_APP_FIREBASE_PROJECT_ID_${region.toUpperCase()}`
      ] as string,
    },
    organizationId: orgId,
    projectName: "liveUpdates",
  });
}

function initMediaHandlers(): Promise<[void, void] | void> {
  return Promise.all([videoPlayerManager.init(), initAutoplayCheck()]).catch(
    (err) => {
      log.debug("Cannot initialize media handlers", err.message);
    }
  );
}

const Content = ({ store, config, client }: ContentProps): ReactElement => {
  const [hasInitialised, setHasInitialised] = useState(false);

  const organization = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );

  //Check screen data for key: embedMenu value: true
  const enableEmbedMenu = useSelector<PlayerState, boolean>(
    (state) => state.screen.screenData?.embedMenu === "true"
  );

  useEffect(() => {
    // 1. initial state is loaded, 2. persist gate loads cache, 3. sdk config is dispatched
    store.dispatch(setConfig(config));

    setHasInitialised(true);
  }, [store, config]);

  const Wrapper = enableEmbedMenu ? MenuProvider : Fragment;

  const [firestoreClient, setFirestoreClient] = useState<
    FirestoreClient | undefined
  >(undefined);
  const previousOrgId = usePreviousValue(organization.id);
  const previousRegion = usePreviousValue(config.region);

  useEffect(() => {
    let isDismissed = false;

    if (
      organization.id !== previousOrgId ||
      config.region !== previousRegion ||
      !firestoreClient
    ) {
      Promise.resolve()
        .then(() => {
          if (isDismissed) {
            return;
          }
          if (firestoreClient) {
            return firestoreClient.purge();
          }
        })
        .then(() => {
          if (isDismissed) {
            return;
          }
          const newClient = createFirestoreClient(
            organization.id,
            config.region
          );

          setFirestoreClient(newClient);
        });
    }

    return () => {
      isDismissed = true;
    };
  }, [
    organization.id,
    config.region,
    firestoreClient,
    previousOrgId,
    previousRegion,
  ]);

  return hasInitialised ? (
    <ClientContext.Provider value={client}>
      <Wrapper>
        {!config.contentConfig.isPreview && (
          <LiveUpdatesContainer liveUpdateClient={firestoreClient} />
        )}
        <TimeSynchroniserContainer
          timeServerUrl={
            process.env.REACT_APP_TIME_SERVER_URL ||
            "https://clock.screen.cloud"
          }
        />
        <PlayerCoreContainer />
        <LoggingContainer />
      </Wrapper>
    </ClientContext.Provider>
  ) : (
    <></>
  );
};

declare global {
  interface Window {
    studioplayer: {
      hash?: string;
    };
  }
}

window.addEventListener("load", () => {
  if (process.env.NODE_ENV === "production") {
    ServiceWorkerManager.getInstance().register();
  }
});

// Pass metadata
window.studioplayer = {
  hash: getShortCommitHash(),
};

console.log(">>>>>>>>>>>>>>>>>> tubbytoey <<<<<<<<<<<<<<<<<<<<<<<<<")

log.info("Application start", { name: "Application start" }, true);
ConfigurationManager.getInstance()
  .getConfiguration()
  .then(async (config) => {
    await clearLocalStorageAfterDailyReload(config.graphqlEndpoint);
    return config;
  })
  .then(async (config) => {
    if (config) {
      const { store } = createPlayerStore();
      const client = createGraphQLClient(config);
      const fileProcessingClient = createFileProcessingClient(config);
      const remoteApi = new RemoteApi();
      connectToPMIForUpdates(store);
      registerHttpInterceptor();

      // Initialize video handling logic before any rendering begins.
      await initMediaHandlers();

      ReactDOM.render(
        <Provider store={store}>
          <ClientApiProvider
            value={{
              fileProcessingClient,
              remoteApi,
            }}
          >
            <Content store={store} client={client} config={config} />
          </ClientApiProvider>
        </Provider>,
        document.getElementById("root")
      );
    } else {
      // Could not find valid config, cannot start the player.
      // TODO - Make a friendlier message. Users *should* never see this however.
      ReactDOM.render(
        <p>
          We could not find valid config on your device and are unable to start
          the player. Please contact support for advice on how to reset this
          screen.
        </p>,
        document.getElementById("root")
      );
    }
  });
