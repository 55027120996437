import {
  DEACTIVATE_SCREEN_MESSAGE,
  PREVIEW_SCREEN_MESSAGE,
} from "../../../../constants";
import { OperatingDay } from "../../../../store/graphqlTypes";
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getDeviceTimezoneName,
  getEndOfDay,
  getStartOfDay,
  parseDateInLocalTime,
  TimeOptions,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import {
  getScreenActiveOperatingRules,
  isInOperatingHours,
  isLoadedInPairedDevice,
} from "../../../../utils";
import {
  requestScreenSuccess,
  updateScreenDeviceInfoSuccess,
} from "../../../../store/screen/actions";
import { isEmbedPlayerMode } from "../../../../utils/embedPlayerHelper";
import { useDispatch, useSelector } from "react-redux";
import { useManualQuery } from "graphql-hooks";
import { Alert } from "../../../core/components/Alert/Alert";
import { ConfigState } from "../../../../store/config/types";
import { EntityType } from "@screencloud/signage-firestore-client";
import { GenericViewer } from "../GenericViewer/GenericViewer";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { Loading } from "../../../core/components/Loading/Loading";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenContext } from "./context";
import { ScreenState } from "../../../../store/screen/types";
import { Space } from "../../../../store/spaces/types";
import isEqual from "lodash/isEqual";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { useLiveUpdate } from "../../../../store/liveUpdates/useLiveUpdateHook";
import { useRetry } from "../../../../utils/useRetry";
import { ContextConfig } from "../../../../store/config/types";
import debounce from "lodash/debounce";
import { OrganizationState } from "../../../../store/organization/types";
import { errorToStr } from "../../../../utils/errorToStr";
import { getCommitHash } from "../../../../utils/helpers";
import { grqphqlHooksErrorMessage } from "../../../../utils/graphqlHooksErrorMessage";
import {
  screenById,
  ScreenByIdQuery,
  currentScreen,
  CurrentScreenQuery,
  useUpdateScreenDevice,
  ScreenStatus,
} from "../../../../queries/";
import { Logger } from "../../../../logger/logger";

const log = new Logger("screenViewer");

interface ScreenViewerContainerProps {
  isRoot?: boolean;
}

export const ScreenViewerContainer: FunctionComponent<ScreenViewerContainerProps> = ({
  isRoot,
}: ScreenViewerContainerProps) => {
  const dispatch = useDispatch();
  const screenId = useSelector<PlayerState, string>((state) => state.screen.id);
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const screenStatus = useSelector<PlayerState, ScreenStatus | undefined>(
    (state) => state.screen.status
  );
  const org = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const space = useSelector<PlayerState, Space | undefined>((state) =>
    screen.spaceId ? state.spaces.byId[screen.spaceId] : undefined
  );
  const config = useSelector<PlayerState, ConfigState>((state) => state.config);
  const playbackControlOffset = useSelector<PlayerState, number>(
    (state) => state.playback.controls.timelineOffset
  );
  const timeOptions = useMemo<TimeOptions>(
    () => ({
      timezoneOverride: screen.deviceMeta.overrideTimezone,
      timeOffset: config.timeOffset,
      timelineControlOffset: playbackControlOffset,
    }),
    [
      screen.deviceMeta.overrideTimezone,
      config.timeOffset,
      playbackControlOffset,
    ]
  );
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.screen.isPreview
  );
  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );
  const spaceOperatingHours = space?.preferences?.operating;
  const screenOperatingHours = screen.operatingHours;

  const [isOperating, setIsOperating] = useState<boolean>(
    isInOperatingHours(
      timeOptions,
      undefined,
      screen.operatingHours,
      space?.preferences?.operating
    )
  );

  const contentPathScreenId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );
  // Get screen data via the contentPath sent from studio preview (screenById)
  // or via the js player on a paired device (getCurrentScreen)
  const [fetchScreen, { error, data }] = useManualQuery<
    ScreenByIdQuery | CurrentScreenQuery
  >(contentPathScreenId ? screenById : currentScreen, {
    useCache: false,
    skipCache: true,
    ...(contentPathScreenId ? { variables: { id: contentPathScreenId } } : {}),
  });

  // Fetch data on player load if it is a top level component (i.e isRoot)
  useInitialFetch(!!isRoot, fetchScreen);

  // Save screen data to state
  useEffect(() => {
    if (data) {
      const action = requestScreenSuccess(data);
      if (action !== undefined) {
        dispatch(action);
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      log.info("Request Screen data [SUCCESS]", {});
    }
  }, [data]);

  useEffect(() => {
    console.log(">>>> error2", error)
    if (error) {
      log.error(
        "Request Screen data [FAIL]",
        {
          errorMessage: grqphqlHooksErrorMessage(error),
        },
        true
      );
    }
  }, [error]);

  useEffect(() => {
    /**
     * need to init a remote logger here because need to wait to get the screen and org data from the backend
     * NOTE: a remote logger is not gonna work for standalone
     */

    // added check for both data && org?.id to ensuring that the required data, Org's featureflag
    // will be available during the initialize of RemoteLog
    // this help flush log afer initilize datadog passing the data to datadog correctly
    if (data && org?.id) {
      const screen =
        "currentScreen" in data
          ? data.currentScreen
          : (data as ScreenByIdQuery).screenById;
      // TODO need to disable a remote logging by checking other value instead of userInteractionEnabled
      if (
        !screen ||
        (contextConfig.userInteractionEnabled && !isEmbedPlayerMode())
      ) {
        return;
      }

      Logger.init(
        {
          clientToken: process.env.REACT_APP_DATADOG_TOKEN as string,
          version: screen.device.releaseVersion,
          env: process.env.REACT_APP_SC_ENV as string,
          proxyHost:
            screen.orgByOrgId?.preferences?.player?.player_log_config?.endpoint,
          orgId: screen.orgByOrgId?.id,
          screenId: screen.id,
          deviceId: screen.device.id,
          platform: screen.device.devicePlatform,
          timeOptions: timeOptions,
          graphqlToken: config.graphqlToken,
          lokiEndpoint: config.lokiEndpoint,
          spaceName: screen.spaceBySpaceId?.name,
          remoteLogEndPoint: process.env
            .REACT_APP_ENABLE_LOGS_ENDPOINT as string,
        },
        org.featureFlags // here is the featureflag update to taking control of logging enable / disable
      );
    }
  }, [
    data,
    contextConfig.userInteractionEnabled,
    timeOptions,
    org.featureFlags,
    config.graphqlToken,
    config.lokiEndpoint,
    org?.id,
  ]);

  // Report screen and device data for studio
  const [
    updateScreenDeviceJson,
    { data: updateDeviceData, error: updateDeviceError },
  ] = useUpdateScreenDevice();

  // Initial setting of screen device data when the player loads.
  const initializeScreenDeviceJsonCallback = useCallback(() => {
    const updatedDeviceJson = {
      ...screen.deviceMeta,
      player_width: window.innerWidth,
      player_height: window.innerHeight,
      deviceSystemTimezone: getDeviceTimezoneName(),
      releaseVersion: getCommitHash(),
    };

    if (
      !isEqual(screen.deviceMeta, updatedDeviceJson) &&
      isLoadedInPairedDevice(config.contentConfig)
    ) {
      updateScreenDeviceJson({
        variables: {
          input: {
            device: updatedDeviceJson,
          },
        },
      });
    }
  }, [updateScreenDeviceJson, screen.deviceMeta, config.contentConfig]);

  // Further updates of screen device data when the player's screen changes size.
  const updateScreenDeviceJsonCallback = useCallback(() => {
    const updatedDeviceJson = {
      player_width: window.innerWidth,
      player_height: window.innerHeight,
      deviceSystemTimezone: getDeviceTimezoneName(),
      releaseVersion: getCommitHash(),
    };

    if (
      (screen.deviceMeta.player_width !== updatedDeviceJson.player_width ||
        screen.deviceMeta.player_height !== updatedDeviceJson.player_height) &&
      isLoadedInPairedDevice(config.contentConfig)
    ) {
      updateScreenDeviceJson({
        variables: {
          input: {
            device: updatedDeviceJson,
          },
        },
      });
    }
  }, [updateScreenDeviceJson, screen.deviceMeta, config.contentConfig]);

  useEffect(() => {
    if (updateDeviceData?.updateSelfScreenDevice?.screen) {
      dispatch(
        updateScreenDeviceInfoSuccess(
          updateDeviceData.updateSelfScreenDevice.screen.device
        )
      );
    }
  }, [updateDeviceData, dispatch]);

  useRetry(updateDeviceError !== undefined, initializeScreenDeviceJsonCallback);

  // Only report to studio once we have the correct screenId
  useEffect(() => {
    if (screen.deviceMeta.screenId !== "") {
      initializeScreenDeviceJsonCallback();
    }
  }, [screen.deviceMeta.screenId, initializeScreenDeviceJsonCallback]);

  // Report studio size to studio
  useEffect(() => {
    const callback = debounce(updateScreenDeviceJsonCallback, 500);
    window.addEventListener("resize", callback);

    return (): void => {
      window.removeEventListener("resize", callback);
    };
  }, [updateScreenDeviceJsonCallback]);

  // Set timeout to check and update `isOperating` state according to current day's setting or at the end of
  //  current day
  useEffect(() => {
    const targetOperatingDayRules: OperatingDay | null = getScreenActiveOperatingRules(
      screenOperatingHours,
      spaceOperatingHours
    );

    let operatingHoursTimeout: number | null = null;

    const setOperatingTimeout = (): void => {
      if (targetOperatingDayRules === null) {
        // screen is always on and no need to update operating state
        return;
      }

      const nowDate = parseDateInLocalTime(
        timeOptions,
        playbackNowTimestamp(timeOptions)
      );
      const endOfDay = getEndOfDay(timeOptions);
      const startOfDay = getStartOfDay(timeOptions);

      const timeoutVariants: number[] = [endOfDay];

      // below we're checking if operating hours have end and start setting and then figuring out what is the next
      //  closest point in time to check if screen should be active

      const targetDayStart = targetOperatingDayRules?.[nowDate.weekDay]?.start;
      if (targetDayStart !== undefined) {
        const targetHoursStartTimestamp = startOfDay + targetDayStart * 1000;
        if (targetHoursStartTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursStartTimestamp);
        }
      }

      const targetDayEnd = targetOperatingDayRules?.[nowDate.weekDay]?.end;
      if (targetDayEnd !== undefined) {
        const targetHoursEndTimestamp = startOfDay + targetDayEnd * 1000;
        if (targetHoursEndTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursEndTimestamp);
        }
      }

      const timeoutValue = Math.min(...timeoutVariants) - nowDate.timestamp;

      operatingHoursTimeout = window.setTimeout(() => {
        const newIsOperating = isInOperatingHours(
          timeOptions,
          undefined,
          screenOperatingHours,
          spaceOperatingHours
        );
        if (newIsOperating !== isOperating) {
          setIsOperating(newIsOperating);
        } else {
          setOperatingTimeout();
        }
      }, timeoutValue);
    };

    setOperatingTimeout();

    return (): void => {
      if (operatingHoursTimeout !== null) {
        window.clearTimeout(operatingHoursTimeout);
      }
    };
  }, [
    config,
    screenOperatingHours,
    spaceOperatingHours,
    isOperating,
    timeOptions,
  ]);

  useEffect(() => {
    const newIsOperating = isInOperatingHours(
      timeOptions,
      undefined,
      screenOperatingHours,
      spaceOperatingHours
    );
    if (newIsOperating !== isOperating) {
      setIsOperating(newIsOperating);
    }
  }, [timeOptions, screenOperatingHours, spaceOperatingHours, isOperating]);

  // the reason to use hook here directly instead of <LiveUpdateConnector /> is 2 options for an actual graphql query
  //  otherwise would be the same <LiveUpdateConnector/> component which uses screenById query by default
  useLiveUpdate(EntityType.SCREEN, screenId, fetchScreen);

  console.log(">>> error", error)
  console.log(">>> screenId", screenId)
  if (error && !screenId) {
    console.log(`Failed to fetch screen data`, errorToStr(error));
    return <p>Sorry, we can&apos;t load the screen.</p>;
  }

  let view: ReactNode;
  if (!isOperating) {
    view = null;
  } else if (!screen.activeContentItem) {
    view = <Loading />;
  }
  // screen is deactivated
  else if (screenStatus === "PAUSED") {
    view = <Alert message={DEACTIVATE_SCREEN_MESSAGE} />;
  } else if (isPreview) {
    // For Trial screen, we still need to show the content in the background so need to have GenericViewer before Alert component
    view = (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          <GenericViewer contentItem={screen.activeContentItem} />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Alert message={PREVIEW_SCREEN_MESSAGE} />
        </div>
      </>
    );
  } else {
    view = <GenericViewer contentItem={screen.activeContentItem} />;
  }
  return (
    <ScreenContext.Provider
      value={{
        timezoneOverride: screen.deviceMeta.overrideTimezone,
      }}
    >
      {screen.spaceId ? (
        <LiveUpdateConnector
          entityId={screen.spaceId}
          entityType={EntityType.SPACE}
        />
      ) : null}
      {view}
    </ScreenContext.Provider>
  );
};
