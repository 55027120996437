/**
 * Is this a local dev build?
 */
export const isLocalDev = (): boolean => {
  return process.env.REACT_APP_SC_ENV === "development";
};

export const isStorePersistDisabledFlag = (): boolean => {
  const urlParams = new window.URLSearchParams(window.location.search);
  return urlParams.has("disable_store_persist");
};
