import { useEffect, useState } from "react";
import { HOUR_DURATION_MS } from "../constants";

const MAX_RETRY_TIME = HOUR_DURATION_MS / 2;

export const useRetry = (
  hasError: boolean,
  retryCallback: () => unknown
): void => {
  const [retryCount, setRetryCount] = useState<number>(0);
  useEffect(() => {
    if (hasError) {
      const timeoutMsExponential = Math.pow(2, retryCount) * 1000 * 10; // multiply by 10 seconds
      const timeoutMs =
        timeoutMsExponential > MAX_RETRY_TIME
          ? MAX_RETRY_TIME
          : timeoutMsExponential;

      const retryTimeout: number = window.setTimeout(() => {
        setRetryCount(retryCount + 1);

        retryCallback();
      }, timeoutMs);

      return (): void => {
        window.clearTimeout(retryTimeout);
      };
    } else {
      setRetryCount(0);
    }
  }, [hasError, retryCount, retryCallback]);
};
