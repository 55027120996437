import { ContentViewItem } from "../types/content";
import { ContentConfig } from "../store/config/types";
import { DEFAULT_CONTENT_PATH } from "../constants";

/**
 * Gets root content item, assigned to a screen (via contentConfig object from state)
 */
export function getRootContent(contentConfig: ContentConfig): ContentViewItem {
  const fallbackId = DEFAULT_CONTENT_PATH;

  switch (contentConfig.type) {
    case "screen":
    case "channel":
    case "playlist":
      return {
        type: contentConfig.type,
        id: contentConfig.id || fallbackId,
      };
    case "app":
      return {
        type: contentConfig.type,
        id: contentConfig.id || fallbackId,
        fullDurationMs: Infinity, //Note: Infinity gets transformed to null when sent through PMI. JSON.stringify({fullDurationMs: Infinity}) === "{"fullDurationMs":null}"
      };
    case "editor":
      return {
        type: contentConfig.type,
        id: contentConfig.id || fallbackId,
        fullDurationMs: Infinity, //Note: Infinity gets transformed to null when sent through PMI. JSON.stringify({fullDurationMs: Infinity}) === "{"fullDurationMs":null}"
      };
  }
}
