import { produce } from "immer";
import { makeContentListIdForZone } from "../contentLists/utils";
import { GqlLayoutConfigJson } from "../graphqlTypes";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import { Layout, LayoutsState, REQUEST_LAYOUT_SUCCESS, Zone } from "./types";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import isEqual from "lodash/isEqual";
import { LayoutFragment } from "../../queries";

/**
 * Data about the available app instances.
 */
const initialState: LayoutsState = {
  byId: {},
};

export function layoutsReducer(
  state = initialState,
  action: PlayerAction
): LayoutsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_LAYOUT_SUCCESS:
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const layouts = action.payload.layouts;
        const ids = Object.keys(layouts);
        ids.forEach((id) => {
          if (layouts[id]) {
            const newLayout = mapGqlLayoutToPlayerLayout(layouts[id]);
            if (!isEqual(state.byId[id], newLayout)) {
              draft.byId[id] = newLayout;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlLayoutToPlayerLayout = (data: LayoutFragment): Layout => {
  const zones: Zone[] = (data.config as GqlLayoutConfigJson).zones.map(
    (zone) => {
      return {
        contentListId: makeContentListIdForZone(data.id, zone.id),
        id: zone.id,
        name: zone.name,
      };
    }
  );

  return {
    css: data.config.css,
    height: data.height ?? 1080,
    id: data.id,
    isFlexible: data.isFlexible ?? true,
    isScalable: data.isScalable ?? false,
    name: data.config.name,
    width: data.width ?? 1920,
    zones,
  };
};
