import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Logger } from "../../../../logger/logger";

const log = new Logger("audioViewer");

interface AudioViewerProps {
  src: string;
  isPreload: boolean;
  initialPlaybackPositionMs: number; // where should the audio start it's playback
  loop?: boolean;
  fileName: string;
}

export const AudioViewer = memo((props: AudioViewerProps): ReactElement<
  AudioViewerProps
> | null => {
  const { isPreload, loop, src, initialPlaybackPositionMs } = props;
  const audioElementRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (!isPreload && audioElement) {
      const initialPlaybackPositionSec = initialPlaybackPositionMs / 1000;
      audioElement.currentTime = initialPlaybackPositionSec;
      const playPromise = audioElement.play();

      // audio element's api is not implemented in jsdom, so we add this condition to avoid failures in test environment
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            log.info(`Play Audio ${audioElement.src}`, {
              audioId: audioElement.id,
              name: audioElement.title,
              contentType: "audio",
              isPreload: props.isPreload,
            }); // This promise resolve is added just to get a warning when play fails (i.e. autoplay is blocked)
          })
          .catch((error) => {
            // handled the error to prevent sentry to capture this
            log.error("caught error playing audio file", error);
          });
      }
    }

    return (): void => {
      audioElement?.pause();
    };
  }, [isPreload, initialPlaybackPositionMs]);

  const onError = useCallback(
    (event) => {
      const error = (event.target as { error?: MediaError }).error;

      log.error(
        `Audio element error ${event}`,
        {
          src,
          errorMessage: error?.message,
          errorCode: error?.code,
        },
        true
      );
    },
    [src]
  );

  return (
    <audio
      ref={audioElementRef}
      controls
      src={src}
      loop={loop ?? true}
      title={props.fileName}
      onError={onError}
      data-testid="audio-viewer"
    />
  );
});
AudioViewer.displayName = "AudioViewer";
