import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  memo,
  useCallback,
} from "react";
import styles from "./VideoViewer.module.css";
import { videoPlayerManager } from "../../../../videoPlayerManager";
import {
  VideoPlayer,
  VideoPlayerInputConfiguration,
} from "@screencloud/persistent-video-player";
import { ContentSizeType } from "../../../../types/content";
import {
  VideoFile,
  VideoStreamingMimetypesPriorityList,
} from "../../../../store/files/types";
import { canPlayUnmutedMedia } from "../../../../utils/autoplay";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { VideoPlayer as OldVideoPlayer } from "@screencloud/signage-player-components";
import { OrganizationState } from "../../../../store/organization/types";
import { ScreenState } from "../../../../store/screen/types";
import { Logger } from "../../../../logger/logger";

const log = new Logger("videoViewer");

const e2eTestIdentifier = "video-viewer";

interface StreamingVideoProps {
  src: string;
  isPreload: boolean;
  muted: boolean;
  controls: boolean;
  loop: boolean;
  sizeStyle: string;
  playbackPositionMs: number;
  sizeType?: ContentSizeType;
  organization?: OrganizationState;
}

const StreamingVideo = memo(
  (props: StreamingVideoProps): ReactElement<StreamingVideoProps> => {
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>();
    let attemptCount = 1;
    const retryLimit = 3;
    const retryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [reMountKey, setReMountKey] = useState<number | undefined>(
      new Date().getTime()
    );
    const {
      src,
      isPreload,
      muted,
      loop,
      sizeType,
      controls,
      playbackPositionMs,
    } = props;
    useEffect(() => {
      const setup = async (): Promise<void> => {
        if (!videoContainerRef.current) return;
        if (videoPlayer) return;

        const is4KVideoDemanded = true; // TODO: this will come from studio backend when it is implemented
        const Opts4K: VideoPlayerInputConfiguration = {
          minResolution: "2160p", // request video player to play minimum resolution above this if exists, otherwise play the highest resolution
        };

        // cleanup cannot be async (i.e. cannot block until player is destroyed)
        // hence we are using the utility function from video player that makes
        // sure to wait until the previous player on the same video element is destroyed if there was any.
        const newPlayer = await videoPlayerManager.addPlayerSafeAsync(
          videoContainerRef.current,
          {
            loop: true,
            ...(is4KVideoDemanded ? Opts4K : {}),
          }
        );
        setVideoPlayer(newPlayer);
      };

      setup();

      return (): void => {
        videoPlayer?.destroy();
      };
    }, [loop, videoPlayer]);

    const screen = useSelector<PlayerState, ScreenState>(
      (state) => state.screen
    );

    useEffect(() => {
      const startTimeSec = playbackPositionMs / 1000;

      videoPlayer
        ?.load(startTimeSec)
        .then(() => {
          if (!isPreload) {
            // handled error if video play request was interrupted
            return videoPlayer.play().catch((error) => {
              log.warn(error, {
                videoViewerName: VideoViewer.displayName,
                id: videoContainerRef.current?.id,
                screen: screen.id,
              });
            });
          }
        })
        .catch((err) => {
          log.error(
            `Cannot load or play video ${src} ${err?.message}`,
            {
              errormessage: err?.message,
              id: videoContainerRef?.current?.id,
              name: VideoViewer.name,
              screen: screen.id,
            },
            true
          );
        });
    }, [screen.id, isPreload, videoPlayer, src, playbackPositionMs]);

    useEffect(() => {
      return () => {
        if (retryTimeout.current !== null) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
        }
      };
    }, []);

    const onError = useCallback(
      (event?: unknown) => {
        const errorEvent = event as
          | { target: { error?: MediaError } }
          | undefined;

        const error = errorEvent?.target?.error;

        const timeoutMs = attemptCount * 3 * 1000;
        if (retryTimeout.current !== null) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
        }
        if (error) {
          log.error(
            `Cannot load or play video ${src}, error code ${error?.code}`,
            { errorMessage: error?.message, errorCode: error?.code },
            true
          );
        } else {
          log.error(
            `Cannot load or play video ${src}: ${errorEvent}`,
            {
              errormessage: errorEvent?.target.error?.message,
              name: VideoViewer.name,
              videoContainerId: videoContainerRef?.current?.id,
            },
            true
          );
        }

        if (attemptCount <= retryLimit) {
          retryTimeout.current = setTimeout(() => {
            attemptCount++;
            setReMountKey(new Date().getTime());
            log.info(
              `Retry reload video attemptCount[${attemptCount}] ${src}`,
              { screenId: screen.id }
            );
          }, timeoutMs);
        } else {
          log.error(
            `Reach limit retry reload video ${src}`,
            {
              screenId: screen.id,
            },
            true
          );
        }
      },
      [src, attemptCount, screen.id]
    );

    return (
      <div
        ref={videoContainerRef}
        className={`${e2eTestIdentifier} ${styles.persiePlayer}`}
        id={e2eTestIdentifier}
        aria-label={e2eTestIdentifier}
        data-src={src}
      >
        <OldVideoPlayer
          src={src}
          key={reMountKey}
          muted={muted}
          controls={controls}
          // sizingType={sizeStyle as ContentSizeType} - Switch this back over to class name when we use the new video player again
          sizingType={sizeType}
          onError={onError}
        />
      </div>
    );
  }
);
StreamingVideo.displayName = "StreamingVideo";

interface SimpleVideoProps {
  src: string;
  isPreload: boolean;
  muted: boolean;
  controls: boolean;
  loop: boolean;
  sizeStyle: string;
  playbackPositionMs: number;
  sizeType?: ContentSizeType;
}

const SimpleVideo = memo(
  (props: SimpleVideoProps): ReactElement<SimpleVideoProps> => {
    let attemptCount = 1;
    const retryLimit = 3;
    const videoElementRef = useRef<OldVideoPlayer | null>(null);
    const retryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [reMountKey, setReMountKey] = useState<number | undefined>(
      new Date().getTime()
    );
    const {
      src,
      isPreload,
      muted,
      loop,
      controls,
      sizeType,
      playbackPositionMs,
    } = props;

    useEffect(() => {
      if (!isPreload) {
        videoElementRef.current?.play();
      }
    }, [isPreload]);

    const screen = useSelector<PlayerState, ScreenState>(
      (state) => state.screen
    );
    useEffect(() => {
      return () => {
        if (retryTimeout.current !== null) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
        }
      };
    }, []);

    const onError = useCallback(
      (event?: unknown) => {
        const errorEvent = event as
          | { target: { error?: MediaError } }
          | undefined;

        const error = errorEvent?.target?.error;

        const timeoutMs = attemptCount * 3 * 1000;
        if (retryTimeout.current !== null) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
        }
        if (error) {
          log.error(
            `Cannot load or play video ${src}, error code ${error?.code}`,
            {
              errorMessage: error?.message,
              errorCode: error?.code,
              screenid: screen.id,
            },
            true
          );
        } else {
          log.error(
            `Cannot load or play video ${src}: ${errorEvent}`,
            {
              errormessage: errorEvent?.target?.error?.message,
              screenid: screen.id,
              errorCode: errorEvent?.target?.error?.code,
            },
            true
          );
        }

        if (attemptCount <= retryLimit) {
          retryTimeout.current = setTimeout(() => {
            attemptCount++;
            setReMountKey(new Date().getTime());
            log.info(
              `Retry reload video attemptCount[${attemptCount}] ${src}`,
              { screenId: screen.id }
            );
          }, timeoutMs);
        } else {
          log.error(
            `Reach limit retry reload video ${src}`,
            {
              screenId: screen.id,
            },
            true
          );
        }
      },
      [src, attemptCount, screen.id]
    );

    return (
      <div
        className={`${e2eTestIdentifier} ${styles.persiePlayer}`}
        id={e2eTestIdentifier}
        aria-label={e2eTestIdentifier}
        data-testid="simple-video"
      >
        <OldVideoPlayer
          ref={videoElementRef}
          src={src}
          autoPlay={false}
          loop={loop}
          muted={muted}
          preload="auto"
          controls={controls}
          // sizingType={sizeStyle as ContentSizeType} - Switch this back over to class name when we use the new video player again
          sizingType={sizeType}
          initialPlaybackPositionMs={playbackPositionMs}
          onError={onError}
          key={reMountKey}
        />
      </div>
    );
  }
);
SimpleVideo.displayName = "SimpleVideo";

interface VideoViewerProps {
  src: string;
  file: VideoFile;
  isPreload: boolean;
  initialPlaybackPositionMs: number; // where should the video start it's playback
  userInteractionEnabled: boolean;
  loop?: boolean;
  sizeType?: ContentSizeType;
}
export const VideoViewer = memo(
  (props: VideoViewerProps): ReactElement<VideoViewerProps> => {
    const {
      isPreload,
      file: { mimetype },
      src,
      sizeType,
      initialPlaybackPositionMs,
      loop = true, // default is true since we want the video to loop until timeline decides to unmount the component
      userInteractionEnabled,
    } = props;

    const isStreaming = VideoStreamingMimetypesPriorityList.includes(mimetype);

    const organization = useSelector<PlayerState, OrganizationState>(
      (state) => state.organization || {}
    );
    const muted = !canPlayUnmutedMedia();

    const controls = userInteractionEnabled && muted;

    const sizeStyle = sizeType === "fill" ? styles.videoFill : styles.videoFit;

    useEffect(() => {
      log.info(`Show Video ${props.file.urlKey}`, {
        fileId: props.file.id,
        name: props.file.name,
        contentType: props.file.mimetype,
        isPreload: props?.isPreload,
      });
    }, [props.file.id, props.isPreload]);

    if (!src) return <></>;

    return isStreaming ? (
      <StreamingVideo
        src={src}
        isPreload={isPreload}
        muted={muted}
        controls={controls}
        loop={loop}
        sizeStyle={sizeStyle}
        sizeType={sizeType}
        organization={organization}
        playbackPositionMs={initialPlaybackPositionMs}
      />
    ) : (
      // todo: add support for playback position here
      <SimpleVideo
        src={src}
        isPreload={isPreload}
        muted={muted}
        controls={controls}
        loop={loop}
        sizeStyle={sizeStyle}
        sizeType={sizeType}
        playbackPositionMs={initialPlaybackPositionMs}
      />
    );
  }
);
VideoViewer.displayName = "VideoViewer";
