import React, { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./MainPanel.module.css";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";
import EmptyMenu from "../../../../img/empty-menu.svg";
import SearchFilter from "../SidePanel/SearchFilter";

interface Props {
  currentIndex: number;
  search: string;
  setSearch: (search: string) => void;
}

const MainPanel: FunctionComponent<Props> = ({
  currentIndex,
  search,
  setSearch,
}: Props) => {
  const { items, filteredItems, navigateToItem } = useEmbedMenu();

  return (
    <div className={styles.root} data-testid="main-panel">
      <SearchFilter search={search} setSearch={setSearch} />

      {filteredItems.length <= 0 ? (
        <div
          className={styles.emptyContainer}
          data-testid="empty-filtered-item"
        >
          <img src={EmptyMenu} alt="Empty menu" className={styles.emptyIcon} />
          <p>Nothing to see here.</p>
          <p className={styles.subText}>Please amend your search filters</p>
        </div>
      ) : (
        <div className={styles.grid} data-testid="filtered-items-list">
          {filteredItems.map((item, index) =>
            item.type !== "void" ? (
              <div
                onClick={() =>
                  navigateToItem(
                    items.findIndex((element) => {
                      if (element.type !== "void") {
                        return element.listId === item.listId;
                      }
                      return false;
                    })
                  )
                }
                key={item.id + index}
                className={classNames({
                  [styles.gridItem]: true,
                  [styles.selectedGridItem]:
                    items.findIndex((element) => element === item) ===
                    currentIndex,
                })}
              >
                <div className={styles.itemIcon}>
                  {item.thumbnail ? (
                    <img src={item.thumbnail} alt={item.type} />
                  ) : null}
                </div>
                <div className={styles.itemLabel}>
                  <p>{item.name}</p>
                </div>
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default MainPanel;
