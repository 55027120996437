import RemoteApi from "../remoteApi";
import { LogLevel } from "./logger";
import { Context } from "@datadog/browser-core";

export class PMILogger {
  private remoteApi = new RemoteApi();

  pmsLogger(level: LogLevel, message: string, context?: Context): void {
    this.remoteApi?.remote.fire("STUDIO_PLAYER_LOGS", [
      level,
      message,
      context,
    ]);
  }

  getlogger() {
    return this.pmsLogger;
  }
}
