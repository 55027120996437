import { ReactNode } from "react";
import { GqlZoneItemTransition } from "../../../../store/graphqlTypes";

export enum TransitionTypeInternal {
  None = "none",
  Fade = "fade",
  Dissolve = "dissolve",
  SlideDown = "slidedown",
  SlideUp = "slideup",
  SlideLeft = "slideleft",
  SlideRight = "slideright",
  FlipX = "flipx",
  FlipY = "flipy",
  BounceCenter = "bounceCenter",
  BounceDown = "bounceDown",
  BounceUp = "bounceUp",
  BounceLeft = "bounceLeft",
  BounceRight = "bounceRight",
  RotateCounterclockwise = "rotateCounterclockwise",
  RotateClockwise = "rotateClockwise",
  ZoomCenter = "zoomCenter",
  ZoomDown = "zoomDown",
  ZoomUp = "zoomUp",
  ZoomLeft = "zoomLeft",
  ZoomRight = "zoomRight",
  RollDown = "rollDown",
  RollUp = "rollUp",
  RollLeft = "rollLeft",
  RollRight = "rollRight",
  JackInTheBox = "jackinthebox",
}

export type Transition = {
  [key in TransitionTypeInternal]: {
    in: string;
    out: string;
    isCross: boolean;
    isColor: boolean;
  };
};

export enum TransitionStatus {
  in = "in",
  out = "out",
}

export interface Props {
  transitionProps: GqlZoneItemTransition;
  transitionStatus?: TransitionStatus;
  className?: string;
  children: ReactNode;
}
