import React, { ReactElement } from "react";

import Lottie from "./LottieWrapper";
import animation from "./sc-preloader-animation.json";
import styles from "./Loading.module.css";

export const Loading = (): ReactElement => {
  return (
    <div data-testid="loading" className={styles.wrapper}>
      <div>
        <div>
          <Lottie
            options={{
              animationData: animation,
            }}
          />
        </div>
        <div className={styles.loaderTextContainer}>
          <p className={styles.loaderText}>Building your content</p>
        </div>
      </div>
    </div>
  );
};
