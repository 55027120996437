const ChannelFragment = `
  fragment Channel on Channel {
    id
    name
    content
    width
    height
    publishedAt
    layoutByChannel {
      ...Layout
    }
    appInstancesByChannelId {
      nodes {
        ...AppInstance
      }
    }
    playlistsByChannelId {
      nodes {
        ...Playlist
      }
    }
    filesByChannelId {
      nodes {
        ...File
      }
    }
    linksByChannelId {
      nodes {
        ...Link
      }
    }
    sitesByChannelId {
      nodes {
        ...Site
      }
    }
    themeByThemeId {
      ...Theme
    }
  }
`;

const AppInstanceFragment = `
  fragment AppInstance on AppInstance {
    id
    name
    appInstallId
    config
    filesByAppInstanceId {
      nodes {
        ...File
      }
    }
    fileByThumbnailFileId {
      ...File
    }
    spaceId
    state
    version
    appVersionByAppInstanceId {
      ...AppVersion
    }
    appByAppId {
      iconUrl
      scrn
    }
  }
`;

const AppVersionFragment = `
  fragment AppVersion on AppVersion {
    id
    appId
    version
    viewerUrl
  }
`;

const FileFragment = `
  fragment File on File {
    id
    orgId
    name
    availableAt
    expireAt
    fileProcessingStatus
    mimetype
    source
    metadata
    fileOutputsByFileId {
      nodes {
        ...FileOutput
      }
    }
  }
`;

const FileOutputFragment = `
  fragment FileOutput on FileOutput {
    id
    content
    metadata
    mimetype
    url
  }
`;

const PlaylistFragment = `
  fragment Playlist on Playlist {
    id
    name
    content
    publishedAt
    filesByPlaylistId {
      nodes {
        ...File
      }
    }
    linksByPlaylistId {
      nodes {
        ...Link
      }
    }
    appInstancesByPlaylistId {
      nodes {
        ...AppInstance
      }
    }
    sitesByPlaylistId {
      nodes {
        ...Site
      }
    }
  }
`;

const LinkFragment = `
  fragment Link on Link {
    id
    name
    url
    linkType
    cloudConfig
    fileByFileId {
      ...File
    }
  }
`;

const CastFragment = `
  fragment Cast on Cast {
    id
    priority
    content
    appInstanceByCastId {
      ...AppInstance
    }
    fileByCastId {
      ...File
    }
    linkByCastId {
      ...Link
    }
    siteByCastId {
      ...Site
    }
    playlistByCastId {
      ...Playlist
    }
    channelByCastId {
      ...Channel
    }
  }
`;

const ThemeFragment = `
  fragment Theme on Theme {
    id
    name
    fileByLogoFileId {
      id
      name
      mimetype
      source
    }
    config
  }
`;

const LayoutFragment = `
  fragment Layout on Layout {
    id
    zones
    config
    width
    height
    isFlexible
    isScalable
  }
`;

const OrgFragment = `
  fragment Org on Org {
    id
    brandChannelId
    themeByDefaultChannelThemeId {
      ...Theme
    }
    featureFlagsCache
    preferences
  }
`;

const ScreenFragment = `
  fragment Screen on Screen {
    id
    content
    status
    preview
    device
    env
    preferences
    orgByOrgId {
      id
      brandChannelId
      themeByDefaultChannelThemeId {
        ...Theme
      }
      featureFlagsCache
      preferences
    }
    channelsByScreenId {
      nodes {
        ...Channel
      }
    }
    appInstancesByScreenId {
      nodes {
        ...AppInstance
      }
      
    }
    filesByScreenId {
      nodes {
        ...File
      }
    }
    linksByScreenId {
      nodes {
        ...Link
      }
    }
    playlistsByScreenId {
      nodes {
        ...Playlist
      }
    }
    castByCastId {
      ...Cast
    }
    spaceBySpaceId {
      ...Space
    }
    sitesByScreenId {
      nodes {
        ...Site
      }
    }
  }
`;

const SpaceFragment = `
  fragment Space on Space {
    id
    name
    preferences
  }
`;

const SiteFragment = `
  fragment Site on Site {
    id
    name
    url
    actions
    config
    type
    fileByThumbnailId {
      ...File
    }
  }
`;

// TODO - I'm sure there's a nicer way to write these :)
// But if we have very few queries in the end, this may be fine?
export const GET_CURRENT_SCREEN = `
  query currentScreen {
    currentScreen {
     ...Screen
    }
  }

  ${ChannelFragment}
  ${AppInstanceFragment}
  ${AppVersionFragment}
  ${FileFragment}
  ${FileOutputFragment}
  ${PlaylistFragment}
  ${LinkFragment}
  ${CastFragment}
  ${ThemeFragment}
  ${LayoutFragment}
  ${ScreenFragment}
  ${SpaceFragment}
  ${SiteFragment}
`;

export const GET_SCREEN_BY_ID = `query screenById($id: UUID!) {
    screenById(id:$id) {
      ...Screen
    }
  } 

  ${ChannelFragment}
  ${AppInstanceFragment}
  ${AppVersionFragment}
  ${FileFragment}
  ${FileOutputFragment}
  ${PlaylistFragment}
  ${LinkFragment}
  ${CastFragment}
  ${ThemeFragment}
  ${LayoutFragment}
  ${ScreenFragment}
  ${SpaceFragment}
  ${SiteFragment}
`;

export const GET_APP_INSTANCE = `
  query appInstanceById($id: UUID!) {
    appInstanceById(id:$id) {
      ...AppInstance
      orgId
      fileByThumbnailFileId {
        ...File
    }
      filesByAppInstanceId {
        nodes {
          ...File
        }
      }
      appVersionByAppInstanceId {
        ...AppVersion
        appByAppId {
          id
          name
          iconUrl
        }
      }
    }
  }
  ${AppInstanceFragment}
  ${FileFragment}
  ${FileOutputFragment}
  ${AppVersionFragment}
`;

export const GET_CHANNEL = `
  query channelById($id: UUID!) {
    channelById(id:$id) { 
      ...Channel
    }
  }

  ${ChannelFragment}
  ${LayoutFragment}
  ${AppInstanceFragment}
  ${AppVersionFragment}
  ${PlaylistFragment}
  ${LinkFragment}
  ${FileFragment}
  ${FileOutputFragment}
  ${ThemeFragment}
  ${SiteFragment}
  ${SpaceFragment}
  ${OrgFragment}
`;

export const GET_PLAYLIST = `
  query playlistById($id: UUID!) {
    playlistById(id:$id) { 
      ...Playlist
    }
  }
  ${PlaylistFragment}
  ${AppInstanceFragment}
  ${AppVersionFragment}
  ${LinkFragment}
  ${FileFragment}
  ${FileOutputFragment}
  ${SiteFragment}
  ${SpaceFragment}
  ${OrgFragment}
  ${ThemeFragment}
`;

export const GET_FILE = `
  query fileById($id: UUID!) {
    fileById(id: $id) {
      ...File
    }
  }
  ${FileFragment}
`;

export const GET_SPACE = `
  query($id: UUID!) {
    spaceById(id: $id) {
      ...Space
    }
  }
  ${SpaceFragment}
`;

export const GET_LAYOUT = `
  query layoutById($id: UUID!) {
    layoutById(id: $id) {
      ...Layout
    }
  }
  ${LayoutFragment}
`;

export const GET_LINK = `
  query linkById($id: UUID!) {
    linkById(id: $id) {
      ...Link
    }
  }
  ${LinkFragment}
`;

export const UPDATE_SCREEN_DEVICE_INFO = `
  mutation UpdateScreenDevice($input: UpdateSelfScreenDeviceInput!) {
    updateSelfScreenDevice(input: $input) {
      screen {
        id
        device
        updatedAt
      }
    }
  }
`;

export const GET_SITE = `
  query siteById($id: UUID!) {
    siteById(id: $id) {
      ...Site
    }
  }
  ${SiteFragment}
`;

export const CREATE_APP_TOKEN = `
  mutation createAppToken($input: RuntimeJwtRequestInput!) {
    createSignedRuntimeJwt(input: $input) {
      signedRuntimeToken
      tokenType
      expiresAt
    }
  }
`;
