import { REQUEST_SPACE_SUCCESS, SpacesState } from "./types";
import { PlayerAction } from "../storeTypes";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import produce from "immer";
import isEqual from "lodash/isEqual";

export const initialState: SpacesState = { byId: {} };

export const spacesReducer = (
  state: SpacesState = initialState,
  action: PlayerAction
): SpacesState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SPACE_SUCCESS:
      case REQUEST_SCREEN_SUCCESS:
        const spaces = action.payload.spaces;
        const ids = Object.keys(spaces);

        ids.forEach((id) => {
          if (!isEqual(draft.byId[id], spaces[id])) {
            draft.byId[id] = spaces[id];
          }
        });
        break;

      default:
        return draft;
    }
  });
};
