import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import {
  REQUEST_SCREEN_SUCCESS,
  ScreenState,
  SET_SCREEN_ACTIVE_ITEM,
  UPDATE_SCREEN_DEVICE_JSON_SUCCESS,
} from "./types";

import { PlayerAction } from "../storeTypes";
import isEqual from "lodash/isEqual";

/**
 * Data about the current screen itself.
 * Assumed the player is only ever running 1 screen at a time.
 */
const initialState: ScreenState = {
  id: "",
  spaceId: "",
  operatingHours: undefined,
  deviceMeta: {
    id: "",
    settings: {
      name: "",
      updatedAt: 0,
      orientation: "landscape",
    },
    player_width: 0,
    player_height: 0,
    releaseVersion: "",
    deviceSystemTimezone: "",
    screenId: "",
    connection: "",
    deviceModel: "",
    connected_at: 0,
    devicePlatform: "",
    disconnected_at: 0,
  },
  status: "PENDING",
  isPreview: false,
};

export function screenReducer(
  state = initialState,
  action: PlayerAction
): ScreenState {
  switch (action.type) {
    case SET_SCREEN_ACTIVE_ITEM: {
      const updatedState = {
        ...state,
        activeContentItem: action.payload,
      };
      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case REQUEST_PLAYLIST_SUCCESS:
    case REQUEST_CHANNEL_SUCCESS:
      const updatedState = {
        ...state,
        spaceId: action.payload.space?.id || null,
      };
      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    case REQUEST_SCREEN_SUCCESS: {
      const updatedState = {
        ...state,
        ...action.payload.screen,
      };
      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case UPDATE_SCREEN_DEVICE_JSON_SUCCESS: {
      const updatedState = {
        ...state,
        deviceMeta: {
          ...state.deviceMeta,
          ...action.payload.device,
        },
      };

      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }

    default:
      return state;
  }
}
