import cx from "classnames";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mimetype } from "../../../../store/files/types";
import { ContentSizeType } from "../../../../types/content";
import { ElementSize } from "../../../../utils/helpers";
import styles from "./ImageViewer.module.css";
import { Logger } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenState } from "../../../../store/screen/types";

const log = new Logger("imageViewer");

interface ImageProps {
  src: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  name: string;
  mimetype?: Mimetype;
}

interface ImageViewerProps {
  src: string;
  name: string;
  mimetype?: Mimetype;
  urlKey?: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
}

export const Image = (props: ImageProps): ReactElement<ImageProps> | null => {
  let attemptCount = 1;
  const retryLimit = 3;
  const [isPreloaded, setIsPreloaded] = useState<boolean>(false);
  const [reMountKey, setReMountKey] = useState<number | undefined>(
    new Date().getTime()
  );
  const { sizeType, src, name, mimetype, isPreload } = props;
  const retryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    let isDismissed = false;

    if (!isPreloaded && src !== undefined && src !== "") {
      window
        .fetch(src)
        .then(() => {
          if (isDismissed) {
            return;
          }
          setIsPreloaded(true);
        })
        .catch(() => {
          if (isDismissed) {
            return;
          }
          // ignore error and try render dom element
          setIsPreloaded(true);
        });
    }

    return (): void => {
      isDismissed = true;
    };
  }, [src, isPreloaded]);

  const sizeStyle = sizeType === "fill" ? styles.imageFill : styles.imageFit;
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  useEffect(() => {
    return () => {
      if (retryTimeout.current !== null) {
        clearTimeout(retryTimeout.current);
        retryTimeout.current = null;
      }
    };
  }, []);

  const onError = useCallback(() => {
    const timeoutMs = attemptCount * 3 * 1000;
    if (retryTimeout.current !== null) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
    // TODO: replace the second params with look up function for content object from normaliza data (redux)
    log.info(
      `Cannot load image ${src}`,
      {
        screenId: screen.id,
        name,
        isPreload: props.isPreload,
      },
      true
    );
    if (attemptCount <= retryLimit) {
      retryTimeout.current = setTimeout(() => {
        setReMountKey(new Date().getTime());
        log.info(`Retry reload image attemptCount[${attemptCount}]${src}`, {
          screenId: screen.id,
          name,
          isPreload: props.isPreload,
        });
        attemptCount++;
      }, timeoutMs);
    } else {
      log.error(
        `Reach limit! retry reload image ${src}`,
        {
          screenId: screen.id,
          name,
        },
        true
      );
    }
  }, [src, attemptCount, name, screen.id]);

  useEffect(() => {
    log.info(`Show Image ${src}`, {
      screenId: screen.id,
      name,
      contentType: mimetype,
      isPreload,
    });
  }, [src, name, mimetype, isPreload, screen.id]);

  return isPreloaded ? (
    <img
      crossOrigin="anonymous"
      className={cx(styles.image, sizeStyle)}
      alt={name}
      src={src}
      onError={onError}
      data-testid="image-loaded"
      key={reMountKey}
    />
  ) : null;
};

export const ImageViewer = memo(
  (props: ImageViewerProps): ReactElement<ImageViewerProps> => {
    return (
      <div
        className={styles.imageContainer}
        data-testid={`image-${props.urlKey}`}
      >
        <Image
          src={props.src}
          name={props.name}
          mimetype={props.mimetype}
          sizeType={props.sizeType}
          containerSize={props.containerSize}
          isPreload={props.isPreload}
        />
      </div>
    );
  }
);
ImageViewer.displayName = "ImageViewer";
