import { REQUEST_SPACE_SUCCESS, RequestSpaceSuccessAction } from "./types";
import { SpaceFragment } from "../../queries";

export const requestSpaceSuccess = (
  space: SpaceFragment
): RequestSpaceSuccessAction => {
  return {
    type: REQUEST_SPACE_SUCCESS,
    payload: {
      spaces: {
        [space.id]: space,
      },
    },
  };
};
