import { ContentListIdParseReport } from "../../../../store/contentLists/types";
import { ChannelsState } from "../../../../store/channels/types";
import { ContentViewItem } from "../../../../types/content";
import { PlaylistsState } from "../../../../store/playlists/types";

export const getSourceDetail = (
  type: "single-content" | "timeline",
  activeContentItem: ContentViewItem,
  parsedContentListId?: ContentListIdParseReport,
  channels?: ChannelsState,
  playlists?: PlaylistsState
): Record<string, string> => {
  /**
   * parseContentListId gives parent of activeContentItem and will tell us whether the content is being played from
   * channel or playlist. We then use "type" filter and then on filter channels/playlist state using
   * activeContentItem.id to get more details of parent(channel/playlist)
   */
  if (activeContentItem?.type === "void") return {};
  const source = {
    source: parsedContentListId?.type || "N/A",
    playlistId: parsedContentListId?.playlistId || "N/A",
    channelZoneId: parsedContentListId?.zoneId || "N/A",
    channelId: "N/A",
    channelName: "N/A",
    playlistName: "N/A",
    name: "N/A",
  };

  if (type === "single-content") return source;
  switch (parsedContentListId?.type) {
    case "channel-zone":
      source.channelId = channels?.byId[activeContentItem.id]?.id || "N/A";
      source.channelName = channels?.byId[activeContentItem.id]?.name || "N/A";
      source.name = channels?.byId[activeContentItem.id]?.name || "N/A";
      return source;
    case "playlist":
      source.playlistName =
        playlists?.byId[activeContentItem.id]?.name || "N/A";
      source.name = playlists?.byId[activeContentItem.id]?.name || "N/A";
      return source;
  }

  return {};
};
