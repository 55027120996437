import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import { Theme, ThemesState } from "./types";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import isEqual from "lodash/isEqual";
import { ThemeFragment } from "../../queries";
import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { REQUEST_APP_SUCCESS } from "../apps/types";

/**
 * Data about the available app instances.
 */
const initialState: ThemesState = {
  byId: {},
};

export function themesReducer(
  state = initialState,
  action: PlayerAction
): ThemesState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_APP_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const themes = action.payload.themes;
        const ids = Object.keys(themes);
        ids.forEach((id) => {
          if (themes[id]) {
            const newTheme = mapGqlThemeToPlayerTheme(themes[id]);
            if (!isEqual(state.byId[id], newTheme)) {
              draft.byId[id] = newTheme;
            }
          }
        });
      }
    }

    return draft;
  });
}

const mapGqlThemeToPlayerTheme = (data: ThemeFragment): Theme => {
  return {
    id: data.id,
    name: data.name,
    primaryColor: data.config.primaryColor,
    secondaryColor: data.config.secondaryColor,
    textOnPrimary: data.config.textOnPrimary,
    textOnSecondary: data.config.textOnSecondary,
    bodyFont: data.config.bodyFont,
    headingFont: data.config.headingFont,
  };
};
