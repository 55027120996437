import PMI from "@screencloud/postmessage-interface";
import {
  RemoteApiInterface,
  StudioPlayerParentConfiguration,
} from "./configurationManager";
import {
  PlaybackItemReport,
  ContentListUpdateReport,
} from "./modules/viewers/containers/TimelineViewer/types";
import { getLogger } from "./utils/debugLog";
const log = getLogger("configuration-manager");

// This class is used to communicate with JS Player which exposes an api to fetch
// token and device info etc.
class RemoteApi implements RemoteApiInterface {
  remote = new PMI.RemoteInterface();

  connect(): Promise<void> {
    log("Trying to connect to remote api!");
    return this.remote.connect(window.parent).then(() => {
      log("Connected to remote api!");
    });
  }
  private async getInitialiseDataFromJSPlayer(): Promise<
    StudioPlayerParentConfiguration
  > {
    const appData: StudioPlayerParentConfiguration = await this.remote.call(
      "getAppData"
    );
    const mediacacheBaseUrl: string = await this.remote.call(
      "getMediaCacheURL"
    );
    const parentConf = { ...appData, mediacacheBaseUrl };
    log("Parent conf from JS Player", parentConf);
    return parentConf;
  }

  private getInitialiseDataFromPlayerSDK(): Promise<
    StudioPlayerParentConfiguration
  > {
    return this.remote.call("getSPInitialiseData");
  }

  reportPlaybackStateUpdate(
    playbackItemReport: PlaybackItemReport,
    zoneId?: string
  ): void {
    return this.remote.fire("SP_ACTIVE_ITEM_CHANGE", {
      payload: {
        activeItem: playbackItemReport,
        zoneId,
      },
    });
  }

  reportContentListUpdate(contentListReport: ContentListUpdateReport): void {
    return this.remote.fire("SP_CONTENT_LIST_REPORT_UPDATE", {
      payload: {
        report: contentListReport,
      },
    });
  }

  // resolve with correct data end point
  async getInitialiseData(): Promise<StudioPlayerParentConfiguration> {
    return this.getInitialiseDataFromPlayerSDK()
      .catch(() => this.getInitialiseDataFromJSPlayer())
      .catch((err) => {
        console.error("Could not connect to a Remote API.", err);
        throw err;
      });
  }
}

export default RemoteApi;
