import React, {
  FunctionComponent,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import styles from "./AppEditorViewer.module.css";
import {
  GenericMessage,
  InitializeMessagePayload,
  PMIMessageReceivedPayload,
} from "../AppViewer/types";
import { Theme } from "../../../../store/themes/types";
import { PlayerFile } from "../../../../store/files/types";
import { ConfigurationManager } from "../../../../configurationManager";
import { ContextConfig } from "../../../../store/config/types";
import { ScreenData } from "../../../../store/screen/types";
import { makeConnectedMessage, sendMessage } from "./utils";
import { SdkInterface } from "../../../../types/sdk";

export const AppEditorViewerContainer: FunctionComponent = () => {
  const contentPathId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );

  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );

  const screenData = useSelector<PlayerState, ScreenData | undefined>(
    (state) => state.screen.screenData
  );

  const overrideAppInitialize = useSelector<
    PlayerState,
    Partial<InitializeMessagePayload> | undefined
  >((state) => {
    const overrideAppInitialize = state.config.overrideAppInitialize;
    // Ensure content path matches overrideAppInitialize being sent
    if (overrideAppInitialize?.appInstanceId === contentPathId) {
      return overrideAppInitialize;
    }
  });

  const orgId = useSelector<PlayerState, string | undefined>(
    (state) => state.organization?.id
  );

  // Using a key to trigger the app re-mount
  const [reMountKey, setReMountKey] = useState<number | undefined>(undefined);

  useEffect(() => {
    // update the key prop to cause AppEditorViewer re-mount on app data change
    setReMountKey(new Date().getTime());
  }, [overrideAppInitialize]);

  return (
    <AppEditorViewer
      key={reMountKey}
      overrideAppInitialize={overrideAppInitialize}
      orgId={orgId}
      filesByAppInstanceId={
        overrideAppInitialize?.filesByAppInstanceId || { nodes: [] }
      }
      contextConfig={contextConfig}
      screenData={screenData}
      sdkInterface={ConfigurationManager.getInstance().getRemoteInterface()}
    />
  );
};

interface AppEditorViewerProps {
  overrideAppInitialize?: Partial<InitializeMessagePayload>;
  theme?: Theme;
  orgId?: string;
  screenId?: string;
  filesByAppInstanceId: {
    nodes: Array<PlayerFile>;
  };
  contextConfig?: ContextConfig;
  screenData?: ScreenData;
  sdkInterface: SdkInterface;
}

// The AppViewer is exported for reuse in the SiteViewerContainer.
// This will no longer need to be exported after Secure Sites has been refactored to use App Instances under the hood.
export const AppEditorViewer = memo(
  (props: AppEditorViewerProps): ReactElement<AppEditorViewerProps> => {
    const {
      overrideAppInitialize,
      theme,
      orgId,
      filesByAppInstanceId,
      screenData,
      contextConfig,
      sdkInterface,
    } = props;
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const viewerUrl = overrideAppInitialize?.viewerUrl || "";
    const isTesting = process.env.NODE_ENV === "test";
    useEffect(() => {
      const onMessage = (event: MessageEvent): void => {
        try {
          const { data, source } = event;

          // There may be multiple AppViewers on screen at once, e.g. multiple zones.
          if (!isTesting && source !== iframeRef.current?.contentWindow) {
            return;
          }

          // Only CONNECT, CONNECT_SUCCESS, DISCONNECT messages add this ___ thing.
          // The rest nest their data under a 2nd "data" key.
          // TODO - Remove this complexity.
          if (data.substr(0, 3) === "___") {
            handleMessage(JSON.parse(data.substring(3)));
          } else {
            const parsed = JSON.parse(data);
            handleMessage(parsed.data, parsed.referenceId, parsed.requestId);
          }
        } catch (err) {
          console.warn("Could not parse received postMessage", err, event);
        }
      };

      /**
       * 1 - Receive all messages from this app.
       */
      window.addEventListener("message", onMessage, false);

      /**
       * 2 - Deal with the messages.
       */

      const handleMessage = async (
        receivedMessage: GenericMessage,
        referenceId?: number,
        requestId?: number
      ): Promise<void> => {
        const replyMessages: GenericMessage[] = [];
        // handle requestConfigUpdate message.
        // referenceId must match the requestId for requestConfigUpdate because a type is not sent back from the mixedPlayer.
        if (referenceId === 1) {
          console.log("requestConfigUpdate message received from app");
          ConfigurationManager.getInstance()
            .getRemoteInterface()
            .fire("requestConfigUpdate", { payload: receivedMessage });
          return;
        }

        switch (receivedMessage.type) {
          case "CONNECT": {
            replyMessages.push(makeConnectedMessage());
            if (overrideAppInitialize) {
              replyMessages.push({
                type: "initialize",
                payload: {
                  ...overrideAppInitialize,
                  context: {
                    ...contextConfig,
                    theme,
                    screenData,
                  },
                },
              });
            }

            break;
          }
          case "started":
            console.log(
              `Player received app "started" confirmation for:`,
              overrideAppInitialize?.appInstanceId
            );
            break;
          case "configUpdateAvailable":
            ConfigurationManager.getInstance()
              .getRemoteInterface()
              .fire("configUpdateAvailable", {});
            break;
          case "requestFiles":
            ConfigurationManager.getInstance()
              .getRemoteInterface()
              .fire("requestFiles", {
                payload: receivedMessage.payload,
                requestId,
              });
            break;
          case "requestAuthToken":
            console.log(
              `Auth token requested for ${overrideAppInitialize?.appInstanceId}`
            );
            ConfigurationManager.getInstance()
              .getRemoteInterface()
              .fire("requestAuthToken", { requestId });
            break;
        }

        replyMessages.forEach((message) => {
          if (iframeRef.current) {
            if (overrideAppInitialize) {
              sendMessage(iframeRef.current, message, viewerUrl);
            }
          }
        });
      };

      /**
       * Clean up when the App is removed from screen.
       */
      return (): void =>
        window.removeEventListener("message", onMessage, false);
    }, [
      overrideAppInitialize,
      theme,
      orgId,
      filesByAppInstanceId,
      viewerUrl,
      contextConfig,
      screenData,
      isTesting,
    ]);

    const requestConfigUpdateListener = useCallback(
      ({ data }: PMIMessageReceivedPayload): void => {
        if (iframeRef.current) {
          sendMessage(
            iframeRef.current,
            {
              type: "requestConfigUpdate",
              payload: data,
            },
            viewerUrl
          );
        }
      },
      [viewerUrl]
    );

    useEffect(() => {
      let enabled = false;
      const listenerCb = requestConfigUpdateListener;

      sdkInterface.on("SP_REQUEST_CONFIG_UPDATE", listenerCb);
      enabled = true;
      return () => {
        if (enabled) {
          sdkInterface.off("SP_REQUEST_CONFIG_UPDATE", listenerCb);
        }
      };
    }, [sdkInterface, requestConfigUpdateListener]);

    const sendRequestFileListener = useCallback(
      ({ data, requestId }: PMIMessageReceivedPayload): void => {
        if (iframeRef.current) {
          sendMessage(
            iframeRef.current,
            {
              type: "requestFiles",
              payload: data,
            },
            viewerUrl,
            requestId
          );
        }
      },
      [viewerUrl]
    );

    useEffect(() => {
      let enabled = false;
      const listenerCb = sendRequestFileListener;

      sdkInterface.on("SP_SEND_REQUESTED_FILES", listenerCb);
      enabled = true;
      return () => {
        if (enabled) {
          sdkInterface.off("SP_SEND_REQUESTED_FILES", listenerCb);
        }
      };
    }, [sdkInterface, sendRequestFileListener]);

    const sendRequestAuthTokenListener = useCallback(
      ({ data, requestId }: PMIMessageReceivedPayload): void => {
        if (iframeRef.current) {
          sendMessage(
            iframeRef.current,
            {
              type: "requestAuthToken",
              payload: data,
            },
            viewerUrl,
            requestId
          );
        }
      },
      [viewerUrl]
    );

    useEffect(() => {
      const listener = sendRequestAuthTokenListener;

      sdkInterface.on("SP_SEND_REQUEST_AUTH_TOKEN", listener);

      return () => {
        sdkInterface.off("SP_SEND_REQUEST_AUTH_TOKEN", listener);
      };
    }, [sendRequestAuthTokenListener, sdkInterface]);

    return (
      <>
        <iframe
          data-testid="app-editor-iframe"
          ref={iframeRef}
          className={styles.iframe}
          title={overrideAppInitialize?.appInstanceId || ""}
          src={viewerUrl}
        />
      </>
    );
  }
);
AppEditorViewer.displayName = "AppEditorViewer";
