import { FilesState, PlayerFile } from "../../../../store/files/types";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { App } from "../../../../store/apps/types";
import isEqual from "lodash/isEqual";
import { useMemo, useRef } from "react";

// Temporary transform for files to expected shape by files
// Prevents breakage of apps which rely on the current player
const transformToAppFileStructure = (
  files: Array<PlayerFile>
): { nodes: Array<PlayerFile> } => {
  return { nodes: files || [] };
};

export interface AppInstanceFiles {
  nodes: PlayerFile[];
}

/**
 * Hook that gets you files that are used in an app instance.
 * Takes care of returning same object reference if files haven't changed.
 */
export const useAppInstanceFiles = (
  appInstanceId: string,
  isPreview: boolean
): AppInstanceFiles => {
  const prevFiles = useRef<AppInstanceFiles>();

  const app = useSelector<PlayerState, App | undefined>((state) => {
    if (!isPreview) {
      return state.apps.byId[appInstanceId];
    }
    return undefined;
  });

  const files = useSelector<PlayerState, FilesState>((state) => {
    return state.files;
  });

  const appInstanceFiles = useMemo(() => {
    const fileEntities = app?.fileIds.map((id) => files.byId[id]) || [];
    return transformToAppFileStructure(fileEntities);
  }, [files, app]);

  // having deep equality comparison here may have rendering performance repercussions in case of large file sets
  if (!isEqual(prevFiles.current, appInstanceFiles)) {
    prevFiles.current = appInstanceFiles;
    return appInstanceFiles;
  } else if (prevFiles.current === undefined) {
    prevFiles.current = { nodes: [] };
    return prevFiles.current;
  } else {
    return prevFiles.current;
  }
};
