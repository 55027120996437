import { RemoteInterface } from "@screencloud/postmessage-interface";
import { InitializeMessagePayload } from "./modules/viewers/containers/AppViewer/types";
import { config as developmentConfig } from "./config.development";
import {
  ConfigState,
  DeviceConfig,
  FeaturesConfig,
  MediaProxyEndpointConf,
} from "./store/config/types";
import { isLocalDev } from "./utils/devUtils";
import { getContentConfig, insertRegion } from "./utils";
import { setShouldPrioritizeStreamingVideo } from "./utils/videoTypePriorityManager";
import {
  ContentListUpdateReport,
  PlaybackItemReport,
} from "./modules/viewers/containers/TimelineViewer/types";
import RemoteApi from "./remoteApi";
export interface StudioPlayerParentConfiguration {
  token: string;
  mediaProxyEndpoint?: MediaProxyEndpointConf;
  mediacacheBaseUrl?: string; // this is the legacy mediacache server url passed from native apps/chromeos app to js player
  contentPath?: string;
  overrideAppInitialize?: Partial<InitializeMessagePayload>;
  region?: string;
  context?: {
    [key: string]: unknown;
  };
  prioritizeStreamingVideo: boolean;
  features?: FeaturesConfig;
  device?: DeviceConfig;
}

export interface RemoteApiInterface {
  connect: () => Promise<void>;
  reportPlaybackStateUpdate: (
    playbackItemReport: PlaybackItemReport,
    zoneId?: string
  ) => void;
  getInitialiseData: () => Promise<StudioPlayerParentConfiguration>;
  reportContentListUpdate: (contentListReport: ContentListUpdateReport) => void;
}

// This is a singleton class that is in charge of getting the latest app configuration necessary
// from the above layer hosting studio player.
// This configuration data includes the firebase token, media cache url etc.
export class ConfigurationManager {
  private static _instance: ConfigurationManager;
  private remoteApi = new RemoteApi();

  standalone: boolean;
  initialized = false;

  static getInstance(): ConfigurationManager {
    if (!ConfigurationManager._instance) {
      ConfigurationManager._instance = new ConfigurationManager();
    }
    return ConfigurationManager._instance;
  }

  constructor() {
    this.standalone = window.parent === window;
  }

  isStandalone(): boolean {
    return this.standalone;
  }

  async init(): Promise<ConfigurationManager> {
    if (!this.isStandalone()) {
      await this.remoteApi.connect();
    }

    return this;
  }

  async getParentConfiguration(): Promise<StudioPlayerParentConfiguration> {
    return this.remoteApi.getInitialiseData();
  }

  /**
   * Config = The data passed to the player before startup,
   * e.g. from localStorage, queryParams, parent hosting this player, etc
   *
   * For easier local development, all of this should be possible to
   * set via config.development.js using the "config" key.
   */
  //TODO: refactor this to separate local conf (from env variables and query params etc) from parent conf.
  async getConfiguration(): Promise<ConfigState> {
    if (!this.initialized) {
      await this.init();
    }

    let graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT || "";
    const lokiEndpoint = process.env.REACT_APP_LOKI_ENDPOINT || "";
    let mediaOrigin = process.env.REACT_APP_MEDIA_ORIGIN || "";

    const linkCloudServiceUrl =
      process.env.REACT_APP_LINK_CLOUD_SERVICE_URL || "";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const regionUrlParam = urlParams.get("region");
    // In local development, let developers use config.development.js
    if (isLocalDev() && this.isStandalone()) {
      const localConfig = developmentConfig.config;
      const regionValue = regionUrlParam || localConfig.region;
      const imgixDomain =
        process.env[`REACT_APP_IMGIX_DOMAIN_${regionValue.toUpperCase()}`] ||
        "";
      const graphqlToken =
        process.env.REACT_APP_GRAPHQL_TOKEN || localConfig.graphqlToken;
      graphqlEndpoint = insertRegion(
        graphqlEndpoint || localConfig.graphqlEndpoint,
        regionValue
      );
      mediaOrigin = insertRegion(mediaOrigin, regionValue);

      const contentConfig = getContentConfig(localConfig.contentPath);

      const config: ConfigState = {
        ...localConfig,
        graphqlEndpoint, // .env variables last to take precedence.
        lokiEndpoint,
        graphqlToken,
        linkCloudServiceUrl,
        contentConfig,
        mediaOrigin,
        region: regionValue,
      };

      if (!graphqlEndpoint || !imgixDomain || !mediaOrigin) {
        console.warn(
          "The player requires a valid GraphQL endpoint, Imgix domain and media origin to start !"
        );
      }
      return config;
    }

    const parentConfiguration = await this.getParentConfiguration();

    if (!parentConfiguration) {
      throw new Error(
        `Could not get data from parent player layer. Check if you run the player inside a platform specific layer.`
      );
    }

    const {
      token,
      mediaProxyEndpoint,
      mediacacheBaseUrl,
      contentPath,
      overrideAppInitialize,
      region = "eu",
      context,
      prioritizeStreamingVideo = false,
      features,
      device,
    } = parentConfiguration;

    // region param is sent from the sdk e.g loaded via studio
    // regionUrlParam is used when player is loaded via js player (player url includes region param, this comes from the backend into JS player)
    const regionValue = regionUrlParam || region;

    setShouldPrioritizeStreamingVideo(prioritizeStreamingVideo);

    const contentConfig = getContentConfig(contentPath);

    graphqlEndpoint = insertRegion(graphqlEndpoint, regionValue);
    mediaOrigin = insertRegion(mediaOrigin, regionValue);
    const imgixDomain =
      process.env[`REACT_APP_IMGIX_DOMAIN_${regionValue.toUpperCase()}`] || "";

    // Make sure legacy format is handled, i.e. when url string is provided rather than an object
    let processedMediaProxyEndpoint:
      | MediaProxyEndpointConf
      | string
      | undefined = mediaProxyEndpoint;
    if (typeof mediaProxyEndpoint === "string") {
      processedMediaProxyEndpoint = {
        baseUrl: mediaProxyEndpoint,
      };
    }
    const defaultMediaProxyEndpointConf: MediaProxyEndpointConf = {
      baseUrl: "",
      cacheImages: false,
      cacheVideos: true, // keep the legacy behaviour as default by just caching mp4 videos.
      cacheStreamingMedia: false,
    };

    const config: ConfigState = {
      graphqlEndpoint,
      lokiEndpoint,
      graphqlToken: token,
      imgixDomain,
      mediaProxyEndpoint: processedMediaProxyEndpoint
        ? Object.assign(
            {},
            defaultMediaProxyEndpointConf,
            processedMediaProxyEndpoint
          )
        : undefined,
      mediacacheBaseUrl,
      timeOffset: 0, // todo: get the real time offset from screencloud time server
      orientation: "landscape", // TODO: where does this come from?
      linkCloudServiceUrl,
      contentConfig,
      contextConfig: context || {},
      region: regionValue,
      mediaOrigin,
      features: features || undefined,
      device: device || undefined,
      overrideAppInitialize,
    };

    if (!graphqlEndpoint || !imgixDomain || !mediaOrigin) {
      console.warn(
        "The player requires a valid GraphQL endpoint, Imgix domain and media origin to start !"
      );
    }

    return config;
  }

  // todo: underlying postmessage packages should be hidden behind the interface for the rest of the app
  getRemoteInterface(): RemoteInterface {
    return this.remoteApi.remote;
  }
}
