// helper function to filter undefined/null and their types, useful mostly for filtering arrays
export function notUndefinedOrNull<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}

export interface ElementSize {
  width: number;
  height: number;
}

export function detectElementSize(element: HTMLElement): ElementSize {
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  return {
    width,
    height,
  };
}

export function getUrlAndParams(): { url: URL; params: URLSearchParams } {
  const url = new URL(window.location.toString());
  const params = new URLSearchParams(window.location.search);
  return { url, params };
}

export function getShortCommitHash(): string {
  return getCommitHash().slice(0, 6);
}

export function getCommitHash(): string {
  return process.env.REACT_APP_GITHASH || "N/A";
}
