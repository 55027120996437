import { Logger } from "../logger/logger";

const log = new Logger("isUrlReachable");

export async function isURLReachable(testUrl: string): Promise<boolean> {
  try {
    const response = await fetch(testUrl);
    log.debug(
      `${testUrl} is reachable with status`,
      {
        status: response.status,
      },
      true
    );
    return true;
  } catch (e) {
    log.debug(`${testUrl} is not reachable`, { message: e?.message }, true);
  }
  return false;
}
