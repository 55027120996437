import { LiveUpdateItemType } from "./types";

export function getLiveUpdateCompositeId(
  entityType: LiveUpdateItemType,
  entityId: string
): string {
  return `${entityType}::${entityId}`;
}

export function decomposeLiveUpdateId(
  compositeId: string
): { entityType: LiveUpdateItemType; entityId: string } {
  const [entityType, entityId] = compositeId.split("::");
  return { entityType: entityType as LiveUpdateItemType, entityId };
}
