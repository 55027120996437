import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import {
  makeContentListIdForPlaylist,
  makeContentListIdForZone,
  makeTimelineIdForContentList,
} from "../../../../store/contentLists/utils";
import { TimeOptions, actualUtcNow } from "../../../../utils/timeManager";
import { setTimelineControlOffset } from "../../../../store/playback/actions";
import { SdkInterface } from "../../../../types/sdk";
import { Channel } from "../../../../store/channels/types";
export interface PlaybackControlContainerProps {
  type: "channel" | "playlist";
  id: string;
  sdkInterface: SdkInterface;
}

export const PlaybackControlContainer: FunctionComponent<PlaybackControlContainerProps> = ({
  type,
  id,
  sdkInterface,
}) => {
  const store = useStore<PlayerState>();
  const dispatch = useDispatch();
  const enableControls = useSelector<PlayerState, boolean | undefined>(
    (state) => state.config.features?.enablePlaybackControlInterface
  );
  const channel = useSelector<PlayerState, Channel | undefined>(
    (state) => state.channels.byId[id]
  );

  const getTargetTimelineId = useCallback(
    (zoneId: string | undefined): string => {
      if (type === "channel" && channel && zoneId) {
        return makeTimelineIdForContentList(
          makeContentListIdForZone(channel.layoutId, zoneId)
        );
      } else if (type === "playlist") {
        return makeTimelineIdForContentList(makeContentListIdForPlaylist(id));
      } else {
        throw new Error(
          `Playback controller is not able to generate timeline id for type: ${type} and id: ${id}. Target content missing in store.`
        );
      }
    },
    [type, id, channel]
  );

  const skipItemsByIndexOffset = useCallback(
    (
      targetItemIndexOffset: number, // negative number => skip back
      zoneId: string | undefined
    ) => {
      const timelines = store.getState().timelines;
      const timelinesPlaybackStates = store.getState().playback.timelines;
      const targetTimelineId = getTargetTimelineId(zoneId);
      const targetTimeline = timelines.byId[targetTimelineId];
      const targetPlaybackState = timelinesPlaybackStates[targetTimelineId];
      const timeOptions: TimeOptions = {
        timeOffset: store.getState().config.timeOffset,
        timezoneOverride: store.getState().screen?.deviceMeta.overrideTimezone,
        timelineControlOffset: 0, // not needed here
      };
      if (
        targetTimeline &&
        targetPlaybackState &&
        typeof targetPlaybackState.activeIndex === "number"
      ) {
        // important: we assume, that "activeIndex + n" item is present in generated timeline,
        //  otherwise the skip call will be ignored
        const nextItem =
          targetTimeline.items[
            targetPlaybackState.activeIndex + targetItemIndexOffset
          ];
        const nextItemStart = nextItem?.startTimestamp;
        if (typeof nextItemStart === "number") {
          const now = actualUtcNow(timeOptions);
          const offsetValue = nextItemStart - now;
          dispatch(setTimelineControlOffset(offsetValue));
        }
      }
    },
    [getTargetTimelineId, dispatch, store]
  );

  const skipForward = useCallback(
    (payload: { zoneId: string | undefined } | undefined): void => {
      skipItemsByIndexOffset(1, payload?.zoneId);
    },
    [skipItemsByIndexOffset]
  );

  const skipBack = useCallback(
    (payload: { zoneId: string | undefined } | undefined): void => {
      skipItemsByIndexOffset(-1, payload?.zoneId);
    },
    [skipItemsByIndexOffset]
  );

  useEffect(() => {
    if (enableControls) {
      let enabled = false;
      const skipForwardCb = skipForward;

      sdkInterface.on("SP_SKIP_FORWARD_CONTROL_ACTION", skipForwardCb);
      enabled = true;

      return () => {
        if (enabled) {
          sdkInterface.off("SP_SKIP_FORWARD_CONTROL_ACTION", skipForwardCb);
        }
      };
    }
  }, [skipForward, enableControls, sdkInterface]);

  useEffect(() => {
    if (enableControls) {
      let enabled = false;
      const skipBackCb = skipBack;

      sdkInterface.on("SP_SKIP_BACK_CONTROL_ACTION", skipBackCb);
      enabled = true;

      return () => {
        if (enabled) {
          sdkInterface.off("SP_SKIP_BACK_CONTROL_ACTION", skipBackCb);
        }
      };
    }
  }, [skipBack, enableControls, sdkInterface]);

  return <></>;
};
