import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setConfig } from "../../../../store/config/actions";
import { useRetry } from "../../../../utils/useRetry";
import { useTimeOffset } from "./getTimeOffset";

export interface TimeSynchroniserContainerProps {
  timeServerUrl: string;
}

export const TimeSynchroniserContainer: FunctionComponent<TimeSynchroniserContainerProps> = ({
  timeServerUrl,
}: TimeSynchroniserContainerProps) => {
  const dispatch = useDispatch();

  const { hasError, getTimeOffset, timeOffset } = useTimeOffset(timeServerUrl);

  // use exponential back off if getTimeOffset fails
  useRetry(hasError, getTimeOffset);
  useEffect(() => {
    getTimeOffset();
  }, [getTimeOffset]);

  useEffect(() => {
    if (timeOffset !== undefined) {
      dispatch(setConfig({ timeOffset }));
    }
  }, [dispatch, timeOffset]);

  return <></>;
};
