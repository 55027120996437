import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import { OrganizationState } from "./types";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { REQUEST_APP_SUCCESS } from "../apps/types";

/**
 * Data about the organization this screen belongs to.
 * Assumed a screen can only belong to one org.
 */
const initialState: OrganizationState = {
  id: "",
  featureFlags: [],
};

export function organizationReducer(
  state = initialState,
  action: PlayerAction
): OrganizationState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_APP_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const org = action.payload.organization;
        if (!org) {
          return draft;
        }
        draft.id = org.id;
        draft.brandChannelId = org.brandChannelId ?? undefined;
        draft.defaultThemeId = org.themeByDefaultChannelThemeId ?? undefined;
        draft.featureFlags = org.featureFlagsCache ?? [];
        draft.loggingUrlOverride =
          org.preferences?.player?.player_log_config?.endpoint ?? undefined;
      }
    }
    return draft;
  });
}
